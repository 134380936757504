import React, { useEffect, useState } from "react";
import Contener from "../Component/Contener";
import horizon from '../Assets/horizon.png'
import MainRouter from "./MainRouter";
import owener from '../Assets/owener.png'
import PaginatedItems from "../Component/PaginatedItems";
import RowSelect from "../Component/RowSelect";
import ProfileModal from "../Component/ProfileModal";
import Cookies from "js-cookie";
import axios from "axios";
import { toast } from 'react-toastify';
import {BASE_URL}  from '../Function/Base';
import { Empty, Skeleton } from 'antd';
import { Cascader } from 'antd';
import { useParams } from "react-router-dom";
import { useAuth } from "../Function/AuthProvider";
import FileSaver from "file-saver";
import * as XLSX from 'xlsx';
import Modal from "../Component/Modal";
function Student(props){
  const [value, setValue] = useState([]); // Declare the value state variable
  const auth=useAuth()
  const [excel,setExcel]=useState(true)
  const [selecterdCampus,setSelecterdCampus]=useState(null);
  const {campusID} = useParams();
   
  const options = [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Gender',
      value: 'gender',
      children: [
        {
          label: 'Male',
          value: 'Male',
        },
        {
          label: 'Female',
          value: 'Female',
        },
      ],
    },
    {
      label: 'Host Availability',
      value: 'availability',
      children: [
        {
          label: 'Available',
          value: '1',
        },
        {
          label: 'Not Available',
          value: '0',
        },
      ],
    },
    // Add additional options here
  ];
 
  const [tableshow,setTableShow]=useState({
    fullName:true,
    sex:true,
    Phone:false,
    fellowship:true,
    City:true,
    church:false,
language:false,
Host:false,
show:false,
  });

  const onChange = (value) => {
   
    setValue(value); // Update the value state variable with the selected value
  };
    const [open, setOpen] = useState(false);
    const [modalData,setModalData]=useState({});
    const [fshow,setFShow]=useState(false);
       const [search,setSearch]=useState(false)
 const userProfile=( fullName,lastName,sex,phone,fellowship,city,cityFrom,church,lang,host,arrivalDate,created_at,campusID,studentID)=>{
setModalData({
    FullName:fullName,
    Sex:sex,
    Phone:phone,
    fellowship:fellowship,
    City:city||cityFrom,
    Church:church,
    Lang:lang,
    Host:host,
    arrivalDate:arrivalDate,
    Registered:created_at,
    campusID:campusID,
    studentID:studentID,
    lastName:lastName
 })
setOpen(true);

 }
 const token=Cookies.get('token')

   
  
  const handleRowsPerPageChange = (value) => {
    setRowsPerPage(value);
  };

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset>0?itemOffset+Number(rowsPerPage):Number(rowsPerPage);

  const [searchQuery, setSearchQuery] = useState('');
  const [filter,setFilter]=useState('fullName');
  const Show=(e)=>{

switch(e){
  case 'name': setTableShow({...tableshow,fullName:!tableshow.fullName });
  break;
  case 'sex': setTableShow({...tableshow,Sex:!tableshow.sex });
  break;
  case 'phone': setTableShow({...tableshow,Phone:!tableshow.Phone });
  break;
  case 'city': setTableShow({...tableshow,City:!tableshow.City });
  break;
  
}
  }
  const filteredData =  props?.student?.filter((item) => {

    if (
      item?.firstName?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      item?.sex?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      item?.city?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      item?.campusCity?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      item?.church?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      item?.language?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      item?.phone?.toLowerCase().includes(searchQuery?.toLowerCase())
    ) {
      return true;
    }
  
    return false;
  });
  function Cancel(){
    setOpen(false)
  }

  const currentItems = filteredData?.length>0&& filteredData?.slice(itemOffset, endOffset);
 
  const pageCount =props?.student!=null&& Math.ceil(props?.student?.length / rowsPerPage);
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const uniqueFellowships = new Set();

const filteredFellowships = props?.student?.map((data, index) => {
  
  if (!uniqueFellowships.has(data?.fellowship)) {
  
    uniqueFellowships.add(data?.fellowship);
    return (
    
    <option key={data?.id} value={data?.fellowship}>   
        {data?.fellowship}
      </option>
  
      
    );
  }
  return null;
});
function Export() {
    // Convert any array data to a string before exporting
   const download=selecterdCampus&&props?.student?.filter((item)=>{ 
    if(item.fellowship===selecterdCampus){

      return item.fellowship === selecterdCampus
    }else{
return item
    }
  });
   
    // const excel=props?.student?.map(({firstName,lastName,sex,city,church,phone,language,hasHostingFamily,registration_date})=>({firstName,lastName,sex,city,church,phone,language,hasHostingFamily,registration_date}))
    const newData = download&&download?.map((item) => {
      const newItem = { ...item };
      Object.keys(item).forEach((key) => {
        if (Array.isArray(item[key+2])) {
          newItem[key] = item[key].join(", ");
        }
      });
      return newItem;
    });
  
    const ws = XLSX.utils.json_to_sheet(newData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    download&&FileSaver.saveAs(data, 'stuentList' + fileExtension);
  };
  useEffect(()=>{

    if(selecterdCampus==='null'){
    }else{selecterdCampus&&Export()}

  },[selecterdCampus])
  useEffect(()=>{
    auth.setDemo(campusID)
  },[campusID])

    return(
      <div className={`${props.isDarkMode?'dark dark:bg-[#000] dark:text-white h-screen overflow-y':'bg-[#F5F5F5] h-screen overflow-y'}`}>
      <div className={`dark:bg-[#000] dark:text-white ${props.expand===false?'md:ml-[5.5%]  ': 'md:ml-[17.5%]  ' }`}>
            <div className=" dark:bg-black dark:text-white  dark:border-[1px]   bg-white md:mt-[20px] mb-5">
{auth.contextHolder}
<div className="">
<img className='w-full mt-[0px]' src={horizon} alt="" />
</div>
<div className='relative flex justify-center gap-8  md:mt-[-101px]  h-full ' >
{props?.super!=0?'':
<div className=' absolute top-0 w-[90%] flex even:z-50 odd:z-0 even:w-[60px] -z-10 mt-[-13px] '>
  <div className='h-[50px] w-[100px]   even:shadow-md odd:shadow-xl odd:translet-y-6 hover:-translate-y-2 hover:h-[65px] hover:shadow-md cursor-pointer duration-500 even:bg-purple-500 odd:bg-violet-900 text-white '>
    <div className='flex flex-col h-full justify-center items-center'>
<p className="font-bold">{props.studentDash?.newStudents>999?props.studentDash?.newStudents/100 +'k':props.studentDash?.newStudents}</p>
<p className="text-[10px]">new Student</p>
    </div>
  </div>
  {/*  */}
  <div className='h-[50px] w-[100px]   even:shadow-md odd:shadow-xl odd:translet-y-6 hover:-translate-y-2 hover:h-[65px] hover:shadow-md cursor-pointer duration-500 even:bg-purple-500 odd:bg-violet-900 text-white '>
    <div className='flex flex-col h-full justify-center items-center text-center'>
<p>{props.studentDash?.lastYearStudents>999?props.studentDash?.lastYearStudents/100 +'k':props.studentDash?.lastYearStudents}</p>
<p className="text-[10px]">Last year Student</p>
    </div>
  </div>
  {/*  */}
  <div className='h-[50px] w-[100px]   even:shadow-md odd:shadow-xl odd:translet-y-6 hover:-translate-y-2 hover:h-[65px] hover:shadow-md cursor-pointer duration-500 even:bg-purple-500 odd:bg-violet-900 text-white '>
    <div className='flex flex-col h-full justify-center items-center'>
<p>{props.studentDash?.totalNeedHost>999?props.studentDash?.totalNeedHost/100 +'k':props.studentDash?.totalNeedHost}</p>
<p className="text-[10px]">Host Request</p>
    </div>
  </div>
   {/*  */}
   <div className='h-[50px] w-[100px]   even:shadow-md odd:shadow-xl odd:translet-y-6 hover:-translate-y-2 hover:h-[65px] hover:shadow-md cursor-pointer duration-500 even:bg-purple-500 odd:bg-violet-900 text-white '>
    <div className='flex flex-col h-full justify-center items-center'>
<p className="font-bold">{props.studentDash?.totalStudents>999?props.studentDash?.totalStudents/100 +'k':props.studentDash?.totalStudents}</p>
<p className="text-[10px]">Total Student</p>
    </div>
  </div>
</div>
}
<div className=" dark:bg-black dark:text-white  bg-white w-[90%] mt-8 border border-solid ">
   <div className="flex justify-between items-center h-[48px]  w-full   mr-[41px]">
    <p className="ml-[16px]">All time Student list </p>
    <input className={search===true?'border border-gray-300 rounded-md py-2 px-4  w-[500px] h-[30px]   p-3 bg-white hover:bg-transparent active:bg-transparent':'hidden'} type="text" placeholder='Search'   value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}  />            
   <div className="flex gap-5 items-center">
   <svg  width="1" height="32" viewBox="0 0 1 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="1" height="32" fill="#E5E9EB"/>
</svg>
<svg onClick={()=>setSearch(!search)} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M14 10.0008C12.9391 10.0008 11.9217 10.4222 11.1716 11.1723C10.4214 11.9225 10 12.9399 10 14.0008C10 15.0616 10.4214 16.079 11.1716 16.8292C11.9217 17.5793 12.9391 18.0008 14 18.0008C15.0609 18.0008 16.0783 17.5793 16.8284 16.8292C17.5786 16.079 18 15.0616 18 14.0008C18 12.9399 17.5786 11.9225 16.8284 11.1723C16.0783 10.4222 15.0609 10.0008 14 10.0008ZM8 14.0008C7.99988 13.0565 8.22264 12.1255 8.65017 11.2835C9.0777 10.4415 9.69792 9.71236 10.4604 9.15529C11.2229 8.59822 12.1061 8.22898 13.0381 8.0776C13.9702 7.92622 14.9249 7.99698 15.8245 8.28412C16.724 8.57126 17.5432 9.06667 18.2152 9.73006C18.8872 10.3935 19.3931 11.2061 19.6919 12.1019C19.9906 12.9977 20.0737 13.9514 19.9343 14.8853C19.795 15.8193 19.4372 16.7072 18.89 17.4768L23.707 22.2938C23.8892 22.4824 23.99 22.735 23.9877 22.9972C23.9854 23.2594 23.8802 23.5102 23.6948 23.6956C23.5094 23.881 23.2586 23.9862 22.9964 23.9884C22.7342 23.9907 22.4816 23.8899 22.293 23.7078L17.477 18.8918C16.5794 19.53 15.5233 19.9089 14.4247 19.9869C13.326 20.0648 12.2271 19.8389 11.2483 19.3337C10.2695 18.8286 9.44869 18.0638 8.87572 17.1231C8.30276 16.1824 7.99979 15.1022 8 14.0008Z" fill="#48535B"/>
</svg>
<svg  width="1" height="32" viewBox="0 0 1 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="1" height="32" fill="#E5E9EB"/>
</svg>
<div className='flex  cursor-pointer justify-center items-center ' onClick={()=>setExcel(!excel)}>
<svg className=""  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M5 19C5 18.7348 5.10536 18.4804 5.29289 18.2929C5.48043 18.1054 5.73478 18 6 18H18C18.2652 18 18.5196 18.1054 18.7071 18.2929C18.8946 18.4804 19 18.7348 19 19C19 19.2652 18.8946 19.5196 18.7071 19.7071C18.5196 19.8946 18.2652 20 18 20H6C5.73478 20 5.48043 19.8946 5.29289 19.7071C5.10536 19.5196 5 19.2652 5 19ZM8.293 11.293C8.48053 11.1055 8.73484 11.0002 9 11.0002C9.26516 11.0002 9.51947 11.1055 9.707 11.293L11 12.586V5C11 4.73478 11.1054 4.48043 11.2929 4.29289C11.4804 4.10536 11.7348 4 12 4C12.2652 4 12.5196 4.10536 12.7071 4.29289C12.8946 4.48043 13 4.73478 13 5V12.586L14.293 11.293C14.3852 11.1975 14.4956 11.1213 14.6176 11.0689C14.7396 11.0165 14.8708 10.9889 15.0036 10.9877C15.1364 10.9866 15.2681 11.0119 15.391 11.0622C15.5139 11.1125 15.6255 11.1867 15.7194 11.2806C15.8133 11.3745 15.8875 11.4861 15.9378 11.609C15.9881 11.7319 16.0134 11.8636 16.0123 11.9964C16.0111 12.1292 15.9835 12.2604 15.9311 12.3824C15.8787 12.5044 15.8025 12.6148 15.707 12.707L12.707 15.707C12.5195 15.8945 12.2652 15.9998 12 15.9998C11.7348 15.9998 11.4805 15.8945 11.293 15.707L8.293 12.707C8.10553 12.5195 8.00021 12.2652 8.00021 12C8.00021 11.7348 8.10553 11.4805 8.293 11.293V11.293Z" fill="#48535B"/>
</svg>
{excel&&<p className='bg-red-500 rounded-8 p-2 text-white text-center '>x</p>}
</div>
    {excel&&
<select  onChange={(e)=>setSelecterdCampus(e.target.value)}>
<option  value='null'>   
      Select Campus
      </option>
     {props?.super!=0?'': <option  value='Allcampus'>   
      All Campus
      </option>}
{filteredFellowships}


</select>
  }
<svg width="1" height="32" viewBox="0 0 1 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="1" height="32" fill="#E5E9EB"/>
</svg>

<svg onClick={()=>setTableShow({...tableshow,show:!tableshow.show})} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M13.9999 9C13.7347 9 13.4803 8.89464 13.2928 8.70711C13.1053 8.51957 12.9999 8.26522 12.9999 8C12.9999 7.73478 13.1053 7.48043 13.2928 7.29289C13.4803 7.10536 13.7347 7 13.9999 7H18.9999C19.2651 7 19.5195 7.10536 19.707 7.29289C19.8946 7.48043 19.9999 7.73478 19.9999 8V13C19.9999 13.2652 19.8946 13.5196 19.707 13.7071C19.5195 13.8946 19.2651 14 18.9999 14C18.7347 14 18.4803 13.8946 18.2928 13.7071C18.1053 13.5196 17.9999 13.2652 17.9999 13V10.414L13.7069 14.707C13.5194 14.8945 13.2651 14.9998 12.9999 14.9998C12.7348 14.9998 12.4804 14.8945 12.2929 14.707L9.99992 12.414L5.70692 16.707C5.51832 16.8892 5.26571 16.99 5.00352 16.9877C4.74132 16.9854 4.49051 16.8802 4.3051 16.6948C4.11969 16.5094 4.01452 16.2586 4.01224 15.9964C4.00997 15.7342 4.11076 15.4816 4.29292 15.293L9.29292 10.293C9.48045 10.1055 9.73475 10.0002 9.99992 10.0002C10.2651 10.0002 10.5194 10.1055 10.7069 10.293L12.9999 12.586L16.5859 9H13.9999Z" fill="#252C32"/>
</svg>
{/* <select className="ml-[16px]"  onChange={(e) => Show(e.target.value)} > 
<option value="Filter">Visible</option>
<option value="name">Name</option>
      <option value="sex">Sex</option>  
        <option value="phone">Phone</option>
        <option value="city">City</option>
    </select> */}
     {tableshow.show && (
<Modal setShowModal={
  () => setTableShow({...tableshow,show:!tableshow.show})
  }>
  <div className='flex flex-col space-y-4 p-5'>
    <p>Select You want to see</p>
<div className="flex items-center">
  <input id="checkbox" type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" onChange={(e)=>setTableShow({...tableshow,City:!tableshow.City})} checked={tableshow.City}/>
  <label for="checkbox" className="ml-2 block text-gray-700">
    City
  </label>
</div>
{/*  */}
<div className="flex items-center">
  <input id="checkbox" type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" onChange={(e)=>setTableShow({...tableshow,fullName:!tableshow.fullName})} checked={tableshow.fullName}/>
  <label for="checkbox" className="ml-2 block text-gray-700">
    Name
  </label>
</div>
{/*  */}
<div className="flex items-center">
  <input id="checkbox" type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" onChange={(e)=>setTableShow({...tableshow,language:!tableshow.language})} checked={tableshow.language}/>
  <label for="checkbox" className="ml-2 block text-gray-700">
    Language
  </label>
</div>
{/*  */}
<div className="flex items-center">
  <input id="checkbox" type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" onChange={(e)=>setTableShow({...tableshow,church:!tableshow.church})} checked={tableshow.church}/>
  <label for="checkbox" className="ml-2 block text-gray-700">
   Church
  </label>
</div>
{/*  */}
<div className="flex items-center">
  <input id="checkbox" type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" onChange={(e)=>setTableShow({...tableshow,Host:!tableshow.Host})} checked={tableshow.Host}/>
  <label for="checkbox" className="ml-2 block text-gray-700">
   Host
  </label>
</div>
{/*  */}
<div className="flex items-center">
  <input id="checkbox" type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" onChange={(e)=>setTableShow({...tableshow,sex:!tableshow.sex})} checked={tableshow.sex}/>
  <label for="checkbox" className="ml-2 block text-gray-700">
   Sex
  </label>
</div>
{/*  */}
<div className="flex items-center">
  <input id="checkbox" type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" onChange={(e)=>setTableShow({...tableshow,Phone:!tableshow.Phone })} checked={tableshow.Phone}/>
  <label for="checkbox" className="ml-2 block text-gray-700">
   Phone
  </label>
</div>

{/*  */}
{props?.super==0&&
<div className="flex items-center">
  <input id="checkbox" type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" onChange={(e)=>setTableShow({...tableshow,fellowship:!tableshow.fellowship })} checked={tableshow.fellowship}/>
  <label for="checkbox" className="ml-2 block text-gray-700">
   Fellowship
  </label>
</div>
}
{/*  */}
  </div>
</Modal>
     )}
    <div className={ `flex flex-col space-y-2 ${fshow!=false? 'hidden':'hidden'}`}>
    <Cascader
    style={{
      width: '100%',
    }}
    options={options}
    onChange={onChange}
    multiple
    maxTagCount="responsive"
  />
{/* 
  <label class="inline-flex items-center">
    <input type="checkbox" class="form-checkbox" value="option1"/>
    <span class="firstName">firstName</span>
  </label>
  <label class="inline-flex items-center">
    <input type="checkbox" class="form-checkbox" value="option2"/>
    <span class="lastName">lastName</span>
  </label>
  <label class="inline-flex items-center">
    <input type="checkbox" class="form-checkbox" value="option3"/>
    <span class="city">city</span>
  </label>
  <label class="inline-flex items-center">
    <input type="checkbox" class="form-checkbox" value="option3"/>
    <span class="city">language</span>
  </label>
  <label class="inline-flex items-center">
    <input type="checkbox" class="form-checkbox" value="option3"/>
    <span class="city">city</span>
  </label> */}
</div>

{/* <svg onClick={()=>setFShow(!fshow)} className="mr-[16px]" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.9999 9C13.7347 9 13.4803 8.89464 13.2928 8.70711C13.1053 8.51957 12.9999 8.26522 12.9999 8C12.9999 7.73478 13.1053 7.48043 13.2928 7.29289C13.4803 7.10536 13.7347 7 13.9999 7H18.9999C19.2651 7 19.5195 7.10536 19.707 7.29289C19.8946 7.48043 19.9999 7.73478 19.9999 8V13C19.9999 13.2652 19.8946 13.5196 19.707 13.7071C19.5195 13.8946 19.2651 14 18.9999 14C18.7347 14 18.4803 13.8946 18.2928 13.7071C18.1053 13.5196 17.9999 13.2652 17.9999 13V10.414L13.7069 14.707C13.5194 14.8945 13.2651 14.9998 12.9999 14.9998C12.7348 14.9998 12.4804 14.8945 12.2929 14.707L9.99992 12.414L5.70692 16.707C5.51832 16.8892 5.26571 16.99 5.00352 16.9877C4.74132 16.9854 4.49051 16.8802 4.3051 16.6948C4.11969 16.5094 4.01452 16.2586 4.01224 15.9964C4.00997 15.7342 4.11076 15.4816 4.29292 15.293L9.29292 10.293C9.48045 10.1055 9.73475 10.0002 9.99992 10.0002C10.2651 10.0002 10.5194 10.1055 10.7069 10.293L12.9999 12.586L16.5859 9H13.9999Z" fill="#252C32"/>
</svg> */}

   </div>
    </div> 
    
   {props?.student===null||props?.student===undefined?
 <Skeleton active/>
     :
     <>
    { props?.student?.length!==0?
<table className="w-full dark:bg-black dark:text-white divide-x  overflow-x-scroll">
    <thead>
    <tr className="border border-t-solide  h-[48px] hover:bg-gray-100  ">
    {tableshow.fullName&&
        <td> 
   <div className="flex  gap-2 p-2 items-center">
    <p className='text-[#6E7C87] md:text-md text-[12px]'>Full name</p>
    <svg  width="8" height="10" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.292787 5.70679C0.105316 5.51926 0 5.26495 0 4.99979C0 4.73462 0.105316 4.48031 0.292787 4.29279L4.29279 0.292786C4.48031 0.105315 4.73462 0 4.99979 0C5.26495 0 5.51926 0.105315 5.70679 0.292786L9.70679 4.29279C9.88894 4.48139 9.98974 4.73399 9.98746 4.99619C9.98518 5.25838 9.88001 5.5092 9.6946 5.6946C9.5092 5.88001 9.25838 5.98518 8.99619 5.98746C8.73399 5.98974 8.48139 5.88894 8.29279 5.70679L5.99979 3.41379V14.9998C5.99979 15.265 5.89443 15.5194 5.70689 15.7069C5.51936 15.8944 5.265 15.9998 4.99979 15.9998C4.73457 15.9998 4.48022 15.8944 4.29268 15.7069C4.10514 15.5194 3.99979 15.265 3.99979 14.9998V3.41379L1.70679 5.70679C1.51926 5.89426 1.26495 5.99957 0.999786 5.99957C0.734622 5.99957 0.480314 5.89426 0.292787 5.70679V5.70679Z" fill="#DBDBDB"/>
</svg>
<svg width="8" height="10" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.71929 10.293C9.90676 10.4805 10.0121 10.7348 10.0121 11C10.0121 11.2652 9.90676 11.5195 9.71929 11.707L5.71929 15.707C5.53176 15.8945 5.27745 15.9998 5.01229 15.9998C4.74712 15.9998 4.49282 15.8945 4.30529 15.707L0.305288 11.707C0.209778 11.6148 0.133596 11.5044 0.0811869 11.3824C0.0287779 11.2604 0.00119157 11.1292 3.77571e-05 10.9964C-0.00111606 10.8636 0.0241854 10.7319 0.0744663 10.609C0.124747 10.4861 0.199 10.3745 0.292893 10.2806C0.386786 10.1867 0.498438 10.1125 0.621334 10.0622C0.74423 10.0119 0.87591 9.9866 1.00869 9.98775C1.14147 9.9889 1.27269 10.0165 1.39469 10.0689C1.5167 10.1213 1.62704 10.1975 1.71929 10.293L4.01229 12.586V1C4.01229 0.734784 4.11765 0.48043 4.30518 0.292893C4.49272 0.105357 4.74707 0 5.01229 0C5.2775 0 5.53186 0.105357 5.71939 0.292893C5.90693 0.48043 6.01229 0.734784 6.01229 1V12.586L8.30529 10.293C8.49282 10.1055 8.74712 10.0002 9.01229 10.0002C9.27745 10.0002 9.53176 10.1055 9.71929 10.293Z" fill="#8E98A8"/>
</svg>

   </div>
        </td>
}
        {tableshow.sex&&
        <td>
   <div className="flex gap-2  items-center">
   <p className='text-[#6E7C87] md:text-md text-[12px]'>Sex</p>
    <svg width="8" height="10" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.292787 5.70679C0.105316 5.51926 0 5.26495 0 4.99979C0 4.73462 0.105316 4.48031 0.292787 4.29279L4.29279 0.292786C4.48031 0.105315 4.73462 0 4.99979 0C5.26495 0 5.51926 0.105315 5.70679 0.292786L9.70679 4.29279C9.88894 4.48139 9.98974 4.73399 9.98746 4.99619C9.98518 5.25838 9.88001 5.5092 9.6946 5.6946C9.5092 5.88001 9.25838 5.98518 8.99619 5.98746C8.73399 5.98974 8.48139 5.88894 8.29279 5.70679L5.99979 3.41379V14.9998C5.99979 15.265 5.89443 15.5194 5.70689 15.7069C5.51936 15.8944 5.265 15.9998 4.99979 15.9998C4.73457 15.9998 4.48022 15.8944 4.29268 15.7069C4.10514 15.5194 3.99979 15.265 3.99979 14.9998V3.41379L1.70679 5.70679C1.51926 5.89426 1.26495 5.99957 0.999786 5.99957C0.734622 5.99957 0.480314 5.89426 0.292787 5.70679V5.70679Z" fill="#DBDBDB"/>
</svg>
<svg width="8" height="10" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.71929 10.293C9.90676 10.4805 10.0121 10.7348 10.0121 11C10.0121 11.2652 9.90676 11.5195 9.71929 11.707L5.71929 15.707C5.53176 15.8945 5.27745 15.9998 5.01229 15.9998C4.74712 15.9998 4.49282 15.8945 4.30529 15.707L0.305288 11.707C0.209778 11.6148 0.133596 11.5044 0.0811869 11.3824C0.0287779 11.2604 0.00119157 11.1292 3.77571e-05 10.9964C-0.00111606 10.8636 0.0241854 10.7319 0.0744663 10.609C0.124747 10.4861 0.199 10.3745 0.292893 10.2806C0.386786 10.1867 0.498438 10.1125 0.621334 10.0622C0.74423 10.0119 0.87591 9.9866 1.00869 9.98775C1.14147 9.9889 1.27269 10.0165 1.39469 10.0689C1.5167 10.1213 1.62704 10.1975 1.71929 10.293L4.01229 12.586V1C4.01229 0.734784 4.11765 0.48043 4.30518 0.292893C4.49272 0.105357 4.74707 0 5.01229 0C5.2775 0 5.53186 0.105357 5.71939 0.292893C5.90693 0.48043 6.01229 0.734784 6.01229 1V12.586L8.30529 10.293C8.49282 10.1055 8.74712 10.0002 9.01229 10.0002C9.27745 10.0002 9.53176 10.1055 9.71929 10.293Z" fill="#8E98A8"/>
</svg>
   </div>
        </td>
}
        {tableshow.Phone&&
        <td>

   <div className="flex gap-2 items-center">
   <p className='text-[#6E7C87] md:text-md text-[12px]'>Phone number</p>
    <svg width="8" height="10" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.292787 5.70679C0.105316 5.51926 0 5.26495 0 4.99979C0 4.73462 0.105316 4.48031 0.292787 4.29279L4.29279 0.292786C4.48031 0.105315 4.73462 0 4.99979 0C5.26495 0 5.51926 0.105315 5.70679 0.292786L9.70679 4.29279C9.88894 4.48139 9.98974 4.73399 9.98746 4.99619C9.98518 5.25838 9.88001 5.5092 9.6946 5.6946C9.5092 5.88001 9.25838 5.98518 8.99619 5.98746C8.73399 5.98974 8.48139 5.88894 8.29279 5.70679L5.99979 3.41379V14.9998C5.99979 15.265 5.89443 15.5194 5.70689 15.7069C5.51936 15.8944 5.265 15.9998 4.99979 15.9998C4.73457 15.9998 4.48022 15.8944 4.29268 15.7069C4.10514 15.5194 3.99979 15.265 3.99979 14.9998V3.41379L1.70679 5.70679C1.51926 5.89426 1.26495 5.99957 0.999786 5.99957C0.734622 5.99957 0.480314 5.89426 0.292787 5.70679V5.70679Z" fill="#DBDBDB"/>
</svg>
<svg width="8" height="10" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.71929 10.293C9.90676 10.4805 10.0121 10.7348 10.0121 11C10.0121 11.2652 9.90676 11.5195 9.71929 11.707L5.71929 15.707C5.53176 15.8945 5.27745 15.9998 5.01229 15.9998C4.74712 15.9998 4.49282 15.8945 4.30529 15.707L0.305288 11.707C0.209778 11.6148 0.133596 11.5044 0.0811869 11.3824C0.0287779 11.2604 0.00119157 11.1292 3.77571e-05 10.9964C-0.00111606 10.8636 0.0241854 10.7319 0.0744663 10.609C0.124747 10.4861 0.199 10.3745 0.292893 10.2806C0.386786 10.1867 0.498438 10.1125 0.621334 10.0622C0.74423 10.0119 0.87591 9.9866 1.00869 9.98775C1.14147 9.9889 1.27269 10.0165 1.39469 10.0689C1.5167 10.1213 1.62704 10.1975 1.71929 10.293L4.01229 12.586V1C4.01229 0.734784 4.11765 0.48043 4.30518 0.292893C4.49272 0.105357 4.74707 0 5.01229 0C5.2775 0 5.53186 0.105357 5.71939 0.292893C5.90693 0.48043 6.01229 0.734784 6.01229 1V12.586L8.30529 10.293C8.49282 10.1055 8.74712 10.0002 9.01229 10.0002C9.27745 10.0002 9.53176 10.1055 9.71929 10.293Z" fill="#8E98A8"/>
</svg>
   </div>
        </td>
}
        {tableshow.City&&
        <td className=''>
<div className="">

   <div className="flex gap-2 items-center ">
   <p className='text-[#6E7C87] md:text-md text-[12px]'>Original City</p>
    <svg width="8" height="10" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.292787 5.70679C0.105316 5.51926 0 5.26495 0 4.99979C0 4.73462 0.105316 4.48031 0.292787 4.29279L4.29279 0.292786C4.48031 0.105315 4.73462 0 4.99979 0C5.26495 0 5.51926 0.105315 5.70679 0.292786L9.70679 4.29279C9.88894 4.48139 9.98974 4.73399 9.98746 4.99619C9.98518 5.25838 9.88001 5.5092 9.6946 5.6946C9.5092 5.88001 9.25838 5.98518 8.99619 5.98746C8.73399 5.98974 8.48139 5.88894 8.29279 5.70679L5.99979 3.41379V14.9998C5.99979 15.265 5.89443 15.5194 5.70689 15.7069C5.51936 15.8944 5.265 15.9998 4.99979 15.9998C4.73457 15.9998 4.48022 15.8944 4.29268 15.7069C4.10514 15.5194 3.99979 15.265 3.99979 14.9998V3.41379L1.70679 5.70679C1.51926 5.89426 1.26495 5.99957 0.999786 5.99957C0.734622 5.99957 0.480314 5.89426 0.292787 5.70679V5.70679Z" fill="#DBDBDB"/>
</svg>
<svg width="8" height="10" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.71929 10.293C9.90676 10.4805 10.0121 10.7348 10.0121 11C10.0121 11.2652 9.90676 11.5195 9.71929 11.707L5.71929 15.707C5.53176 15.8945 5.27745 15.9998 5.01229 15.9998C4.74712 15.9998 4.49282 15.8945 4.30529 15.707L0.305288 11.707C0.209778 11.6148 0.133596 11.5044 0.0811869 11.3824C0.0287779 11.2604 0.00119157 11.1292 3.77571e-05 10.9964C-0.00111606 10.8636 0.0241854 10.7319 0.0744663 10.609C0.124747 10.4861 0.199 10.3745 0.292893 10.2806C0.386786 10.1867 0.498438 10.1125 0.621334 10.0622C0.74423 10.0119 0.87591 9.9866 1.00869 9.98775C1.14147 9.9889 1.27269 10.0165 1.39469 10.0689C1.5167 10.1213 1.62704 10.1975 1.71929 10.293L4.01229 12.586V1C4.01229 0.734784 4.11765 0.48043 4.30518 0.292893C4.49272 0.105357 4.74707 0 5.01229 0C5.2775 0 5.53186 0.105357 5.71939 0.292893C5.90693 0.48043 6.01229 0.734784 6.01229 1V12.586L8.30529 10.293C8.49282 10.1055 8.74712 10.0002 9.01229 10.0002C9.27745 10.0002 9.53176 10.1055 9.71929 10.293Z" fill="#8E98A8"/>
</svg>
   </div>
</div>
        </td>
}
{props?.super==0&&tableshow.fellowship&&
        <td className=''>
<div className="">

   <div className="flex gap-2 items-center ">
   <p className='text-[#6E7C87] md:text-md text-[12px]'>Fellowship</p>
    <svg width="8" height="10" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.292787 5.70679C0.105316 5.51926 0 5.26495 0 4.99979C0 4.73462 0.105316 4.48031 0.292787 4.29279L4.29279 0.292786C4.48031 0.105315 4.73462 0 4.99979 0C5.26495 0 5.51926 0.105315 5.70679 0.292786L9.70679 4.29279C9.88894 4.48139 9.98974 4.73399 9.98746 4.99619C9.98518 5.25838 9.88001 5.5092 9.6946 5.6946C9.5092 5.88001 9.25838 5.98518 8.99619 5.98746C8.73399 5.98974 8.48139 5.88894 8.29279 5.70679L5.99979 3.41379V14.9998C5.99979 15.265 5.89443 15.5194 5.70689 15.7069C5.51936 15.8944 5.265 15.9998 4.99979 15.9998C4.73457 15.9998 4.48022 15.8944 4.29268 15.7069C4.10514 15.5194 3.99979 15.265 3.99979 14.9998V3.41379L1.70679 5.70679C1.51926 5.89426 1.26495 5.99957 0.999786 5.99957C0.734622 5.99957 0.480314 5.89426 0.292787 5.70679V5.70679Z" fill="#DBDBDB"/>
</svg>
<svg width="8" height="10" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.71929 10.293C9.90676 10.4805 10.0121 10.7348 10.0121 11C10.0121 11.2652 9.90676 11.5195 9.71929 11.707L5.71929 15.707C5.53176 15.8945 5.27745 15.9998 5.01229 15.9998C4.74712 15.9998 4.49282 15.8945 4.30529 15.707L0.305288 11.707C0.209778 11.6148 0.133596 11.5044 0.0811869 11.3824C0.0287779 11.2604 0.00119157 11.1292 3.77571e-05 10.9964C-0.00111606 10.8636 0.0241854 10.7319 0.0744663 10.609C0.124747 10.4861 0.199 10.3745 0.292893 10.2806C0.386786 10.1867 0.498438 10.1125 0.621334 10.0622C0.74423 10.0119 0.87591 9.9866 1.00869 9.98775C1.14147 9.9889 1.27269 10.0165 1.39469 10.0689C1.5167 10.1213 1.62704 10.1975 1.71929 10.293L4.01229 12.586V1C4.01229 0.734784 4.11765 0.48043 4.30518 0.292893C4.49272 0.105357 4.74707 0 5.01229 0C5.2775 0 5.53186 0.105357 5.71939 0.292893C5.90693 0.48043 6.01229 0.734784 6.01229 1V12.586L8.30529 10.293C8.49282 10.1055 8.74712 10.0002 9.01229 10.0002C9.27745 10.0002 9.53176 10.1055 9.71929 10.293Z" fill="#8E98A8"/>
</svg>
   </div>
</div>
        </td>
}
        {tableshow.church&&
        <td className=''>
<div className="">

   <div className="flex gap-2 items-center  ">
   <p className='text-[#6E7C87] md:text-md text-[12px]'>Church</p>
    <svg width="8" height="10" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.292787 5.70679C0.105316 5.51926 0 5.26495 0 4.99979C0 4.73462 0.105316 4.48031 0.292787 4.29279L4.29279 0.292786C4.48031 0.105315 4.73462 0 4.99979 0C5.26495 0 5.51926 0.105315 5.70679 0.292786L9.70679 4.29279C9.88894 4.48139 9.98974 4.73399 9.98746 4.99619C9.98518 5.25838 9.88001 5.5092 9.6946 5.6946C9.5092 5.88001 9.25838 5.98518 8.99619 5.98746C8.73399 5.98974 8.48139 5.88894 8.29279 5.70679L5.99979 3.41379V14.9998C5.99979 15.265 5.89443 15.5194 5.70689 15.7069C5.51936 15.8944 5.265 15.9998 4.99979 15.9998C4.73457 15.9998 4.48022 15.8944 4.29268 15.7069C4.10514 15.5194 3.99979 15.265 3.99979 14.9998V3.41379L1.70679 5.70679C1.51926 5.89426 1.26495 5.99957 0.999786 5.99957C0.734622 5.99957 0.480314 5.89426 0.292787 5.70679V5.70679Z" fill="#DBDBDB"/>
</svg>
<svg width="8" height="10" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.71929 10.293C9.90676 10.4805 10.0121 10.7348 10.0121 11C10.0121 11.2652 9.90676 11.5195 9.71929 11.707L5.71929 15.707C5.53176 15.8945 5.27745 15.9998 5.01229 15.9998C4.74712 15.9998 4.49282 15.8945 4.30529 15.707L0.305288 11.707C0.209778 11.6148 0.133596 11.5044 0.0811869 11.3824C0.0287779 11.2604 0.00119157 11.1292 3.77571e-05 10.9964C-0.00111606 10.8636 0.0241854 10.7319 0.0744663 10.609C0.124747 10.4861 0.199 10.3745 0.292893 10.2806C0.386786 10.1867 0.498438 10.1125 0.621334 10.0622C0.74423 10.0119 0.87591 9.9866 1.00869 9.98775C1.14147 9.9889 1.27269 10.0165 1.39469 10.0689C1.5167 10.1213 1.62704 10.1975 1.71929 10.293L4.01229 12.586V1C4.01229 0.734784 4.11765 0.48043 4.30518 0.292893C4.49272 0.105357 4.74707 0 5.01229 0C5.2775 0 5.53186 0.105357 5.71939 0.292893C5.90693 0.48043 6.01229 0.734784 6.01229 1V12.586L8.30529 10.293C8.49282 10.1055 8.74712 10.0002 9.01229 10.0002C9.27745 10.0002 9.53176 10.1055 9.71929 10.293Z" fill="#8E98A8"/>
</svg>
   </div>
</div>
        </td>
}
        {tableshow.language&&
        <td className=''>
<div className="">

   <div className="flex gap-2 items-center ">
   <p className='text-[#6E7C87] md:text-md text-[12px]'>Language</p>
    <svg width="8" height="10" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.292787 5.70679C0.105316 5.51926 0 5.26495 0 4.99979C0 4.73462 0.105316 4.48031 0.292787 4.29279L4.29279 0.292786C4.48031 0.105315 4.73462 0 4.99979 0C5.26495 0 5.51926 0.105315 5.70679 0.292786L9.70679 4.29279C9.88894 4.48139 9.98974 4.73399 9.98746 4.99619C9.98518 5.25838 9.88001 5.5092 9.6946 5.6946C9.5092 5.88001 9.25838 5.98518 8.99619 5.98746C8.73399 5.98974 8.48139 5.88894 8.29279 5.70679L5.99979 3.41379V14.9998C5.99979 15.265 5.89443 15.5194 5.70689 15.7069C5.51936 15.8944 5.265 15.9998 4.99979 15.9998C4.73457 15.9998 4.48022 15.8944 4.29268 15.7069C4.10514 15.5194 3.99979 15.265 3.99979 14.9998V3.41379L1.70679 5.70679C1.51926 5.89426 1.26495 5.99957 0.999786 5.99957C0.734622 5.99957 0.480314 5.89426 0.292787 5.70679V5.70679Z" fill="#DBDBDB"/>
</svg>
<svg width="8" height="10" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.71929 10.293C9.90676 10.4805 10.0121 10.7348 10.0121 11C10.0121 11.2652 9.90676 11.5195 9.71929 11.707L5.71929 15.707C5.53176 15.8945 5.27745 15.9998 5.01229 15.9998C4.74712 15.9998 4.49282 15.8945 4.30529 15.707L0.305288 11.707C0.209778 11.6148 0.133596 11.5044 0.0811869 11.3824C0.0287779 11.2604 0.00119157 11.1292 3.77571e-05 10.9964C-0.00111606 10.8636 0.0241854 10.7319 0.0744663 10.609C0.124747 10.4861 0.199 10.3745 0.292893 10.2806C0.386786 10.1867 0.498438 10.1125 0.621334 10.0622C0.74423 10.0119 0.87591 9.9866 1.00869 9.98775C1.14147 9.9889 1.27269 10.0165 1.39469 10.0689C1.5167 10.1213 1.62704 10.1975 1.71929 10.293L4.01229 12.586V1C4.01229 0.734784 4.11765 0.48043 4.30518 0.292893C4.49272 0.105357 4.74707 0 5.01229 0C5.2775 0 5.53186 0.105357 5.71939 0.292893C5.90693 0.48043 6.01229 0.734784 6.01229 1V12.586L8.30529 10.293C8.49282 10.1055 8.74712 10.0002 9.01229 10.0002C9.27745 10.0002 9.53176 10.1055 9.71929 10.293Z" fill="#8E98A8"/>
</svg>
   </div>
</div>
        </td>
        }
        {tableshow.Host&&
        <td className=''>
<div className="">

   <div className="flex gap-2 items-center ">
   <p className='text-[#6E7C87] md:text-md text-[12px]'>Host</p>
    <svg width="8" height="10" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.292787 5.70679C0.105316 5.51926 0 5.26495 0 4.99979C0 4.73462 0.105316 4.48031 0.292787 4.29279L4.29279 0.292786C4.48031 0.105315 4.73462 0 4.99979 0C5.26495 0 5.51926 0.105315 5.70679 0.292786L9.70679 4.29279C9.88894 4.48139 9.98974 4.73399 9.98746 4.99619C9.98518 5.25838 9.88001 5.5092 9.6946 5.6946C9.5092 5.88001 9.25838 5.98518 8.99619 5.98746C8.73399 5.98974 8.48139 5.88894 8.29279 5.70679L5.99979 3.41379V14.9998C5.99979 15.265 5.89443 15.5194 5.70689 15.7069C5.51936 15.8944 5.265 15.9998 4.99979 15.9998C4.73457 15.9998 4.48022 15.8944 4.29268 15.7069C4.10514 15.5194 3.99979 15.265 3.99979 14.9998V3.41379L1.70679 5.70679C1.51926 5.89426 1.26495 5.99957 0.999786 5.99957C0.734622 5.99957 0.480314 5.89426 0.292787 5.70679V5.70679Z" fill="#DBDBDB"/>
</svg>
<svg width="8" height="10" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.71929 10.293C9.90676 10.4805 10.0121 10.7348 10.0121 11C10.0121 11.2652 9.90676 11.5195 9.71929 11.707L5.71929 15.707C5.53176 15.8945 5.27745 15.9998 5.01229 15.9998C4.74712 15.9998 4.49282 15.8945 4.30529 15.707L0.305288 11.707C0.209778 11.6148 0.133596 11.5044 0.0811869 11.3824C0.0287779 11.2604 0.00119157 11.1292 3.77571e-05 10.9964C-0.00111606 10.8636 0.0241854 10.7319 0.0744663 10.609C0.124747 10.4861 0.199 10.3745 0.292893 10.2806C0.386786 10.1867 0.498438 10.1125 0.621334 10.0622C0.74423 10.0119 0.87591 9.9866 1.00869 9.98775C1.14147 9.9889 1.27269 10.0165 1.39469 10.0689C1.5167 10.1213 1.62704 10.1975 1.71929 10.293L4.01229 12.586V1C4.01229 0.734784 4.11765 0.48043 4.30518 0.292893C4.49272 0.105357 4.74707 0 5.01229 0C5.2775 0 5.53186 0.105357 5.71939 0.292893C5.90693 0.48043 6.01229 0.734784 6.01229 1V12.586L8.30529 10.293C8.49282 10.1055 8.74712 10.0002 9.01229 10.0002C9.27745 10.0002 9.53176 10.1055 9.71929 10.293Z" fill="#8E98A8"/>
</svg>
   </div>
</div>
        </td>
}
       
        
    </tr>
    </thead>
    <tbody>

   
   {currentItems&&currentItems?.map((data,index)=>(
    <tr className="border border-solide h-[40px] hover:bg-gray-100 dark:hover:text-black" key={index} onClick={()=>userProfile(data.firstName,data.lastName,data.sex,data.phone,data.fellowshipName,data.city,data.cityFrom,data.church,data.language,data.isHostAvailable,data.arrivalDate,data.created_at,data.campusID,data.id)} >
     {tableshow.fullName&&
    <td className="md:pl-2 text-[12px] md:text-md  "><p>{data?.firstName+" "+data?.lastName}</p></td>
     }
   
    {tableshow.sex&&
    <td className=" text-[12px] md:text-md "><p>{data?.sex}</p><p> 
    
      </p>
      </td>
}
{tableshow.Phone&&
    <td className="text-[12px] md:text-md "><p>{data?.phone}</p> </td>
}
    {tableshow.City&&
    <td className="text-[12px]"> <div><p>
  {data?.city}{data?.cityFrom} 
    </p>
      </div></td>
    }
     {props?.super==0&&tableshow.fellowship&&
    <td className="text-[12px]"> <div> <p>
  {data?.fellowship}
    </p>
  
      </div></td>
    }
 {tableshow.church&&
    <td className="md:text-md text-[12px]">
   <p>
   {data?.church}
   </p>
     
     
      </td>
}
      {tableshow.language&&
    <td className="md:text-md text-[12px] " >
     <p>
     {data.language}
     </p>
     
     
      </td>
      }
    {tableshow.Host&&
    <td className="flex items-center md:text-md text-[12px]  gap-4 mt-4 text-white ">
      

        {data?.isHostAvailable!=1?
        <p className=" -mt-2 hidden md:block rounded-[6px] md:text-md text-[12px] px-2 py-1 w-20 text-center text-[#CC0905] bg-[#FFEFEB] "  > no host</p>
         :
<p className=" -mt-2 hidden md:block rounded-[6px] md:text-md text-[12px] px-2 py-1 w-20 text-center text-[#119C2B] bg-[#EBFFF1]"  >has host</p>
}

    </td>
}
   
  </tr>
   ))}
       </tbody>
  
  
</table>
: 
<div className="m-2 mb-8">
<Empty/></div>
}
     </>
}
</div>
        </div>
        {pageCount&&
        <div className="flex justify-center items-center gap-5 p-5 w-full ">
        <PaginatedItems pageCount={pageCount} itemsPerPage={rowsPerPage}  items={props?.student} setItemOffset={setItemOffset} />
        <RowSelect onChange={handleRowsPerPageChange} />
    
        </div>
}
<ProfileModal width='1000' Button="OK" Action={Cancel} cancel={Cancel} setOpen={setOpen} open={open} title='Student Profile'   >
    {/* <h1 className="text-center font-bold text-[32px] mb-[89px] dark:bg-black dark:text-white">This is me!</h1> */}
    <div className="flex md:gap-[40px]  items-end p-3 dark:bg-black dark:text-white dark:rounded-md">
        {/* <div> */}
            {/* <img className="w-[239px] h-[239px] rounded-[15px]" src={owener} alt="" /> */}
        {/* </div> */}
        <div className="flex gap-5 md:gap-[116px]"> 
            <div>
                <p>Full Name</p>
                <p>Phone number</p>
                <p>Home Church</p>
                <p>Sex</p>
                <p>Original City</p>
                <p>language</p>
               {props?.super==0&& <p>Fellowship</p>}
                <p>Have Host</p>
                <p>Arrive Date</p>
                
                {/* <p>Registered Date</p> */}
            </div>
            <div>
                <p>{modalData.FullName+" "+modalData.lastName}</p>
                <p>{modalData.Phone}</p>
                <p>{modalData.Church}</p>
                <p>{modalData.Sex}</p>
                <p>{modalData.City}</p>
                <p>{modalData.Lang}</p>
                <p>{props?.super==0&&modalData.fellowship}</p>
                <p>{modalData.Host!=1?"No Host":"Has Host"}</p>
                <p>{modalData.arrivalDate}</p>
                {/* <p>{modalData.Registered}</p> */}
            </div>
        </div>
    </div>
</ProfileModal>
            </div>
     </div>
     </div>
     
    )
}
export default Student;