import React from 'react'

function Device(props) {
  return (
    <div className=" rounded-[10px] text-center bg-[#F8F7F7]">
    <div className="mt-[33px] mb-[28px]">
      <h1 className="">{props.percent}%</h1>
      <p>{props.device}</p>
    </div>
    {props.children}
  </div>
  )
}

export default Device