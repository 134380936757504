import React, { createRef, useEffect, useRef, useState } from 'react'
import { BsTelephoneFill,BsTelegram,BsFacebook } from "react-icons/bs";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { useInView } from 'framer-motion';
import { BASE_URL } from '../../Function/Base';


function Contactui(props) {
  // AOS.init();
  const ref =useRef(null);
  const isView =useInView(ref,{margin:'-20% 0px -20% 0px'});

  const handleClick = (number) => {
   window.location.href = 'tel:555-555-5555';
  };
  const [currentSlide, setCurrentSlide] = useState(0);
    const [loaded, setLoaded] = useState(false);
    
    const [deviceType, setDeviceType] = useState("");
    const [itemsPerPage,setItemPerPage]=useState(4)
  useEffect(() => {
     const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator?.userAgent);
     setDeviceType(isMobile ? "mobile" : "desktop");
     if(deviceType==="mobile"){
    
        setItemPerPage(props?.props?.length<=1&&2||props?.props?.length<=2&&2||props?.props?.length>=3&&3)
     
      
       }else if(deviceType==="desktop"){
        setItemPerPage(props?.props?.length<=1&&1||props?.props?.length<=2&&2||props?.props?.length<=3&&3||props?.props?.length>3&&4)
        
      }
   }, [deviceType]);
 
    const [sliderRef, instanceRef] = useKeenSlider({
      initial: 0,
      mode: "free-snap",
      slides: {
        
        perView: itemsPerPage,
        spacing: 2
      },
      min:3,
      slideChanged(slider) {
        setCurrentSlide(slider?.track?.details?.rel);
      },
      created() {
        setLoaded(true);
      }
    });
    function call(record){
      //window.open(`tel:${record}`);
      window.location.href = `tel:${record}`;
    }


    function toTitleCase(str) {
      const titleCase = str
        .toLowerCase()
        .split(' ')
        .map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
    
      return titleCase;
    }
    const checkAndAddHttps=(link)=>{
const hasHttps=link.startsWith("https://");
return hasHttps?link:"https://"+link;
    }
  return (
<div ref={ref} id='Contact' className=' flex justify-center overflow-hidden lg:mb-[46px]' >
  <div className='flex flex-col max-w-full lg:max-w-[1440px] lg:justify-center lg:items-center'>
    <div className='flex flex-col justify-center items-center mt-[54px] lg:mt-[141px] md:mt-[201px]'> 
      <p className={`${isView?'translation-all duration-500 scale-500':'scale-0'} text-[24px] md:text-[48px] lg:text-[64px] leading-[24px] md:leading-[48px] Poppins Popp-600  `}>{props?.content?.leaders_Title}</p>
      <p className={`${isView?'translation-all duration-700 scale-500':'scale-0'} text-[12px] text-[#6B1768] md:text-[14px] lg:text-[20px] Poppins Popp-400 Poppins md:mt-[8px] lg:mt-[13px] text-[#6B1768] lg:mb-[60px]`}>{props?.content?.leaders_Subtitle}</p>
    </div>
  

    <div  className={` keen-slider lg:mb-[100px]  mt-8  overflow-hidden  `} ref={sliderRef}>
    {props?.props?.map((data,index)=>(
           
    <div  className={` lg:mt-12 md:mt-12 keen-slider__slide mt-5  lg:first:ml-12`}>
 <div  className={`${isView?'translation-all duration-500 scale-y-1000':'scale-0'} lg:w-[270px] md:w-[270px] flex  flex-col items-center justify-center `} key={index}>
      <img  className='rounded-full w-[111px] h-[111px] md:w-[180px] md:h-[180px] lg:w-[264px] lg:h-[264px] object-cover ' src={`${BASE_URL}/${data?.photo}`} alt="" />
      <div className='flex flex-col items-center justify-center md:mt-[10px] lg:mt-[46px] '>
        <p className='Plus text-[14px] md:text-[18px] lg:text-[21px] md:leading-[22px] lg:leading-[32px] Plus-700 name  '>{toTitleCase(data?.name)}</p>
        <p className='Plus text-[12px] md:text-[14px] lg:text-[16px] leading-[26px] text-[#52525B] Plus-400 name text-center'>{toTitleCase(data?.role)}</p>
      </div>
      <div className='flex gap-3 justify-center items-center md:mt-[6px] lg:mt-[15px]'>
      <svg onClick={()=>call(data.phone)} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.2974 18.2882L16.7871 18.8265C16.7871 18.8265 15.5725 20.1039 12.2584 16.6149C8.94434 13.1259 10.1589 11.8485 10.1589 11.8485L10.4808 11.5086C11.2726 10.6742 11.3477 9.33516 10.6558 8.3572L9.24266 6.35754C8.38583 5.14631 6.7316 4.98706 5.75028 6.01997L3.99062 7.87159C3.50501 8.38412 3.17977 9.04693 3.21902 9.78376C3.31996 11.6679 4.1252 15.7199 8.61574 20.4493C13.3788 25.4625 17.848 25.6621 19.675 25.4816C20.2537 25.4255 20.7561 25.1126 21.161 24.6864L22.7535 23.0086C23.8302 21.8759 23.5274 19.9357 22.1501 19.1439L20.0081 17.9102C19.1041 17.391 18.0039 17.5435 17.2974 18.2871V18.2882Z" fill="#6B1768"/>
<path d="M20.0382 14.3089C21.2576 14.3087 22.4438 13.911 23.4169 13.176C24.39 12.441 25.097 11.4089 25.4307 10.236C25.7645 9.06313 25.7068 7.81341 25.2664 6.67626C24.826 5.5391 24.0269 4.57649 22.9903 3.93431C21.9536 3.29213 20.7359 3.00538 19.5216 3.11752C18.3073 3.22965 17.1627 3.73456 16.2612 4.55573C15.3596 5.3769 14.7504 6.46957 14.5257 7.66814C14.301 8.86672 14.4732 10.1059 15.0161 11.1978C15.1187 11.3961 15.1454 11.625 15.0912 11.8415L14.757 13.0898C14.7239 13.2134 14.7239 13.3435 14.7571 13.4671C14.7902 13.5907 14.8552 13.7033 14.9457 13.7938C15.0362 13.8843 15.1489 13.9493 15.2724 13.9825C15.396 14.0156 15.5261 14.0156 15.6497 13.9825L16.898 13.6483C17.1145 13.5941 17.3435 13.6208 17.5417 13.7234C18.3172 14.1096 19.1719 14.31 20.0382 14.3089Z" fill="#6B1768"/>
</svg>
 {data?.telegramLink&& <a target='blank' className='cursor-pointer' href={checkAndAddHttps(data?.telegramLink)}>  <BsTelegram style={{ color: '#6B1768' }} size={25}/></a>
  }  </div>
    </div>     
    </div>
             
    ))}

    </div>
    
    {instanceRef?.current?.track?.details?.slides?.length-itemsPerPage>0&&
    
<div className='flex gap-2 items-center justify-center my-[50px] '>
{currentSlide === 0?'':
<svg 
 left
 onClick={(e) =>
   e.stopPropagation() || instanceRef?.current?.prev()
 }
 className="  cursor-pointer "
width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M8.1213 10.5001H21V13.5001H8.1213L13.0606 18.4395L10.9393 20.5608L2.37866 12.0001L10.9393 3.43945L13.0606 5.56077L8.1213 10.5001Z" fill="#6B1768"/>
</svg>
}
{ instanceRef?.current && (
        <div className="dots ">
          {[
            ...Array(instanceRef?.current?.track?.details?.slides?.length-itemsPerPage+1).keys()
          ].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx);
                }}
                className={"dot    cursor-pointer " + (currentSlide === idx ? "active" : "")}
              ></button>
            );
          })}
        </div>
      )}
     {
        currentSlide === instanceRef?.current?.track?.details?.slides?.length-itemsPerPage ?'':
        <svg 
       onClick={(e) =>
        e.stopPropagation() || instanceRef?.current?.next()
      }
      
        className="cursor-pointer "
        width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M15.478 13.5568H2.92432V10.4428H15.478L10.6633 5.31571L12.7311 3.11377L21.0757 11.9998L12.7311 20.8858L10.6633 18.6838L15.478 13.5568Z" fill="#6B1768"/>
</svg>
}
        
</div>
    }
  </div>
  </div>
  )
}

export default Contactui;


                 