import React from 'react'

function TextArea(props) {
  return (
    <div>
        <lable className={` text-[14px] font-plus ${props.lableStyle}`}>{props.lable}</lable>
   <textarea minLength={props.min} maxLength={props.max} value={props.value} name={props.name} onChange={props.onChange} placeholder={props.placeholder} className={ `text-[14px] font-plus ${props.style}`}  rows={props.row} cols={props.col}>
{props.children}
   </textarea>
    </div>
  )
}

export default TextArea