import logo from "./logo.svg";
import {
  BrowserRouter,
  HashRouter,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import Login from "./page/Login";
import FackData from "./Database/Fackdata";
import SignUp from "./page/SignUp";
import ForgetPassword from "./page/ForgetPassword";
import AuthNotify from "./Component/AuthNotify";
import EmailVerify from "./page/EmailVerify";
import RestPasswordform from "./page/RestPasswordform";
import MainPage from "./Component/MainPage";
import Dashboard from "./page/Dashboard";
import SideNave from "./Component/SideNave";
import TopNav from "./Component/TopNav";
import Content from "./page/Content";
import NewPage from "./page/NewPage";
import { useEffect, useState } from "react";
import Profile from "./page/Profile";
import Student from "./page/Student";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Function from "./Function/Function";
import axios from "axios";
import Admin from "./page/Admin";
import Cookies from "js-cookie";
import { BASE_URL } from "./Function/Base";
import SignOut from "./Component/SignOut";
import InviteSignUp from "./page/InviteSignUp";
import {
  Button,
  message,
  Steps,
  Switch,
  theme,
  notification,
  Spin,
  Alert,
} from "antd";
import { useAuth } from "./Function/AuthProvider";
import RequireAuth from "./Function/RequireAuth";

import CampusDemo from "./DemoPage/Page/CampusDemo";
import Toor from "./Component/Toor";
import Esist from "./Component/Esist";
import Declen from "./page/Declen";
import SDashBoard from "./SuperAdmin/SDashBoard";
import ALLcampus from "./SuperAdmin/ALLcampus";
import { Offline, Online } from "react-detect-offline";

function App() {
  const [expand, setExpand] = useState(true);
  const u = null;
  const [student, setStuden] = useState(undefined);
  const [admin, setAdmin] = useState(null);

  const [formData, setForm] = useState({
    ID: Cookies.get("campus"),
    profile: null,
  });
  const [selectedValue, setSelected] = useState(formData.ID);
  const [data, SetData] = useState([]);
  const [profile, setProfile] = useState(null);
  const [singleProfile, setSprofile] = useState(null);
  const [notify, setNotify] = useState();
  const auth = useAuth();
  const [dash, setNEWDASH] = useState(null);
  const token = Cookies.get("token");
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const [api, contextHolder] = notification.useNotification();
  function ALLNotification(placement, location) {
    api.success({
      message: ``,
      description: `${placement}`,
      location,
    });
  }
  function changes() {
    setExpand(!expand);
  }

  function HandleChange(e) {
    auth.SETCAMPUS(e);
    Cookies.set("campus", e);
    setForm({ ...formData, ID: e });
    auth.SelectCampus(e);
    // setSelected(auth.selectCAMPUS)
    // auth.setSeting(!auth.openSeting)
  }

  useEffect(() => {
    setForm({ ...formData, ID: Cookies.get("campus") });
    axios.defaults.headers.common["Authorization"] = `Bearer ${auth.token}`;
    axios
      .post(`${BASE_URL}/api/get-profile`)
      .then((res) => {
        setProfile(res.data?.admins);
      })
      .catch(function () {});

    axios
      .post(`${BASE_URL}/api/get-tour-status`)
      .then((res) => {
        if (res.data.status === 200) {
          if (res.data.tour_status === "true") {
          } else {
            // auth.ToorOpen(true)
          }
        }
      })
      .catch(function () {});
    //theme
    axios
      .post(`${BASE_URL}/api/get-theme`)
      .then((res) => {
        if (res.data.status === 200) {
          //auth.Team(res.data.theme);
          auth.toggleDarkMode(res.data.theme);
        }
      })
      .catch(function () {});
  }, [formData.profile, auth.token, auth.apiload]);
  useEffect(() => {
    axios
      .post(`${BASE_URL}/api/view-campuses`)
      .then((res) => {
        const datas = res?.data;

        if (res.data.status === 200) {
          SetData(datas);
        }
      })
      .catch();
  }, [auth.token, auth.apiload, formData.ID]);

  function ProfileUPDATE(one) {
    setForm({ ...formData, profile: one });
  }
  useEffect(() => {
    if (formData.ID != null) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${auth.token}`;
      // setAdmin([])
      // setStuden(null)
      axios
        .get(`${BASE_URL}/api/get-admins?campusID=${formData.ID}`)
        .then(function (response) {
          if (response.data?.status === 200) {
            setAdmin(response.data?.admins);
            setSelected(formData.ID);
          } else {
            // setStuden(null)
            setAdmin(null);
          }
        })
        .catch(function (error) {
          // handle error
          //  setStuden(null)
          setAdmin(null);
        })
        .finally(function () {
          // always executed
        });

      //

      axios
        .get(`${BASE_URL}/api/get-students?campusID=${formData.ID}`)
        .then(function (response) {
          // handle success
          // console.log(response)
          if (response.data?.status === 200) {
            setStuden(response.data?.students);
          } else {
            setStuden(null);
            // setAdmin(null)
          }
        })
        .catch(function (error) {
          setStuden(null);
          // setAdmin(null)
        })
        .finally(function (error) {});
      //
      axios
        .post(`${BASE_URL}/api/dashboard-campus-admin?campusID=${formData.ID}`)
        .then(function (response) {
          // handle success
          if (response.data?.status === 200) {
            setNEWDASH(response.data);
          } else if (response.data?.status === 401) {
            auth.logout();
          } else {
            // setNEWDASH(null);
          }
        })
        .catch(function (error) {})
        .finally(function (error) {});
      //
    } else {
      setAdmin(null);
    }
  }, [Cookies.get("campus"), formData.ID, auth.token, auth.apiload]);

  // Check if the auth.token has expired
  const exp = Cookies.get("expiresIn");

  const expirationDate = exp;
  const now = new Date();
  const nowDate = new Date();
  nowDate.setDate(now.getDate());
  const isExpired = nowDate > expirationDate;
  // if (isExpired) {
  //   Cookies.remove('auth.token')
  //   SignOut()
  // }

  useEffect(() => {
    if (auth.Loder === "dashi") {
      auth.GetSuperDashi();
    }else if(auth.loder==='OtherStudent'){
      auth?.GetComingSoonStudent();
    } else if (auth.Loder === "AllStudent") {
      auth.GetAllStudent();
    } else if (auth.Loder === "Alladmin") {
      auth.GetAllAdmin();
    } else if (auth.Loder === "All") {
      if (auth?.roleSwitch === true) {
        auth.GetSuperDashi();
        auth.GetAllStudent();
        auth?.GetComingSoonStudent();
        auth.GetAllAdmin();
        auth.GetAllCampus();
      }
    }
    {
      auth?.roleSwitch && auth.GetSuperDashi();
      auth.GetAllStudent();

      auth?.GetComingSoonStudent();
      auth.GetAllAdmin();
      auth.GetAllCampus();
    }
  }, [auth.Loder, auth.userType, auth?.roleSwitch, auth?.apiloadSuper]);

  return (
    <BrowserRouter>
      {auth.token && auth.demo === undefined&&Cookies.get('isEmailVerified')==='true' ? (
        <div
          className={` ${
            auth.isDarkMode
              ? "dark dark:bg-[#000] dark:text-gray-500"
              : "bg-[#F5F5F5]"
          }} flex dark:bg-[#000] dark:text-gray-500`}
        >
          <div
            className={`${
              expand === false
                ? "duration-500 w-[50%] md:hidden fixed z-50 overflow-hidden dark:bg-[#000] dark:text-gray-500"
                : " duration-500 hidden md:hidden fixed z-50 overflow-hidden dark:bg-[#000] dark:text-gray-500"
            }`}
          >
            <SideNave
              selectedValue={selectedValue}
              name="ID"
              HandleChange={HandleChange}
              compusData={data}
              onclick={changes}
              expand={expand}
            />
          </div>

          <div
            className={`${
              expand === true
                ? "w-[17.5%] lg:w-[17.5%] md:w-[25.5%] duration-500 hidden md:block  fixed z-50 overflow-hidden dark:bg-[#000] dark:text-gray-500"
                : " duration-500 hidden md:block w-[5.5%] fixed z-50 overflow-hidden dark:bg-[#000] dark:text-gray-500"
            }`}
          >
            <SideNave
              selectedValue={selectedValue}
              name="ID"
              HandleChange={HandleChange}
              compusData={data}
              onclick={changes}
              expand={expand}
            />
          </div>

          <div
            className={`${
              expand === true
                ? "duration-500 w-full lg:w-[82.5%] md:w-[74.5%] fixed  z-50 lg:ml-[17.5%] md:ml-[25.5%] dark:bg-[#000] dark:text-gray-500 "
                : "duration-500 w-full md:w-[94.5%] fixed  z-50  md:ml-[5.5%] dark:bg-[#000] dark:text-gray-500"
            }`}
          >
            <div className="bg-[#AF20F2] py-2">
              <p className="text-center   text-white ">
                Do you need help? Just contact us? +251986339852/+251925679877
              </p>{" "}
            </div>
            {/* <div className=" text-center duration-500  bg-green-400 text-white py-5 flex justify-center items-center">
       <p> Do you need help? Just contact us? +251986339852/+251925679877 </p>
      </div> */}

            <Offline>
              <div className="  duration-500  bg-red-400 text-white py-5 flex  justify-center items-center">
                You are offline. Please check your internet connection.
              </div>
            </Offline>
            <TopNav
              onclick={changes}
              NOTIFY={notify}
              formData={formData}
              ProfileUPDATE={ProfileUPDATE}
              isDarkMode={auth.isDarkMode}
              toggleDarkMode={auth.toggleDarkMode}
              PROFILE={profile}
              selectedValue={selectedValue}
              name="ID"
              HandleChange={HandleChange}
              compusData={data}
              title="title"
            />

            <div></div>
          </div>
        </div>
      ) : (
        ""
      )}
      <Routes>
        {auth.token &&Cookies.get('isEmailVerified')==='true'? (
          <>
            <Route
              exact
              path="/"
              element={
                <RequireAuth loginPath="/login">
                  <Dashboard
                    dash={dash != null ? dash : ""}
                    isDarkMode={auth.isDarkMode}
                    NOTIFY={notify}
                    PROFILE={profile}
                    compusData={data}
                    selectedValue={selectedValue}
                    HandleChange={HandleChange}
                    admin={admin}
                    student={student}
                    data={FackData.auth}
                    expand={expand}
                    changes={changes}
                    title="DashBoard"
                  />
                </RequireAuth>
              }
            />
            <Route
              exact
              path="/SuperDashboard"
              element={
                <RequireAuth loginPath="/login">
                  <SDashBoard
                    compusData={data}
                    isDarkMode={auth.isDarkMode}
                    contextHolder={contextHolder}
                    ALLNotification={ALLNotification}
                    NOTIFY={notify}
                    PROFILE={profile}
                    selectedValue={selectedValue}
                    HandleChange={HandleChange}
                    data={FackData.auth}
                    expand={expand}
                    changes={changes}
                    title="DashBoard"
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/content"
              element={
                <RequireAuth loginPath="/login">
                  <Content
                    compusData={data}
                    isDarkMode={auth.isDarkMode}
                    contextHolder={contextHolder}
                    ALLNotification={ALLNotification}
                    NOTIFY={notify}
                    PROFILE={profile}
                    selectedValue={selectedValue}
                    HandleChange={HandleChange}
                    data={FackData.auth}
                    expand={expand}
                    changes={changes}
                    title="Content"
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/campus"
              element={
                <RequireAuth loginPath="/login">
                  <ALLcampus
                    compusData={data}
                    isDarkMode={auth.isDarkMode}
                    contextHolder={contextHolder}
                    ALLNotification={ALLNotification}
                    NOTIFY={notify}
                    PROFILE={profile}
                    selectedValue={selectedValue}
                    HandleChange={HandleChange}
                    data={FackData.auth}
                    expand={expand}
                    changes={changes}
                    title="Content"
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/newPage"
              element={
                <RequireAuth loginPath="/login">
                  <NewPage
                    isDarkMode={auth.isDarkMode}
                    NOTIFY={notify}
                    PROFILE={profile}
                    compusData={data}
                    selectedValue={selectedValue}
                    HandleChange={HandleChange}
                    data={FackData.auth}
                    expand={expand}
                    changes={changes}
                    title="Create Page"
                  />{" "}
                </RequireAuth>
              }
            />

            <Route
              path="/profile"
              element={
                <RequireAuth loginPath="/login">
                  <Profile
                    isDarkMode={auth.isDarkMode}
                    NOTIFY={notify}
                    PROFILE={profile}
                    ProfileUPDATE={ProfileUPDATE}
                    compusData={data}
                    selectedValue={selectedValue}
                    HandleChange={HandleChange}
                    data={FackData.auth}
                    expand={expand}
                    changes={changes}
                    title="Profile"
                  />{" "}
                </RequireAuth>
              }
            />

            <Route
              path="/Student"
              element={
                <RequireAuth loginPath="/login">
                  <Student
                    isDarkMode={auth.isDarkMode}
                    NOTIFY={notify}
                    PROFILE={profile}
                    compusData={data}
                    selectedValue={selectedValue}
                    student={student}
                    HandleChange={HandleChange}
                    data={Function.studentData}
                    expand={expand}
                    changes={changes}
                    title="Student"
                  />
                </RequireAuth>
              }
            />

            <Route
              path="/AllStudent"
              element={
                <RequireAuth loginPath="/login">
                  {auth.userType != "Admin" ? (
                    <Student
                      super={0}
                      isDarkMode={auth.isDarkMode}
                      NOTIFY={notify}
                      PROFILE={profile}
                      compusData={data}
                      selectedValue={selectedValue}
                      you="admin"
                      studentDash={auth.Totalsudent}
                      student={auth.Totalsudent?.students}
                      HandleChange={HandleChange}
                      data={Function.studentData}
                      expand={expand}
                      changes={changes}
                      title="All Student"
                    />
                  ) : (
                    ""
                  )}
                </RequireAuth>
              }
            />
            <Route
              path="/OtherStudent"
              element={
                <RequireAuth loginPath="/login">
                  {auth.userType != "Admin" ? (
                    <Student
                      super={0}
                      isDarkMode={auth.isDarkMode}
                      NOTIFY={notify}
                      PROFILE={profile}
                      compusData={data}
                      selectedValue={selectedValue}
                      you="admin"
                      studentDash={auth?.OtherStudent}
                      student={auth?.OtherStudent?.students}
                      HandleChange={HandleChange}
                      data={Function.studentData}
                      expand={expand}
                      changes={changes}
                      title="Other Student"
                    />
                  ) : (
                    ""
                  )}
                </RequireAuth>
              }
            />
            <Route
              path="/Admin"
              element={
                <RequireAuth loginPath="/login">
                  <Admin
                    key={0}
                    isDarkMode={auth.isDarkMode}
                    NOTIFY={notify}
                    PROFILE={profile}
                    compusData={data}
                    selectedValue={selectedValue}
                    Admin={admin}
                    HandleChange={HandleChange}
                    data={Function.studentData}
                    expand={expand}
                    changes={changes}
                    title="Admins "
                  />
                </RequireAuth>
              }
            />

            <Route
              path="/AllAdmin"
              element={
                <RequireAuth loginPath="/login">
                  {auth.userType != "Admin" ? (
                    <Admin
                      key={1}
                      off="DA"
                      isDarkMode={auth.isDarkMode}
                      NOTIFY={notify}
                      PROFILE={profile}
                      compusData={data}
                      selectedValue={selectedValue}
                      Admin={auth?.Totaladmin}
                      HandleChange={HandleChange}
                      data={Function.studentData}
                      expand={expand}
                      changes={changes}
                      title="Admins "
                    />
                  ) : (
                    ""
                  )}
                </RequireAuth>
              }
            />
            <Route
              path="/Demo/:campusID"
              element={
                <RequireAuth loginPath="/login">
                  <CampusDemo></CampusDemo>
                </RequireAuth>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        ) : (
          <>
            <Route
              path="/login"
              element={<Login sendNoti={setNotify} data={FackData.auth} />}
            />
            <Route path="/signup" element={<SignUp data={FackData.auth} />} />
            <Route
              path="/forgot"
              element={<ForgetPassword data={FackData.auth} />}
            />
            <Route
              path="/authnotif"
              element={<EmailVerify data={FackData.auth} />}
            />
            <Route path="/Dnotif" element={<Declen data={FackData.auth} />} />
            <Route path="/Demo/:campusID" element={<CampusDemo></CampusDemo>} />
            <Route
              path="/resetForm/:invKey"
              element={<RestPasswordform data={FackData.auth} />}
            />
            <Route path="/esist" element={<Esist />} />
            <Route
              path="/invsignup/:invKey"
              element={<InviteSignUp data={FackData.auth} />}
            />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
