import { Button, Modal } from 'antd';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {  message } from 'antd';
import { useAuth } from '../Function/AuthProvider';
function ProfileModal(props) {
  const [loading, setLoading] = useState(true);
  const auth=useAuth();
  return (
    <>
    {auth.isDarkMode?
      <Modal
      title={props.title}
      centered
      open={props.open}
      loading={loading}
      onOk={props.Action}
      onCancel={props.cancel}
      width={props.width}
      okText={props.Button}
      okType='warning'
      bodyStyle={{
        background:'#000',
        color:'#fff'
    }}
  
    className='modal-content ant-modal-body'
    >
      {props.children}
    </Modal>
      :
    <Modal
    title={props.title}
    centered
    open={props.open}
    loading={loading}
    onOk={props.Action}
    onCancel={props.cancel}
    width={props.width}
    okText={props.Button}
    okType='warning'
    className='dark:bg-black  dark:text-white'
  
  >
    {props.children}
  </Modal>
    }
    </>
  )
}

export default ProfileModal