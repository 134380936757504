import React, { useEffect } from 'react'
import homeIcon from '../Assets/homeIcon.png'
import { Link,useLocation,useNavigate } from "react-router-dom";
import { MdDashboardCustomize, MdOutlineContentPaste } from "react-icons/md";
import { FaUserGraduate } from "react-icons/fa";
import { RiAdminFill } from "react-icons/ri";
import { IoLogOutOutline } from "react-icons/io5";
import sideImg from '../Assets/sideNave.png'
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SignOut from './SignOut';
import { useAuth } from '../Function/AuthProvider';
import { useState } from 'react';
function SideNave(props) {
 const auth=useAuth();
 const location =useLocation();
 const {pathname}=location;
const [navExp,setNavExp]=useState({ 
  dashi:1,
  dashi:false,
student:2,
student:false,
admin:3,
admin:false,
});
function twoFunction(nav,dash,boolen){
  auth.navi(nav)
  setNavExp({
    dashi:dash,
    dashi:boolen
  })
}
  return (
    // calling expand end colaps nav function add into first div onClick={()=>auth.SetVisible(false)}
    <div onClick={()=>auth.SetVisible(false)} className='w-full ' style={{
        backgroundImage:`url(${sideImg})`,
        backgroundRepeat:'no-repeat',
        backgroundSize:'cover'
    }} >
        <div  className='  '>
<div className='mt-[-18px]'>
  {/* add into div this function to colaps nav onClick={props.onclick} */}
<div   className='w-full flex justify-end h-[60px] items-center'>
{/* <svg className=' mt-[20px]' width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M11 26H29V24H11V26ZM11 21H29V19H11V21ZM11 14V16H29V14H11Z" fill="white"/>
</svg> */}
</div>
<div className={`${props.expand===false?'hidden':'text-center '}`}>
    <h1 className='nameSideBar hidden md:block'>myfellow.et </h1>
   
</div>
</div>
</div>
        <div className=' h-vh flex flex-col  justify-between'>


<div className=' mt-[140px] sideItem'>
    <ul   className='flex flex-col items-start justify-start'>
      <li className={ ` w-full  ${navExp.dashi===true?'  text-white font-bold ':' text-white   '}`} >
   <div  onClick={()=>setNavExp({
        dashi:1,
        dashi:!navExp.dashi
      })} className={`  cursor-pointer ${props.expand|| 'justify-between '} py-3  w-full  flex  justify-between  ${navExp.dashi===true?'  font-bold ':' text-white  '} `}> 
      <div  className={`${props.expand===false? ' flex items-center ml-5  gap-4':'pl-10 flex items-center  gap-4'}`}>
      <MdDashboardCustomize className=''  size={props.expand!==true?20:20}/>
     {/* {!props.expand&& 'Dashboard'}   */}
     Dashboard
      </div>
      <p className='mr-5' >{navExp.dashi===true?
      <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.94 0.939992C7.22125 0.659092 7.6025 0.501312 8 0.501312C8.3975 0.501312 8.77875 0.659092 9.06 0.939992L14.718 6.59599C14.8573 6.73532 14.9677 6.90072 15.0431 7.08275C15.1184 7.26477 15.1572 7.45985 15.1571 7.65685C15.1571 7.85385 15.1182 8.04891 15.0428 8.23089C14.9674 8.41288 14.8568 8.57823 14.7175 8.71749C14.5782 8.85676 14.4128 8.96722 14.2307 9.04256C14.0487 9.11791 13.8536 9.15667 13.6566 9.15662C13.4596 9.15657 13.2646 9.11772 13.0826 9.04229C12.9006 8.96686 12.7353 8.85633 12.596 8.71699L8 4.12099L3.404 8.71699C3.2657 8.86032 3.10024 8.97467 2.91727 9.05337C2.7343 9.13207 2.53749 9.17354 2.33832 9.17536C2.13915 9.17719 1.94162 9.13933 1.75724 9.06399C1.57286 8.98866 1.40533 8.87736 1.26443 8.73659C1.12352 8.59581 1.01206 8.42839 0.936554 8.24408C0.861046 8.05977 0.822999 7.86227 0.824636 7.6631C0.826272 7.46393 0.867558 7.26709 0.946084 7.08404C1.02461 6.901 1.1388 6.73543 1.282 6.59699L6.939 0.938993L6.94 0.939992Z" fill="white"/>
      </svg>:
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1106_1479)">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.707 15.707C12.5194 15.8945 12.2651 15.9998 12 15.9998C11.7348 15.9998 11.4805 15.8945 11.293 15.707L5.63598 10.05C5.54047 9.95776 5.46428 9.84742 5.41188 9.72541C5.35947 9.60341 5.33188 9.47219 5.33073 9.33941C5.32957 9.20663 5.35487 9.07495 5.40516 8.95205C5.45544 8.82916 5.52969 8.7175 5.62358 8.62361C5.71747 8.52972 5.82913 8.45547 5.95202 8.40519C6.07492 8.3549 6.2066 8.3296 6.33938 8.33076C6.47216 8.33191 6.60338 8.3595 6.72538 8.41191C6.84739 8.46431 6.95773 8.5405 7.04998 8.63601L12 13.586L16.95 8.63601C17.1386 8.45385 17.3912 8.35305 17.6534 8.35533C17.9156 8.35761 18.1664 8.46278 18.3518 8.64819C18.5372 8.8336 18.6424 9.08441 18.6447 9.34661C18.6469 9.6088 18.5461 9.86141 18.364 10.05L12.707 15.707Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_1106_1479">
      <rect width="24" height="24" fill="white"/>
      </clipPath>
      </defs>
      </svg>
      
      }</p>
         </div>
        <div className={`  flex flex-col  ${navExp.dashi===true?'break ':'hidden'} `}>

   
      {auth?.userType!=='Admin'?
      <Link onClick={()=>auth.navi('Dashboard')} className='flex items-center gap-4 ' to='/SuperDashboard'>

        <div className={`${props.expand|| 'justify-center'}   w-full  flex  ${pathname==='/SuperDashboard'?' bg-white text-[#AF20F2]  font-bold h-[40px]':' text-white focus:bg-white  h-[40px]'} `}>
      
     <div  className={`text-[12px] text-ellipsis overflow-hidden ${props.expand===false? ' flex items-center   gap-4  ':' text-[10px] pl-10 flex items-center  gap-4'}`}>
     {/* <MdDashboardCustomize className=''  size={props.expand!=true?20:20}/> */}
    {/* {!props.expand&& ' Super Dashboard'}   */}
  <p>Super Dashboard</p>  
     </div>
        </div>
      </Link>
       :""}
 <Link onClick={()=>auth.navi('Dashboard')} className='flex items-center gap-4 ' to='/'>
        <div className={`${props.expand|| 'justify-center'}  w-full  flex  ${pathname==='/'?' bg-white  text-[#AF20F2]  font-bold h-[40px]':' text-white focus:bg-white  h-[40px]'} `}>
      
     <div  className={` text-ellipsis overflow-hidden text-[12px] ${props.expand===false? ' flex items-center   gap-4 ':' text-[10px] pl-10 flex items-center  gap-4'}`}>
     {/* <MdDashboardCustomize className=''  size={props.expand!=true?20:20}/> */}
    {/* {!props.expand&& 'Campus Dashboard'}   */}
  <p> Campus Dashboard</p> 
     </div>
     {/* <p className='mr-5 flex justify-center items-center ' >{navExp.dashi===true?
      <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.94 0.939992C7.22125 0.659092 7.6025 0.501312 8 0.501312C8.3975 0.501312 8.77875 0.659092 9.06 0.939992L14.718 6.59599C14.8573 6.73532 14.9677 6.90072 15.0431 7.08275C15.1184 7.26477 15.1572 7.45985 15.1571 7.65685C15.1571 7.85385 15.1182 8.04891 15.0428 8.23089C14.9674 8.41288 14.8568 8.57823 14.7175 8.71749C14.5782 8.85676 14.4128 8.96722 14.2307 9.04256C14.0487 9.11791 13.8536 9.15667 13.6566 9.15662C13.4596 9.15657 13.2646 9.11772 13.0826 9.04229C12.9006 8.96686 12.7353 8.85633 12.596 8.71699L8 4.12099L3.404 8.71699C3.2657 8.86032 3.10024 8.97467 2.91727 9.05337C2.7343 9.13207 2.53749 9.17354 2.33832 9.17536C2.13915 9.17719 1.94162 9.13933 1.75724 9.06399C1.57286 8.98866 1.40533 8.87736 1.26443 8.73659C1.12352 8.59581 1.01206 8.42839 0.936554 8.24408C0.861046 8.05977 0.822999 7.86227 0.824636 7.6631C0.826272 7.46393 0.867558 7.26709 0.946084 7.08404C1.02461 6.901 1.1388 6.73543 1.282 6.59699L6.939 0.938993L6.94 0.939992Z" fill="white"/>
      </svg>:
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1106_1479)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.707 15.707C12.5194 15.8945 12.2651 15.9998 12 15.9998C11.7348 15.9998 11.4805 15.8945 11.293 15.707L5.63598 10.05C5.54047 9.95776 5.46428 9.84742 5.41188 9.72541C5.35947 9.60341 5.33188 9.47219 5.33073 9.33941C5.32957 9.20663 5.35487 9.07495 5.40516 8.95205C5.45544 8.82916 5.52969 8.7175 5.62358 8.62361C5.71747 8.52972 5.82913 8.45547 5.95202 8.40519C6.07492 8.3549 6.2066 8.3296 6.33938 8.33076C6.47216 8.33191 6.60338 8.3595 6.72538 8.41191C6.84739 8.46431 6.95773 8.5405 7.04998 8.63601L12 13.586L16.95 8.63601C17.1386 8.45385 17.3912 8.35305 17.6534 8.35533C17.9156 8.35761 18.1664 8.46278 18.3518 8.64819C18.5372 8.8336 18.6424 9.08441 18.6447 9.34661C18.6469 9.6088 18.5461 9.86141 18.364 10.05L12.707 15.707Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_1106_1479">
      <rect width="24" height="24" fill="white"/>
      </clipPath>
      </defs>
      </svg>     
      }</p> */}
        </div>
      </Link>
      {pathname==='/'&&
      <div className=''>
{props?.compusData?.Active_Campuses?.map((data,index)=>( 
   <div onClick={()=>props.HandleChange(data.Welcome_Section_Contents.campusID)} key={index}  className={`flex items-center gap-4  cursor-pointer `} >

   <div className={`${props.expand|| 'justify-center'}   w-full  flex  ${auth.selectCAMPUS===data.Welcome_Section_Contents.campusID?'bg-[#ffffffd5] text-[#AF20F2]  font-bold h-[40px]':' text-white focus:bg-white  h-[40px]'} `}>
 
<div   className={`text-[12px] text-ellipsis overflow-hidden ${props.expand===false? ' flex items-center   gap-4  ':' text-[10px] pl-10 flex items-center  gap-4'}`}>

<p className={`${props.expand?'black ':'lg:hidden'} `}>{data.Welcome_Section_Contents.fellowshipName}</p>
</div>
   </div>
 </div>
))}
      </div>
}

        </div>

      </li>
        
         <Link  onClick={()=>auth.navi('Content')} className='flex items-center w-full gap-4' to='/content'>  
         <li   className={`${props.expand|| 'justify-start'}  w-full  flex  ${pathname==='/content'?' bg-white text-[#AF20F2]  font-bold h-[50px]': pathname==='/newPage'?' bg-white text-[#AF20F2]  font-bold h-[40px]':' text-white focus:bg-white  h-[40px]'}`}>
         <div className={`${props.expand===false? ' flex items-center ml-5 gap-4':'pl-10 flex items-center  gap-4'}`}>
         <MdOutlineContentPaste    size={props.expand!==true?20:20}/>
         {/* {!props.expand&& ' Content'} */}
         Content
        </div>
         </li>
         </Link>
         {auth?.userType!='Admin'?
        <Link  onClick={()=>auth.navi('Campus')} className='flex items-center w-full gap-4' to='/campus'>  
        <li   className={`${props.expand|| 'justify-start'}  w-full  flex  ${pathname==='/campus'?' bg-white text-[#AF20F2]  font-bold h-[50px]':' text-white focus:bg-white  h-[40px]'}`}>
        <div className={`${props.expand===false? ' flex items-center ml-5 gap-4':'pl-10 flex items-center  gap-4'}`}>
        <MdOutlineContentPaste    size={props.expand!=true?20:20}/>
        {/* {!props.expand&& 'All Campus'} */}
        All Campus
       </div>
        </li>
        </Link> :''
       
}
<li className={` w-full pt-3 ${navExp.student===true?'  text-white font-bold ':' text-white   '}`} >
   <div onClick={()=>setNavExp({
        student:2,
        student:!navExp.student
      })} className={` ${props.expand|| 'justify-between '}  w-full  flex  justify-between  ${navExp.student===true?'  font-bold ':' text-white  '} `}>
      
      <div  className={`cursor-pointer ${props.expand===false? ' flex items-center ml-5  gap-4':'pl-10 flex items-center  gap-4'}`}>
      <FaUserGraduate  size={props.expand!==true?20:20}/>
     {/* {!props.expand&& 'Student'}   */}
     Student
      </div>
      <p className='mr-5' >{navExp.student===true?<svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.94 0.939992C7.22125 0.659092 7.6025 0.501312 8 0.501312C8.3975 0.501312 8.77875 0.659092 9.06 0.939992L14.718 6.59599C14.8573 6.73532 14.9677 6.90072 15.0431 7.08275C15.1184 7.26477 15.1572 7.45985 15.1571 7.65685C15.1571 7.85385 15.1182 8.04891 15.0428 8.23089C14.9674 8.41288 14.8568 8.57823 14.7175 8.71749C14.5782 8.85676 14.4128 8.96722 14.2307 9.04256C14.0487 9.11791 13.8536 9.15667 13.6566 9.15662C13.4596 9.15657 13.2646 9.11772 13.0826 9.04229C12.9006 8.96686 12.7353 8.85633 12.596 8.71699L8 4.12099L3.404 8.71699C3.2657 8.86032 3.10024 8.97467 2.91727 9.05337C2.7343 9.13207 2.53749 9.17354 2.33832 9.17536C2.13915 9.17719 1.94162 9.13933 1.75724 9.06399C1.57286 8.98866 1.40533 8.87736 1.26443 8.73659C1.12352 8.59581 1.01206 8.42839 0.936554 8.24408C0.861046 8.05977 0.822999 7.86227 0.824636 7.6631C0.826272 7.46393 0.867558 7.26709 0.946084 7.08404C1.02461 6.901 1.1388 6.73543 1.282 6.59699L6.939 0.938993L6.94 0.939992Z" fill="white"/>
      </svg>:
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1106_1479)">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.707 15.707C12.5194 15.8945 12.2651 15.9998 12 15.9998C11.7348 15.9998 11.4805 15.8945 11.293 15.707L5.63598 10.05C5.54047 9.95776 5.46428 9.84742 5.41188 9.72541C5.35947 9.60341 5.33188 9.47219 5.33073 9.33941C5.32957 9.20663 5.35487 9.07495 5.40516 8.95205C5.45544 8.82916 5.52969 8.7175 5.62358 8.62361C5.71747 8.52972 5.82913 8.45547 5.95202 8.40519C6.07492 8.3549 6.2066 8.3296 6.33938 8.33076C6.47216 8.33191 6.60338 8.3595 6.72538 8.41191C6.84739 8.46431 6.95773 8.5405 7.04998 8.63601L12 13.586L16.95 8.63601C17.1386 8.45385 17.3912 8.35305 17.6534 8.35533C17.9156 8.35761 18.1664 8.46278 18.3518 8.64819C18.5372 8.8336 18.6424 9.08441 18.6447 9.34661C18.6469 9.6088 18.5461 9.86141 18.364 10.05L12.707 15.707Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_1106_1479">
      <rect width="24" height="24" fill="white"/>
      </clipPath>
      </defs>
      </svg>
      }</p>
         </div>
         <div className={`py-3  flex flex-col ${navExp.student===true?'break ':'hidden'} `}>
        <Link onClick={()=>auth.navi('Student')} className='flex items-center gap-4 '  to='/student'> 
        <div className={`${props.expand|| 'justify-center'} w-full   flex  ${pathname==='/student'?' bg-white text-[#AF20F2]  font-bold h-[40px]':' text-white focus:bg-white  h-[40px]'}`}>
        <div className={` text-[12px] ${props.expand===false? ' flex items-center ml-5  gap-4':'pl-10 flex items-center  gap-4'}`}>
        {/* <FaUserGraduate  size={props.expand!=true?20:20}/> */}
       {/* {!props.expand&& 'Campus Students'} */}
       Campus Students
      </div>
        </div>
        </Link>
        { auth?.userType!=='Admin'?
       <Link onClick={()=>auth.navi('Student')} className='flex items-center gap-4 '  to='/OtherStudent'> 
       <div className={`${props.expand|| 'justify-center'} w-full   flex  ${pathname==='/OtherStudent'?' bg-white text-[#AF20F2]  font-bold h-[40px]':' text-white focus:bg-white  h-[40px]'}`}>
       <div className={  ` text-[12px] ${props.expand===false? ' flex items-center ml-5  gap-4':'pl-10 flex items-center  gap-4'}`}>
   
      Other Students
     </div>
       </div>
       </Link>
      :
      ''  
      }
      { auth?.userType!=='Admin'?
       <Link onClick={()=>auth.navi('Student')} className='flex items-center gap-4 '  to='/AllStudent'> 
       <div className={`${props.expand|| 'justify-center'} w-full   flex  ${pathname==='/AllStudent'?' bg-white text-[#AF20F2]  font-bold h-[40px]':' text-white focus:bg-white  h-[40px]'}`}>
       <div className={  ` text-[12px] ${props.expand===false? ' flex items-center ml-5  gap-4':'pl-10 flex items-center  gap-4'}`}>
       {/* <FaUserGraduate  size={props.expand!=true?20:20}/> */}
      {/* {!props.expand&& 'All Students'} */}
      All Students
     </div>
       </div>
       </Link>
      :
      ''  
      }
         </div>
</li>

<li className={` py-3 w-full ${navExp.admin===true?'  text-white font-bold ':' text-white   '}`} >
   <div onClick={()=>setNavExp({
        admin:3,
        admin:!navExp.admin
      })}  
      className={`  ${props.expand|| 'justify-between '}  w-full  flex  justify-between  ${navExp.admin===true?'  font-bold ':' text-white  '} `}>
      
      <div  className={`cursor-pointer ${props.expand===false? ' flex items-center ml-5  gap-4':'pl-10 flex items-center  gap-4'}`}>
      <RiAdminFill size={props.expand!=true?20:20}/>
     {/* {!props.expand&& 'Admin'}   */}
     Admin
      </div>
      <p className='mr-5' >{navExp.admin===true?<svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.94 0.939992C7.22125 0.659092 7.6025 0.501312 8 0.501312C8.3975 0.501312 8.77875 0.659092 9.06 0.939992L14.718 6.59599C14.8573 6.73532 14.9677 6.90072 15.0431 7.08275C15.1184 7.26477 15.1572 7.45985 15.1571 7.65685C15.1571 7.85385 15.1182 8.04891 15.0428 8.23089C14.9674 8.41288 14.8568 8.57823 14.7175 8.71749C14.5782 8.85676 14.4128 8.96722 14.2307 9.04256C14.0487 9.11791 13.8536 9.15667 13.6566 9.15662C13.4596 9.15657 13.2646 9.11772 13.0826 9.04229C12.9006 8.96686 12.7353 8.85633 12.596 8.71699L8 4.12099L3.404 8.71699C3.2657 8.86032 3.10024 8.97467 2.91727 9.05337C2.7343 9.13207 2.53749 9.17354 2.33832 9.17536C2.13915 9.17719 1.94162 9.13933 1.75724 9.06399C1.57286 8.98866 1.40533 8.87736 1.26443 8.73659C1.12352 8.59581 1.01206 8.42839 0.936554 8.24408C0.861046 8.05977 0.822999 7.86227 0.824636 7.6631C0.826272 7.46393 0.867558 7.26709 0.946084 7.08404C1.02461 6.901 1.1388 6.73543 1.282 6.59699L6.939 0.938993L6.94 0.939992Z" fill="white"/>
      </svg>:
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1106_1479)">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.707 15.707C12.5194 15.8945 12.2651 15.9998 12 15.9998C11.7348 15.9998 11.4805 15.8945 11.293 15.707L5.63598 10.05C5.54047 9.95776 5.46428 9.84742 5.41188 9.72541C5.35947 9.60341 5.33188 9.47219 5.33073 9.33941C5.32957 9.20663 5.35487 9.07495 5.40516 8.95205C5.45544 8.82916 5.52969 8.7175 5.62358 8.62361C5.71747 8.52972 5.82913 8.45547 5.95202 8.40519C6.07492 8.3549 6.2066 8.3296 6.33938 8.33076C6.47216 8.33191 6.60338 8.3595 6.72538 8.41191C6.84739 8.46431 6.95773 8.5405 7.04998 8.63601L12 13.586L16.95 8.63601C17.1386 8.45385 17.3912 8.35305 17.6534 8.35533C17.9156 8.35761 18.1664 8.46278 18.3518 8.64819C18.5372 8.8336 18.6424 9.08441 18.6447 9.34661C18.6469 9.6088 18.5461 9.86141 18.364 10.05L12.707 15.707Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_1106_1479">
      <rect width="24" height="24" fill="white"/>
      </clipPath>
      </defs>
      </svg>
      
      }</p>
         </div>
         <div className={`  flex flex-col ${navExp.admin===true?'break py-3':'hidden'} `}>
        <Link onClick={()=>auth.navi('Admin')} className='flex justify-center items-center  gap-4' to='/Admin'> 
        <div className={`${props.expand|| 'justify-center'} w-full     flex  ${pathname==='/Admin'?' bg-white text-[#AF20F2]  font-bold h-[40px]':' text-white focus:bg-white  h-[40px]'}`}>
        <div className={`text-[12px] ${props.expand===false? '  flex items-center ml-5 justify-center w-full  gap-4':'pl-10 flex items-center   gap-4'}`}>
        {/* <RiAdminFill size={props.expand!=true?20:20}/> */}
        {/* className={`${!props.expand===false?'hidden':''}`} */}
         <span >Campus Admins</span> 
      </div>
        </div>
        </Link>
        { auth?.userType!=='Admin'?
        <Link onClick={()=>auth.navi('Admin')} className='flex justify-center items-center gap-4 ' to='/AllAdmin'> 
        <div className={`${props.expand|| 'justify-center'} w-full flex  ${pathname==='/AllAdmin'?' bg-white text-[#AF20F2]  font-bold h-[40px]':' text-white focus:bg-white  h-[40px]'}`}>
        <div className={` text-[12px] ${props.expand===false? '  flex items-center ml-5 justify-center w-full  gap-4':'pl-10 flex items-center   gap-4'}`}>
        {/* <RiAdminFill size={props.expand!=true?20:20}/> */}
        {/* className={`${!props.expand===false?'hidden':''}`} */}
         <span >All Admins</span> 
      </div>
        </div>
        </Link>
        :''
    }
</div>
</li>
        
    </ul>
</div>
<SignOut expand={props.expand} className='flex cursor-pointer text-white flex-col items-center justify-center mb-[77px]' />
        </div>
    </div>
  )
}

export default SideNave