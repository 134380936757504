const CSSTailwind={
    Hero:{
        contenor:'lg:h-screen  md:h-[848px] h-[848px] relative ',
        Hero_Image_BackGround_Contener:'',
        Hero_Image_BackGround:'absolute lg:h-screen md:h-[848px] h-[848px] w-screen ',
        Nav:' relative mt-[46px]  w-full  px-[17px]     flex  justify-between items-start md:items-center lg:justify-between lg:items-center   md:mt-[-1vh] lg:mt-[4vh] ',
        Nav_Left:'lg:flex md:flex md:items-center md:gap-[41px]  lg:items-center lg:gap-[41px] hidden md:block lg:block   ',
        Nav_left_Smoll:'hidden md:block lg:block',
        Nav_Right:'ml-[10px] md:ml-0 lg:ml-0  flex   items-center gap-6',
        Nav_List:'',
        Nave_Item:'Inter md:text-[14px]  lg:text-[14px] leading-[24px] hidden md:block lg:block',
        Nav_Log:'flex justify-center items-center ',
        Short_Name: '  text-[16px] md:text-[20px] md:leading-[26px] lg:text-[44px] Inter Int-700 text-bold lg:leading-[56px] uppercase',
        Long_Name:'  hidden md:block lg:block Poppins Popp-400  text-[14px] leading-[21px] ',
        Logo:'bg-[#fff] w-[40px] h-[40px] md:h-[50px] md:w-[50px] lg:h-[80px] lg:w-[80px] rounded-full md:object-cover lg:object-cover object-cover',
        Logo_Div:'w-[40px] md:h-[50px] md:w-[50px] h-[40px] lg:w-[80px] lg:h-[80px] rounded-full flex justify-center items-center',
        Name_Div:'',
        Bottom_Section:' lg:w-[1260px]  lg:max-w-[1260px]  lg:h-auto    text-white ',
        BTop_Section:'',
        Campus_Title:'px-8 md:px-0 lg:px-0 text-center md:text-start lg:text-start  leading-[48px] text-[40px] md:text-[40px] lg:text-[48px]  md:leading-[50px]  tracking-[-2px]  uppercase Poppins Popp-700 ',
        Campus_Name:'text-center   md:text-start lg:text-start md:mt-[8px] mb-[48px] lg:mb-0 mt-[35px] text-[20px] md:text-[48px] lg:text-[48px]  md:leading-[48px] lg:leading-[48px] lg:tracking-[-2px] uppercase Poppins Popp-700',
        Moto:'text-center md:px-0 w-full px-12  md:text-start lg:text-start mb-[52px] mt-[10px] md:mt-0 lg:mt-6 lg:mb-[27px] mb-[36px] text-[20px] leading-[25px]  md:text-[18px]  lg:text-[20px]    lg:w-[533px] md:w-[533px] lg:leading-0 md:leading-[30px] Poppins Popp-400 ',
        HeroButton:'RegisterButton transition delay-150 duration-150 hover:delay-300 ease-in-out text-center mb-[120px] md:mb-0 lg:mb-0 Popp-500  md:text-center lg:text-center rounded-[30px] md:rounded-[80px] lg:rounded-[80px] bg-[#6B1768] md:p-4  lg:p-4 w-[200px] h-[46px] md:h-[58px] md:w-[257px] lg:h-[58px] lg:w-[257px] Poppins  ',
    },
    Fellowship:{
        Fellowship_contenor:'relative',
    second_contenor:' flex flex-col justify-center items-center',
    counter_Section:' overflow-hidden h-[10vh] md:h-[20vh] lg:h-[177px] flex ml-[0px]  md:py-[20px] md:px-[40px] w-[75vw] lg:max-w-[1443px] lg:w-[1143px] md:w-[85vw] text-white z-0 -mt-[5vh] md:-mt-[115px] lg:-mt-[96px]   md:gap-[22px] lg:gap-[44px] justify-center items-center shadow-[0px_4px_100px_rgba(0, 0, 0, 0.5)] rounded-[10px] lg:rounded-[40px] md:rounded-[40px]   bg-gradient-to-r from-[#6B1768] to-[#C31091]',
    counter_list:'flex  flex-col items-center ml-[13px] md:ml-0 last:mr-[13px] last:items-center',
    divider:'w-2 h-[100vh] ml-[17px] borderRight    md:bg-[#EEEEEE] lg:even:mr-[30px] lg:even:mr-[3vw]',
    Item_one:'Just-600 Just text-[24px] md:text-[64px] lg:text-[64px]  text-center Jost leading-[125%]',
    Item_two:' Popp-600  text-[10px] text-center md:text-[22px] lg:text-[22px] Poppins text-[#FFFFFF] opacity-[48%]',
    message_section:'mt-[105px] flex justify-center flex-col items-center',
    Header_Section:'items-center',
    title:'text-center text-[30px] Popp-600 md:text-[48px] lg:text-[64px] md:leading-[58px] lg:leading-[62px] track-[-2px] Poppins ',
    subTitle:'text-center  Popp-400 text-[12px] leading-[48px] text-[#6B1768] md:text-[18px] lg:text-[20px] lg:mt-[24px] Poppins',
    History_section:'lg:w-[1160px] mt-[15px] lg:mt-[54px] md:mt-[54px] mx-[5%] lg:mx-[10%] text-center text-justify mb-[46px]',
    history:'text-[12px] md:text-[18px] Popp-400 lg:text-[21px] lg:mb-[100px] lg:leading-[34px] leading-[20px] pb-32 px-[5vw] lg:px-0  Poppins lg:pb-32 md:pb-32'
    },
    message:{
        message_section:'relative flex flex-col items-center justify-center  ',
        message_Contenor:'absolute flex flex-col items-center justify-center  mx-[5vw] xl:max-w-[1260px] lg:mx-8  md:mx-8 mt-[20px] lg:mt-[50px] xl:mt-[64px] md:mt-[80px] lg:py-8 md:py-8 md:h-[90%] lg:h-[837px] ',
        left:'flex flex-col justify-center  last:items-right md:ml-[7%] lg:ml-[86px]',
        right:'flex flex-col items-center justify-center',
        Icon:'absolute top-[-30px] lg:top-[-82px] md:top-[-42px] ml-[60px] lg:ml-[101px] md:ml-[101px] w-[70px] h-[70px] lg:w-[152px] lg:h-[152px] md:w-[152px] md:h-[152px] rounded-full flex flex-col items-center justify-center bg-[#000] text-white',
        Icon_Name:'uppercase B-400 B612 text-[80px] lg:text-[150px] md:text-[150px] mt-[40px] lg:mt-[90px] md:mt-[80px]  leading-[182px] tracking-[-2px] text-center flex items-center',
        message_box:' md:w-full md:h-full lg:w-full lg:h-full grid  lg:flex lg:flex-row md:grid-cols-2 lg:gap-[70px] gap-2   messageblur   md:mx-[10%] md:rounded-[50px] lg:rounded-[50px] rounded-[20px]',
       message_pack:'mx-[8vw]   mt-16 md:mx-0 lg:mx-0 md:mt-[100px] lg:mt-[100px]',
        message_title:'Popp-700 text-[24px] blur-[0px] md:text-[45px] lg:text-[40px] md:text-[35px] Poppins  md:leading-[48px] lg:leading-[68px] lg:tracking-[-2px] text-[white]',
        message:' lg:w-[720px] text-[13px] md:text-[16px] leading-[20px] Popp-400 blur-[0px] lg:text-justify md:text-[18px] mt-5  md:mt-5 lg:text-[18px] md:leading-[24px] lg:leading-[34px]  Poppins text-white',
        name_section:' text-center mr-8 md:mr-0 lg:mr-0 py-5 lg:mt-[83px] md:py-[28px] -mt-[18px] ',
        name:'md:text-[16px] text-[14px] lg:text-[20px] Popp-600 tracking-[-0.2px] md:leading-[24px] leading-[28px] Poppins text-white',
        posetion:'md:text-[14px]  Popp-400 text-[12px] lg:text-[16px] text-[#9F9F9F] Poppins',
        Image:'w-[200px] h-[240px] lg:my-4 mb-8  md:w-[300px] md:h-[362px] lg:w-[335px] lg:h-[451px] object-cover lg:mt-[-40px]  lg:mr-[65px] lg:ml-0  rounded-[15px]',
        Image_BackGround:' object-cover h-[860px] md:h-[800px] lg:h-[1000px] h-[700px] w-screen   '
    },
    Weather:{
        weather_box:' flex flex-col justify-center items-center ',
        Weather_contener:' md:px-[3%] lg:px-[5%] xl:w-[95vw] lg:w-[95%] lg:h-[900px] lg:max-w-[1354px] overflow-hidden    text-white  messageblur  rounded-[20px] lg:rounded-[50px]  md:rounded-[50px]  ',
        Weather_section:' lg:mt-[100px]  flex items-center justify-center w-full lg:h-full  ',
        title:'Poppins Popp-600 md:text-[40px] text-[24px] mt-[66px]  lg:text-[64px] md:leading-[48px] lg:leading-[62px] lg:tracking-[-2px] md:tracking-[-2px] md:mt-[66px] font-[600] md:mt-5 lg:mt-[46px]',
        sub_title:'Poppins Popp-400 text-[12px] md:text-[18px] lg:text-[20px]  font-[400] text-center text-[#C570C2] mb-[27px]',
        weather_list_parent:"flex  p-[6vw] md:p-0 lg:p-0  gap-4 items-center lg:py-0 lg:my-8   md:block lg:block lg:flex md:flex",
        weather_list:'  xl:p-0 md:last:hidden lg:last:block lg:px-0 lg:py-0 mb-[64px] lg:mb-0 flex flex-col overflow-hidden items-center justify-center first:block md:block lg:block md:text-center lg:text-center hidden    md:w-[233px] md:h-[450px] lg:w-[281px] w-[310px]  md:first:h-[480px] lg:first:h-[670px] lg:h-[592px] xl:first:h-[670px] xl:first:w-[301.2px] xl:w-[303px] xl:h-[592px]  text-[#000] first:text-white first:weatherblur weatherBluother  rounded-[10px] ',
        weather_Item_data:'relative flex flex-col items-center ',
        weather_Item:'    rounded-[14px]   weatherItems md:w-[200px] md:h-[180px] h-[234px] w-[246px] xl:w-[247px] xl:h-[234px] lg:w-[247px] lg:h-[234px]',
        weather_item_border:'absolute md:mt-[-10px] lg:mt-0 md:top-0  itemsBorder  border-[1.5px]   rounded-[14px]  ',
        date:' weatherText2 leading-[16px] lg:mt-[40px]  text-[12px] Overpass Over-400 lg:text-[12px] lg:leading-[60px]',
        icon:'mt-[28px] md:mt-[28px] lg:mt-[20px]  lg:mr-8  ',
        info_section:'relative  flex flex-col items-center justify-center  lg:mt-[4%]',
        tem:'text-[69px]  leading-[89px] md:mt-[10px] temShadow lg:mt-[28px] md:mb-0  Overpass Over-400 md:text-[60px] lg:text-[70px] lg:leading-[89px] md:leading-[60px]   ',
        condtion:'  md:mt-1  Overpass Over-700 condtionShadow text-[16px] ',
        detaile_section:'mt-[19px]  flex items-center justify-start md:gap-4 gap-2 lg:gap-2 md:mt-1',
        detaile:'flex flex-col md:gap-3 lg:gap-2 lg:mt-[16px]',
        wind_icon:'',
        hum_icon:'',
        divider:'',
        km_houre:'weatherOthertext Overpass Over-400',
        persent:'weatherOthertext ',
        day:'Poppins text-[32px] mb-8 lg:mb-0 md:mb-0 leading-[48px] text-center md:text-[20px] lg:text-[32px] Popp-700 lg:leading-[48px] text-[#FFF] mt-[51px] lg:mt-[51px] md:mt-[25px] ',
        Image_BackGround:'absolute md:h-[1054px] xl:h-[1054px] lg:h-[1054px] h-[855px]  lg:w-[100vw] lg:left-0 w-full object-fill ',
        dashi_contenor:'relative   overflow-hidden md:mb-4 md:mt-[-1px] lg:mt-[-1px] xl:mb-0  flex flex-col items-center justify-center',
        dashi_Image:'lg:mt-0  lg:mb-[39.7px] object-fill lg:h-[266px] ',
        dashi_context:'absolute md:top-[40px]  lg:top-[40px] odd:mt-[19px] flex flex-col justify-center items-center ',
       
    },
    }
    export default {CSSTailwind};