import React from 'react'

function Title(props) {
    const h1={
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "700",
       
        lineHeight: "60px",
        /* identical to box height, or 59% */
       
        letterSpacing: '-0.02em',
        color: " #000000",   
    }
  return (
  <h1 style={h1} className={`${props.textPostion} md:text-[40px]`}>
{props.Title}
  </h1>
  )
}

export default Title