import React from 'react'
import { useSignOut } from 'react-auth-kit'
import { IoLogOutOutline } from 'react-icons/io5'
import { toast } from 'react-toastify';
import Cookies from 'js-cookie'
import axios from 'axios';
import { BASE_URL } from '../Function/Base';

import { Link,useNavigate } from "react-router-dom";
import { useAuth } from '../Function/AuthProvider';
function SignOut(props) {
  const auth = useAuth();
 
 const navigate= useNavigate()
  const url=`${BASE_URL}/api/logout`
 // axios.defaults.headers.common['Authorization'] = `Bearer ${null}`;
  
 const post=async()=>{
    try {
      const response = await axios.post(url);
     
        
      if(response?.data.status===200){   
     
       auth.logout();
navigate('/')
            }
    } catch (error) {
    // toast(error);
  
    }
  }
 

  return (
    <div className={props?.className} >
 
  
    <div>
<IoLogOutOutline  onClick={ post} size={25}/>
  
    </div>
    <button className='Hovers font-bold transition duration-150 ease-in-out hidden md:block' onClick={ post} >{props.expand&& 'Logout'} </button>
</div>
  )
}

export default SignOut