import React from 'react'
import P from './P';

function ProCard(props) {
    const className=`' rounded-[15px] md:rounded-[30px] h-[52px] md:h-[182px] flex  text-white ${props.className}  m-2 '`;
  return (
    <div className={className} >
<div className='items-start bg-Cwhite rounded-l-[15px] md:rounded-l-[30px] text-center  md:h-[182px] w-[48px] md:w-[174px]'>
<P className=' text-[20px] md:text-[64px] ' title={props.index}/>
</div>
<div className='flex-auto  md:ml-[142px] ml-4 mt-2 md:mt-[35px] md:mb-[20px]'>



    <div className=' flex text-[10px] md:text-[20px] gap-4 md:gap-[81px]' >
    <P className='self-center text-white ' title={props.name}/>
    <P className='self-center text-white' title={props.day}/>
    <P className='self-center text-white' title={props.time}/>
    <P className='self-center text-white' title={props.address}/>
    </div>
    </div>
    </div>
  )
}

export default ProCard