import React, { useEffect, useRef, useState } from "react";
import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, FadeOut, Move, MoveIn, MoveOut, Sticky, StickyIn, StickyOut, Zoom, ZoomIn, ZoomOut } from "react-scroll-motion";
import Css from "../Component/Css";
import { useInView,motion } from "framer-motion";
export default function Fellowship(props) {
  const ref =useRef(null);
  const isView =useInView(ref,{margin:'-20% 0px -20% 0px'});
  const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
  const FadeUp = batch(Fade(), Move(), Sticky());
  const [service,setService]=useState(0);
  const [Team,setTeamCount]=useState(0);
  const [members,setMembers]=useState(0);
useEffect(() => {
  const interval = setInterval(() => {
    if (Team < parseInt(props.props.teams)) {
      setTeamCount((Team) => Team + 1);
    } else {
      clearInterval(interval);
    }
  }, 180);

  return () => clearInterval(interval);
}, [Team]);

useEffect(() => {
  const interval = setInterval(() => {
    if (service < parseInt(props.props.services)) {
      setService((service) => service + 1);
    } else {
      clearInterval(interval);
    }
  }, 180);

  return () => clearInterval(interval);
}, [service]);
useEffect(() => {
  const interval = setInterval(() => {
    if (members < parseInt(props.props.members)) {
      setMembers((members) => members + 1);
    } else {
      clearInterval(interval);
    }
  }, 3);

  return () => clearInterval(interval);
}, [members]);
  return (
    <div ref={ref} className={`${isView?'bg-white':'bg-white'} ${Css.CSSTailwind.Fellowship.Fellowship_contenor}`}>
    <div className={Css.CSSTailwind.Fellowship.second_contenor}>
        {/* counter Section */}
        <div style={{
          boxShadow:'0px 4px 100px rgba(0, 0, 0, 0.5)'
        }} className={Css.CSSTailwind.Fellowship.counter_Section}>
            {/* items- list */}
            <div className={Css.CSSTailwind.Fellowship.counter_list}>
<p className={Css.CSSTailwind.Fellowship.Item_one}>{service>10?service  :service}</p>
<p className={Css.CSSTailwind.Fellowship.Item_two}>{props.props?.servicesCaption}</p>
                </div>
                <p className={Css.CSSTailwind.Fellowship.divider}></p>
                <div className={Css.CSSTailwind.Fellowship.counter_list}>
<p className={Css.CSSTailwind.Fellowship.Item_one}>{Team>10?Team :Team}</p>
<p className={Css.CSSTailwind.Fellowship.Item_two}>{props.props?.teamsCaption}</p>
                </div>
                <p className={Css.CSSTailwind.Fellowship.divider}></p>
                <div className={Css.CSSTailwind.Fellowship.counter_list}>
<p className={Css.CSSTailwind.Fellowship.Item_one}>{members>=1000? members/1000 +'K' :members}</p>
<p className={Css.CSSTailwind.Fellowship.Item_two}>{props.props.membersCaption}</p>
                </div>
            </div>
            {/* Message Section */}
            <div className={Css.CSSTailwind.Fellowship.message_section}>
{/* header section */}
<div 
className={Css.CSSTailwind.Fellowship.Header_Section}>
    <p data-aos="fade-up" className={`${isView?'translation-all duration-500 scale-500':'scale-0'} ${Css.CSSTailwind.Fellowship.title}`}>  {props?.props?.title}</p>
    <p data-aos="fade-up" className={`${isView?'translation-all duration-500 scale-500':'scale-0'} ${Css.CSSTailwind.Fellowship.subTitle}`}>{props?.props?.subtitle}</p>
    </div>
    {/* History section */}
    <div className={Css.CSSTailwind.Fellowship.History_section} >
        <p data-aos="fade-up" className={`${isView?'translation-all duration-500 text-black scale-500':'text-gray-500 scale-0'} ${Css.CSSTailwind.Fellowship.history}`}>
          {props.props?.description}
           </p>
        </div>
                </div>
        </div>
    </div>
  );
}