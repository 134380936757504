import React from 'react'

function RowSelect(props) {
    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        props.onChange(selectedValue);
      };
    
      return (
        <select className="w-[86px] h-[36px] border rounded-md border-grey-100 dark:bg-black dark:text-white" name="row" id="row" onChange={handleSelectChange}>
          <option value="5">5 row</option>
          <option value="10">10 row</option>
          <option value="25">25 row</option>
          <option value="50">50 row</option>
          <option value="75">75 row</option>
          <option value="100">100 row</option>
        </select>
      );
}

export default RowSelect