import React from 'react'

function Table(props) {
  return (
    <table className="w-full dark:bg-black dark:text-white mb-5 ">
        <thead>

    <tr className="border border-solide h-[40px] hover:text-black hover:bg-gray-100 dark:hover:text-black ">
      
        {props.tableshow?.fullName&&
        <td  className=''> 
   <div className="flex gap-2 p-2 items-center w-full">
    <p className='text-[10px] md:text-[14px]'>Full Name</p>
    <svg  width="8" height="10" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.292787 5.70679C0.105316 5.51926 0 5.26495 0 4.99979C0 4.73462 0.105316 4.48031 0.292787 4.29279L4.29279 0.292786C4.48031 0.105315 4.73462 0 4.99979 0C5.26495 0 5.51926 0.105315 5.70679 0.292786L9.70679 4.29279C9.88894 4.48139 9.98974 4.73399 9.98746 4.99619C9.98518 5.25838 9.88001 5.5092 9.6946 5.6946C9.5092 5.88001 9.25838 5.98518 8.99619 5.98746C8.73399 5.98974 8.48139 5.88894 8.29279 5.70679L5.99979 3.41379V14.9998C5.99979 15.265 5.89443 15.5194 5.70689 15.7069C5.51936 15.8944 5.265 15.9998 4.99979 15.9998C4.73457 15.9998 4.48022 15.8944 4.29268 15.7069C4.10514 15.5194 3.99979 15.265 3.99979 14.9998V3.41379L1.70679 5.70679C1.51926 5.89426 1.26495 5.99957 0.999786 5.99957C0.734622 5.99957 0.480314 5.89426 0.292787 5.70679V5.70679Z" fill="#DBDBDB"/>
</svg>
<svg width="8" height="10" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.71929 10.293C9.90676 10.4805 10.0121 10.7348 10.0121 11C10.0121 11.2652 9.90676 11.5195 9.71929 11.707L5.71929 15.707C5.53176 15.8945 5.27745 15.9998 5.01229 15.9998C4.74712 15.9998 4.49282 15.8945 4.30529 15.707L0.305288 11.707C0.209778 11.6148 0.133596 11.5044 0.0811869 11.3824C0.0287779 11.2604 0.00119157 11.1292 3.77571e-05 10.9964C-0.00111606 10.8636 0.0241854 10.7319 0.0744663 10.609C0.124747 10.4861 0.199 10.3745 0.292893 10.2806C0.386786 10.1867 0.498438 10.1125 0.621334 10.0622C0.74423 10.0119 0.87591 9.9866 1.00869 9.98775C1.14147 9.9889 1.27269 10.0165 1.39469 10.0689C1.5167 10.1213 1.62704 10.1975 1.71929 10.293L4.01229 12.586V1C4.01229 0.734784 4.11765 0.48043 4.30518 0.292893C4.49272 0.105357 4.74707 0 5.01229 0C5.2775 0 5.53186 0.105357 5.71939 0.292893C5.90693 0.48043 6.01229 0.734784 6.01229 1V12.586L8.30529 10.293C8.49282 10.1055 8.74712 10.0002 9.01229 10.0002C9.27745 10.0002 9.53176 10.1055 9.71929 10.293Z" fill="#8E98A8"/>
</svg>
   </div>
        </td>
}
{props.tableshow?.sex&&
        <td  className=''> 
   <div className="flex gap-2 p-2 items-center w-full">
    <p className=' text-[10px] md:text-[14px]'>Sex</p>
    <svg  width="8" height="10" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.292787 5.70679C0.105316 5.51926 0 5.26495 0 4.99979C0 4.73462 0.105316 4.48031 0.292787 4.29279L4.29279 0.292786C4.48031 0.105315 4.73462 0 4.99979 0C5.26495 0 5.51926 0.105315 5.70679 0.292786L9.70679 4.29279C9.88894 4.48139 9.98974 4.73399 9.98746 4.99619C9.98518 5.25838 9.88001 5.5092 9.6946 5.6946C9.5092 5.88001 9.25838 5.98518 8.99619 5.98746C8.73399 5.98974 8.48139 5.88894 8.29279 5.70679L5.99979 3.41379V14.9998C5.99979 15.265 5.89443 15.5194 5.70689 15.7069C5.51936 15.8944 5.265 15.9998 4.99979 15.9998C4.73457 15.9998 4.48022 15.8944 4.29268 15.7069C4.10514 15.5194 3.99979 15.265 3.99979 14.9998V3.41379L1.70679 5.70679C1.51926 5.89426 1.26495 5.99957 0.999786 5.99957C0.734622 5.99957 0.480314 5.89426 0.292787 5.70679V5.70679Z" fill="#DBDBDB"/>
</svg>
<svg width="8" height="10" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.71929 10.293C9.90676 10.4805 10.0121 10.7348 10.0121 11C10.0121 11.2652 9.90676 11.5195 9.71929 11.707L5.71929 15.707C5.53176 15.8945 5.27745 15.9998 5.01229 15.9998C4.74712 15.9998 4.49282 15.8945 4.30529 15.707L0.305288 11.707C0.209778 11.6148 0.133596 11.5044 0.0811869 11.3824C0.0287779 11.2604 0.00119157 11.1292 3.77571e-05 10.9964C-0.00111606 10.8636 0.0241854 10.7319 0.0744663 10.609C0.124747 10.4861 0.199 10.3745 0.292893 10.2806C0.386786 10.1867 0.498438 10.1125 0.621334 10.0622C0.74423 10.0119 0.87591 9.9866 1.00869 9.98775C1.14147 9.9889 1.27269 10.0165 1.39469 10.0689C1.5167 10.1213 1.62704 10.1975 1.71929 10.293L4.01229 12.586V1C4.01229 0.734784 4.11765 0.48043 4.30518 0.292893C4.49272 0.105357 4.74707 0 5.01229 0C5.2775 0 5.53186 0.105357 5.71939 0.292893C5.90693 0.48043 6.01229 0.734784 6.01229 1V12.586L8.30529 10.293C8.49282 10.1055 8.74712 10.0002 9.01229 10.0002C9.27745 10.0002 9.53176 10.1055 9.71929 10.293Z" fill="#8E98A8"/>
</svg>
   </div>
        </td>
}

{props.tableshow?.Phone&&
        <td  className=''> 
   <div className="flex gap-2 p-2 items-center w-full">
    <p className=' text-[10px] md:text-[14px]'>Phone Number</p>
    <svg  width="8" height="10" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.292787 5.70679C0.105316 5.51926 0 5.26495 0 4.99979C0 4.73462 0.105316 4.48031 0.292787 4.29279L4.29279 0.292786C4.48031 0.105315 4.73462 0 4.99979 0C5.26495 0 5.51926 0.105315 5.70679 0.292786L9.70679 4.29279C9.88894 4.48139 9.98974 4.73399 9.98746 4.99619C9.98518 5.25838 9.88001 5.5092 9.6946 5.6946C9.5092 5.88001 9.25838 5.98518 8.99619 5.98746C8.73399 5.98974 8.48139 5.88894 8.29279 5.70679L5.99979 3.41379V14.9998C5.99979 15.265 5.89443 15.5194 5.70689 15.7069C5.51936 15.8944 5.265 15.9998 4.99979 15.9998C4.73457 15.9998 4.48022 15.8944 4.29268 15.7069C4.10514 15.5194 3.99979 15.265 3.99979 14.9998V3.41379L1.70679 5.70679C1.51926 5.89426 1.26495 5.99957 0.999786 5.99957C0.734622 5.99957 0.480314 5.89426 0.292787 5.70679V5.70679Z" fill="#DBDBDB"/>
</svg>
<svg width="8" height="10" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.71929 10.293C9.90676 10.4805 10.0121 10.7348 10.0121 11C10.0121 11.2652 9.90676 11.5195 9.71929 11.707L5.71929 15.707C5.53176 15.8945 5.27745 15.9998 5.01229 15.9998C4.74712 15.9998 4.49282 15.8945 4.30529 15.707L0.305288 11.707C0.209778 11.6148 0.133596 11.5044 0.0811869 11.3824C0.0287779 11.2604 0.00119157 11.1292 3.77571e-05 10.9964C-0.00111606 10.8636 0.0241854 10.7319 0.0744663 10.609C0.124747 10.4861 0.199 10.3745 0.292893 10.2806C0.386786 10.1867 0.498438 10.1125 0.621334 10.0622C0.74423 10.0119 0.87591 9.9866 1.00869 9.98775C1.14147 9.9889 1.27269 10.0165 1.39469 10.0689C1.5167 10.1213 1.62704 10.1975 1.71929 10.293L4.01229 12.586V1C4.01229 0.734784 4.11765 0.48043 4.30518 0.292893C4.49272 0.105357 4.74707 0 5.01229 0C5.2775 0 5.53186 0.105357 5.71939 0.292893C5.90693 0.48043 6.01229 0.734784 6.01229 1V12.586L8.30529 10.293C8.49282 10.1055 8.74712 10.0002 9.01229 10.0002C9.27745 10.0002 9.53176 10.1055 9.71929 10.293Z" fill="#8E98A8"/>
</svg>
   </div>
        </td>
}  
{props.tableshow?.City&&
        <td  className=''> 
   <div className="flex gap-2 p-2 items-center w-full">
    <p className=' text-[10px] md:text-[14px]'>City</p>
    <svg  width="8" height="10" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.292787 5.70679C0.105316 5.51926 0 5.26495 0 4.99979C0 4.73462 0.105316 4.48031 0.292787 4.29279L4.29279 0.292786C4.48031 0.105315 4.73462 0 4.99979 0C5.26495 0 5.51926 0.105315 5.70679 0.292786L9.70679 4.29279C9.88894 4.48139 9.98974 4.73399 9.98746 4.99619C9.98518 5.25838 9.88001 5.5092 9.6946 5.6946C9.5092 5.88001 9.25838 5.98518 8.99619 5.98746C8.73399 5.98974 8.48139 5.88894 8.29279 5.70679L5.99979 3.41379V14.9998C5.99979 15.265 5.89443 15.5194 5.70689 15.7069C5.51936 15.8944 5.265 15.9998 4.99979 15.9998C4.73457 15.9998 4.48022 15.8944 4.29268 15.7069C4.10514 15.5194 3.99979 15.265 3.99979 14.9998V3.41379L1.70679 5.70679C1.51926 5.89426 1.26495 5.99957 0.999786 5.99957C0.734622 5.99957 0.480314 5.89426 0.292787 5.70679V5.70679Z" fill="#DBDBDB"/>
</svg>
<svg width="8" height="10" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.71929 10.293C9.90676 10.4805 10.0121 10.7348 10.0121 11C10.0121 11.2652 9.90676 11.5195 9.71929 11.707L5.71929 15.707C5.53176 15.8945 5.27745 15.9998 5.01229 15.9998C4.74712 15.9998 4.49282 15.8945 4.30529 15.707L0.305288 11.707C0.209778 11.6148 0.133596 11.5044 0.0811869 11.3824C0.0287779 11.2604 0.00119157 11.1292 3.77571e-05 10.9964C-0.00111606 10.8636 0.0241854 10.7319 0.0744663 10.609C0.124747 10.4861 0.199 10.3745 0.292893 10.2806C0.386786 10.1867 0.498438 10.1125 0.621334 10.0622C0.74423 10.0119 0.87591 9.9866 1.00869 9.98775C1.14147 9.9889 1.27269 10.0165 1.39469 10.0689C1.5167 10.1213 1.62704 10.1975 1.71929 10.293L4.01229 12.586V1C4.01229 0.734784 4.11765 0.48043 4.30518 0.292893C4.49272 0.105357 4.74707 0 5.01229 0C5.2775 0 5.53186 0.105357 5.71939 0.292893C5.90693 0.48043 6.01229 0.734784 6.01229 1V12.586L8.30529 10.293C8.49282 10.1055 8.74712 10.0002 9.01229 10.0002C9.27745 10.0002 9.53176 10.1055 9.71929 10.293Z" fill="#8E98A8"/>
</svg>
   </div>
        </td>
} 
{props.tableshow?.church&&
        <td  className=''> 
   <div className="flex gap-2 p-2 items-center w-full">
    <p className=' text-[10px] md:text-[14px]'>Church</p>
    <svg  width="8" height="10" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.292787 5.70679C0.105316 5.51926 0 5.26495 0 4.99979C0 4.73462 0.105316 4.48031 0.292787 4.29279L4.29279 0.292786C4.48031 0.105315 4.73462 0 4.99979 0C5.26495 0 5.51926 0.105315 5.70679 0.292786L9.70679 4.29279C9.88894 4.48139 9.98974 4.73399 9.98746 4.99619C9.98518 5.25838 9.88001 5.5092 9.6946 5.6946C9.5092 5.88001 9.25838 5.98518 8.99619 5.98746C8.73399 5.98974 8.48139 5.88894 8.29279 5.70679L5.99979 3.41379V14.9998C5.99979 15.265 5.89443 15.5194 5.70689 15.7069C5.51936 15.8944 5.265 15.9998 4.99979 15.9998C4.73457 15.9998 4.48022 15.8944 4.29268 15.7069C4.10514 15.5194 3.99979 15.265 3.99979 14.9998V3.41379L1.70679 5.70679C1.51926 5.89426 1.26495 5.99957 0.999786 5.99957C0.734622 5.99957 0.480314 5.89426 0.292787 5.70679V5.70679Z" fill="#DBDBDB"/>
</svg>
<svg width="8" height="10" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.71929 10.293C9.90676 10.4805 10.0121 10.7348 10.0121 11C10.0121 11.2652 9.90676 11.5195 9.71929 11.707L5.71929 15.707C5.53176 15.8945 5.27745 15.9998 5.01229 15.9998C4.74712 15.9998 4.49282 15.8945 4.30529 15.707L0.305288 11.707C0.209778 11.6148 0.133596 11.5044 0.0811869 11.3824C0.0287779 11.2604 0.00119157 11.1292 3.77571e-05 10.9964C-0.00111606 10.8636 0.0241854 10.7319 0.0744663 10.609C0.124747 10.4861 0.199 10.3745 0.292893 10.2806C0.386786 10.1867 0.498438 10.1125 0.621334 10.0622C0.74423 10.0119 0.87591 9.9866 1.00869 9.98775C1.14147 9.9889 1.27269 10.0165 1.39469 10.0689C1.5167 10.1213 1.62704 10.1975 1.71929 10.293L4.01229 12.586V1C4.01229 0.734784 4.11765 0.48043 4.30518 0.292893C4.49272 0.105357 4.74707 0 5.01229 0C5.2775 0 5.53186 0.105357 5.71939 0.292893C5.90693 0.48043 6.01229 0.734784 6.01229 1V12.586L8.30529 10.293C8.49282 10.1055 8.74712 10.0002 9.01229 10.0002C9.27745 10.0002 9.53176 10.1055 9.71929 10.293Z" fill="#8E98A8"/>
</svg>
   </div>
        </td>
} 
{props.tableshow?.language&&
        <td  className=''> 
   <div className="flex gap-2 p-2 items-center w-full">
    <p className=' text-[10px] md:text-[14px]'>Language</p>
    <svg  width="8" height="10" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.292787 5.70679C0.105316 5.51926 0 5.26495 0 4.99979C0 4.73462 0.105316 4.48031 0.292787 4.29279L4.29279 0.292786C4.48031 0.105315 4.73462 0 4.99979 0C5.26495 0 5.51926 0.105315 5.70679 0.292786L9.70679 4.29279C9.88894 4.48139 9.98974 4.73399 9.98746 4.99619C9.98518 5.25838 9.88001 5.5092 9.6946 5.6946C9.5092 5.88001 9.25838 5.98518 8.99619 5.98746C8.73399 5.98974 8.48139 5.88894 8.29279 5.70679L5.99979 3.41379V14.9998C5.99979 15.265 5.89443 15.5194 5.70689 15.7069C5.51936 15.8944 5.265 15.9998 4.99979 15.9998C4.73457 15.9998 4.48022 15.8944 4.29268 15.7069C4.10514 15.5194 3.99979 15.265 3.99979 14.9998V3.41379L1.70679 5.70679C1.51926 5.89426 1.26495 5.99957 0.999786 5.99957C0.734622 5.99957 0.480314 5.89426 0.292787 5.70679V5.70679Z" fill="#DBDBDB"/>
</svg>
<svg width="8" height="10" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.71929 10.293C9.90676 10.4805 10.0121 10.7348 10.0121 11C10.0121 11.2652 9.90676 11.5195 9.71929 11.707L5.71929 15.707C5.53176 15.8945 5.27745 15.9998 5.01229 15.9998C4.74712 15.9998 4.49282 15.8945 4.30529 15.707L0.305288 11.707C0.209778 11.6148 0.133596 11.5044 0.0811869 11.3824C0.0287779 11.2604 0.00119157 11.1292 3.77571e-05 10.9964C-0.00111606 10.8636 0.0241854 10.7319 0.0744663 10.609C0.124747 10.4861 0.199 10.3745 0.292893 10.2806C0.386786 10.1867 0.498438 10.1125 0.621334 10.0622C0.74423 10.0119 0.87591 9.9866 1.00869 9.98775C1.14147 9.9889 1.27269 10.0165 1.39469 10.0689C1.5167 10.1213 1.62704 10.1975 1.71929 10.293L4.01229 12.586V1C4.01229 0.734784 4.11765 0.48043 4.30518 0.292893C4.49272 0.105357 4.74707 0 5.01229 0C5.2775 0 5.53186 0.105357 5.71939 0.292893C5.90693 0.48043 6.01229 0.734784 6.01229 1V12.586L8.30529 10.293C8.49282 10.1055 8.74712 10.0002 9.01229 10.0002C9.27745 10.0002 9.53176 10.1055 9.71929 10.293Z" fill="#8E98A8"/>
</svg>
   </div>
        </td>
} 
{props.tableshow?.arrivalDate&&
        <td  className=''> 
   <div className="flex gap-2 p-2 items-center w-full">
    <p className=' text-[10px] md:text-[14px]'>Date</p>
    <svg  width="8" height="10" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.292787 5.70679C0.105316 5.51926 0 5.26495 0 4.99979C0 4.73462 0.105316 4.48031 0.292787 4.29279L4.29279 0.292786C4.48031 0.105315 4.73462 0 4.99979 0C5.26495 0 5.51926 0.105315 5.70679 0.292786L9.70679 4.29279C9.88894 4.48139 9.98974 4.73399 9.98746 4.99619C9.98518 5.25838 9.88001 5.5092 9.6946 5.6946C9.5092 5.88001 9.25838 5.98518 8.99619 5.98746C8.73399 5.98974 8.48139 5.88894 8.29279 5.70679L5.99979 3.41379V14.9998C5.99979 15.265 5.89443 15.5194 5.70689 15.7069C5.51936 15.8944 5.265 15.9998 4.99979 15.9998C4.73457 15.9998 4.48022 15.8944 4.29268 15.7069C4.10514 15.5194 3.99979 15.265 3.99979 14.9998V3.41379L1.70679 5.70679C1.51926 5.89426 1.26495 5.99957 0.999786 5.99957C0.734622 5.99957 0.480314 5.89426 0.292787 5.70679V5.70679Z" fill="#DBDBDB"/>
</svg>
<svg width="8" height="10" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.71929 10.293C9.90676 10.4805 10.0121 10.7348 10.0121 11C10.0121 11.2652 9.90676 11.5195 9.71929 11.707L5.71929 15.707C5.53176 15.8945 5.27745 15.9998 5.01229 15.9998C4.74712 15.9998 4.49282 15.8945 4.30529 15.707L0.305288 11.707C0.209778 11.6148 0.133596 11.5044 0.0811869 11.3824C0.0287779 11.2604 0.00119157 11.1292 3.77571e-05 10.9964C-0.00111606 10.8636 0.0241854 10.7319 0.0744663 10.609C0.124747 10.4861 0.199 10.3745 0.292893 10.2806C0.386786 10.1867 0.498438 10.1125 0.621334 10.0622C0.74423 10.0119 0.87591 9.9866 1.00869 9.98775C1.14147 9.9889 1.27269 10.0165 1.39469 10.0689C1.5167 10.1213 1.62704 10.1975 1.71929 10.293L4.01229 12.586V1C4.01229 0.734784 4.11765 0.48043 4.30518 0.292893C4.49272 0.105357 4.74707 0 5.01229 0C5.2775 0 5.53186 0.105357 5.71939 0.292893C5.90693 0.48043 6.01229 0.734784 6.01229 1V12.586L8.30529 10.293C8.49282 10.1055 8.74712 10.0002 9.01229 10.0002C9.27745 10.0002 9.53176 10.1055 9.71929 10.293Z" fill="#8E98A8"/>
</svg>
   </div>
        </td>
} 
{props.tableshow?.Host&&
        <td  className=''> 
   <div className="flex gap-2 p-2 items-center w-full">
    <p className=' text-[10px] md:text-[14px]'>Host</p>
    <svg  width="8" height="10" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.292787 5.70679C0.105316 5.51926 0 5.26495 0 4.99979C0 4.73462 0.105316 4.48031 0.292787 4.29279L4.29279 0.292786C4.48031 0.105315 4.73462 0 4.99979 0C5.26495 0 5.51926 0.105315 5.70679 0.292786L9.70679 4.29279C9.88894 4.48139 9.98974 4.73399 9.98746 4.99619C9.98518 5.25838 9.88001 5.5092 9.6946 5.6946C9.5092 5.88001 9.25838 5.98518 8.99619 5.98746C8.73399 5.98974 8.48139 5.88894 8.29279 5.70679L5.99979 3.41379V14.9998C5.99979 15.265 5.89443 15.5194 5.70689 15.7069C5.51936 15.8944 5.265 15.9998 4.99979 15.9998C4.73457 15.9998 4.48022 15.8944 4.29268 15.7069C4.10514 15.5194 3.99979 15.265 3.99979 14.9998V3.41379L1.70679 5.70679C1.51926 5.89426 1.26495 5.99957 0.999786 5.99957C0.734622 5.99957 0.480314 5.89426 0.292787 5.70679V5.70679Z" fill="#DBDBDB"/>
</svg>
<svg width="8" height="10" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M9.71929 10.293C9.90676 10.4805 10.0121 10.7348 10.0121 11C10.0121 11.2652 9.90676 11.5195 9.71929 11.707L5.71929 15.707C5.53176 15.8945 5.27745 15.9998 5.01229 15.9998C4.74712 15.9998 4.49282 15.8945 4.30529 15.707L0.305288 11.707C0.209778 11.6148 0.133596 11.5044 0.0811869 11.3824C0.0287779 11.2604 0.00119157 11.1292 3.77571e-05 10.9964C-0.00111606 10.8636 0.0241854 10.7319 0.0744663 10.609C0.124747 10.4861 0.199 10.3745 0.292893 10.2806C0.386786 10.1867 0.498438 10.1125 0.621334 10.0622C0.74423 10.0119 0.87591 9.9866 1.00869 9.98775C1.14147 9.9889 1.27269 10.0165 1.39469 10.0689C1.5167 10.1213 1.62704 10.1975 1.71929 10.293L4.01229 12.586V1C4.01229 0.734784 4.11765 0.48043 4.30518 0.292893C4.49272 0.105357 4.74707 0 5.01229 0C5.2775 0 5.53186 0.105357 5.71939 0.292893C5.90693 0.48043 6.01229 0.734784 6.01229 1V12.586L8.30529 10.293C8.49282 10.1055 8.74712 10.0002 9.01229 10.0002C9.27745 10.0002 9.53176 10.1055 9.71929 10.293Z" fill="#8E98A8"/>
</svg>
   </div>
        </td>
} 
      
        <td><svg onClick={()=>props.setTableShow({...props.tableshow,show:!props.tableshow.show})} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M13.9999 9C13.7347 9 13.4803 8.89464 13.2928 8.70711C13.1053 8.51957 12.9999 8.26522 12.9999 8C12.9999 7.73478 13.1053 7.48043 13.2928 7.29289C13.4803 7.10536 13.7347 7 13.9999 7H18.9999C19.2651 7 19.5195 7.10536 19.707 7.29289C19.8946 7.48043 19.9999 7.73478 19.9999 8V13C19.9999 13.2652 19.8946 13.5196 19.707 13.7071C19.5195 13.8946 19.2651 14 18.9999 14C18.7347 14 18.4803 13.8946 18.2928 13.7071C18.1053 13.5196 17.9999 13.2652 17.9999 13V10.414L13.7069 14.707C13.5194 14.8945 13.2651 14.9998 12.9999 14.9998C12.7348 14.9998 12.4804 14.8945 12.2929 14.707L9.99992 12.414L5.70692 16.707C5.51832 16.8892 5.26571 16.99 5.00352 16.9877C4.74132 16.9854 4.49051 16.8802 4.3051 16.6948C4.11969 16.5094 4.01452 16.2586 4.01224 15.9964C4.00997 15.7342 4.11076 15.4816 4.29292 15.293L9.29292 10.293C9.48045 10.1055 9.73475 10.0002 9.99992 10.0002C10.2651 10.0002 10.5194 10.1055 10.7069 10.293L12.9999 12.586L16.5859 9H13.9999Z" fill="#252C32"/>
</svg></td>
    </tr>
        </thead>
        <tbody>

   {props.currentItems.length>0&&props.currentItems.map((data,index)=>(
    <tr key={index} className="border border-solide h-[40px] hover:text-black hover:bg-gray-100 dark:hover:text-black "  >
   {props.tableshow.fullName&&
    <td className=" md:pl-2 flex md:items-center text-[10px] md:text-[15px] ">
    <div className=" md:m-1 md:w-[25px] md:h-[25px]  bg-[#FFA8A7] rounded-full flex justify-center  items-center">
<p className="hidden md:block progres plus-700 lh-15 text-[10px] text-white">{data.firstName.charAt(0)+ data.lastName.charAt(0)}</p>
                            </div>

        {data.firstName+" "+data.lastName}</td>
}
{props.tableshow.sex&&
    <td className="text-[10px] md:text-[15px]">{data.sex}</td>
}
{props.tableshow.Phone&&
    <td className="text-[10px] md:text-[15px]">{data.phone}</td>
}
{props.tableshow.City&&
    <td className="text-[10px] md:text-[15px]">{data.city}</td>
}
{props.tableshow.church&&
    <td className="text-[10px] md:text-[15px]">{data.church}</td>
}
{props.tableshow.language&&
    <td className="text-[10px] md:text-[15px]">{data.language}</td>
}
{props.tableshow.arrivalDate&&
    <td className="text-[10px] md:text-[15px]">{data.arrivalDate}</td>
}
    {props.tableshow.Host&&
    <td className="flex items-center  gap-4 mt-2 text-white">
        {data.isHostAvailable!=1?
        <p className=" rounded-[6px] text-[14px] px-2 py-1 w-20 text-center text-[#CC0905] bg-[#FFEFEB] "  > no host</p>
         :
<p className=" rounded-[6px] text-[14px] px-2 py-1 w-20 text-center text-[#119C2B] bg-[#EBFFF1]"  >has hos</p>
}

    </td>
}
    
  </tr>
   ))}
  </tbody>
  
</table>
  )
}

export default Table