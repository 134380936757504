import React from "react";
import imageBorder from '../Assets/groupMessageshadow.png'

function WelcomeMessage(props){
    return(
        <div className=' flex items-center  '>
        <div className=' w-full text-start  flex md:flex-row justify-around  '>
            <div className=' mt-[50px] md:mt-[0px] w-[60%]'>
                <div>
                    <h1 className=' text-[15px] font-bold'
                    style={{
                        fontFamily: 'poppins',
                        fontStyle: 'normal',
                        fontWeight: '700',
                     
                        lineHeight: '27px',
                        /* identical to box height */
                        color: '#000000',
                    }}
                    >{props.title}</h1>
                </div>
                <div className='m-5 md:m-0'>
                    <p className=" first-letter:text-[20px] first-letter:font-bold text-justify  w-full  "
                     style={{
                        fontFamily: 'Saira',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize:' 14px',
                        lineHeight: '25px',
                        /* identical to box height */
                        
                      latterSpacing:'-0.02em',
                        
                        color: '#000000',
                    }}
                    >{props.message}</p>
                </div>
                <div className='  flex justify-end' >
                    <div className='ml-5 mr-5 md:ml-0 md:mr-0'>
                        
                    <p className='text-[14px] md:text-[20px]' style={{
                        fontFamily: 'Playfair Display',
                        fontStyle: 'normal',
                        fontWeight: '400',
                     
                        lineHeight: '27px',
                        /* identical to box height */
                        
                        letterSpacing: '-0.02em',
                        textTransform: 'uppercase',
                        
                        color: '#000000',
                    }}>{props.leaderName}</p>
                    <p className='text-[12px] md:text-[14px] ' style={{
                         fontFamily: 'Poppins',
                         fontStyle: 'normal',
                         fontWeight: '400',
                        
                         lineHeight: '21px',
                         /* identical to box height */
                         
                         letterSpacing: '-0.02em',
                         textTransform: 'lowercase',
                         
                         color: '#000000',
                    }}> {props.position}</p>
                    </div>
                </div>
            </div>
            <div className=' w-[30%] flex justify-center '>
        
        <div className='bg-contain w-[265px] h-[320px] md:w-[236px] md:h-[301px] rounded-[8px] rounded-[8px] '
        style={{
            background: `url(${imageBorder})`,
           
        }}
        >
            <img  className='rounded-[8px] w-[200px] h-[284px] ml-[28px] mt-[28px]  md:ml-[42px] md:w-[234px] md:h-[284px] md:mt-[41px] '
            style={{
             
              transform: `rotate(0.98deg)  `,
             
            }}
            src={props.image}
            />
        
            
        
        </div>
        </div>
            </div>
        
        
            </div>
    )
}
export default WelcomeMessage;