import React from 'react'
import Weather from './Weather'

function Ourcity(props) {
  return (
    <div className='  ' style={{
        // background: `rgba(3, 163, 245, 0.09)`
      }}>
         
          <div className='md:flex md:justify-start md:gap-10 md:mt-5  md:items-start '>
          <div className='md:w-[20%]  md:mt-[90px] md:mb-10'>
          <Weather props={props.props} />
          </div>
          <div className=' w-full   mt-[100px] '>
          <p className=' text-start font-bold flex  text-[20px] text-black font-plus  ' >{props.title}</p>
          <p className='m-5 md:-0 text-justify font-plus lh-20 font-400 text-black    text-[14px] font-normal    flex justify-start mx-auto '>{props.history}</p>
          </div>
         
            </div>
          
         
      </div>
  )
}

export default Ourcity