import React, { useState } from 'react';

function Esist() {
  const [formData, setFormData] = useState({
    h1: null,
    h2: null,
    h3: null,
    h4: null,
    h5: null,
    h6: null,
    h7: null,
    h8: null,
    m1: null,
    m2: null,
    m3: null,
    m4: null,
    m5: null,
    m6: null,
  });

  function OnHandle(e) {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  return (
    <div className='flex flex-col md:flex-row gap-4'>
      <div className='flex flex-col gap-4 mx-4 my-4'>
        <input
          placeholder='section'
          className='w-[300px] h-[40px] border-blue-500 bg-gray-200 border-[1px] rounded-md'
          onChange={OnHandle}
          name='h1'
        />
        <input
          placeholder='contener'
          className='w-[300px] h-[40px] border-blue-500 bg-gray-200 border-[1px] rounded-md'
          onChange={OnHandle}
          name='h2'
        />
        <input
          placeholder='image'
          className='w-[300px] h-[40px] border-blue-500 bg-gray-200 border-[1px] rounded-md'
          onChange={OnHandle}
          name='h3'
        />
        <input
          placeholder='text parent'
          className='w-[300px] h-[40px] border-blue-500 bg-gray-200 border-[1px] rounded-md'
          onChange={OnHandle}
          name='h4'
        />
        <input
          placeholder='title'
          className='w-[300px] h-[40px] border-blue-500 bg-gray-200 border-[1px] rounded-md'
          onChange={OnHandle}
          name='h5'
        />
        <input
          placeholder='name'
          className='w-[300px] h-[40px] border-blue-500 bg-gray-200 border-[1px] rounded-md'
          onChange={OnHandle}
          name='h6'
        />
        <input
          placeholder='moto'
          className='w-[300px] h-[40px] border-blue-500 bg-gray-200 border-[1px] rounded-md'
          onChange={OnHandle}
          name='h7'
        />
        <input
          placeholder='button'
          className='w-[300px] h-[40px] border-blue-500 bg-gray-200 border-[1px] rounded-md'
          onChange={OnHandle}
          name='h8'
        />

<input
          placeholder='message section'
          className='w-[300px] h-[40px] border-blue-500 bg-gray-200 border-[1px] rounded-md'
          onChange={OnHandle}
          name='m1'
        />
        <input
          placeholder='message contener'
          className='w-[300px] h-[40px] border-blue-500 bg-gray-200 border-[1px] rounded-md'
          onChange={OnHandle}
          name='m2'
        />
        <input
          placeholder='message title'
          className='w-[300px] h-[40px] border-blue-500 bg-gray-200 border-[1px] rounded-md'
          onChange={OnHandle}
          name='m3'
        />
        <input
          placeholder='message auther message'
          className='w-[300px] h-[40px] border-blue-500 bg-gray-200 border-[1px] rounded-md'
          onChange={OnHandle}
          name='m4'
        />
        <input
          placeholder='message auther name'
          className='w-[300px] h-[40px] border-blue-500 bg-gray-200 border-[1px] rounded-md'
          onChange={OnHandle}
          name='m5'
        />
        <input
          placeholder='message autor posetion'
          className='w-[300px] h-[40px] border-blue-500 bg-gray-200 border-[1px] rounded-md'
          onChange={OnHandle}
          name='m6'
        />
         <input
          placeholder='message image'
          className='w-[300px] h-[40px] border-blue-500 bg-gray-200 border-[1px] rounded-md'
          onChange={OnHandle}
          name='m6'
        />
      </div>
      <div className=''>
        <section
          className={` ${formData.h1} `}
        >
          <img
            className={`${formData.h3} `}
            src='https://www.searchenginejournal.com/wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej-1280x720.png'
            alt=''
          />
          <div className={`${formData.h4} `}>
            <p className={`${formData.h5} `}>Welcome to Arbaminch</p>
            <p className={`${formData.h6} `}>|Main campus.</p>
            <p className={`${formData.h7} `}>
              My dear brothers and sisters, take note of this: Everyone should be quick to listen, slow to speak and slow to become angry. James 1:19
            </p>
            <button className={`${formData.h8} `}>Register now</button>
          </div>
        </section>
        <section
          className={` ${formData.m1} `}
        >
          <div className={`${formData.m2} `}>
            <p className={`${formData.m3} `}>Welcome message</p>
            <p className={`${formData.m4} `}> Welcome to the Arba Minch University Christian Fellowship! We are so glad that you have decided to join us in our journey of faith and fellowship. Our goal is to create a community where students can come together to deepen their relationship with God and connect with other believers. We have a variety of activities planned including Bible studies, prayer meetings, worship nights, and service opportunities. These events are designed to help you grow in your faith and provide you with a supportive community during your time at the university. We believe that faith should be an integral part of your university experience and we are committed to providing opportunities for you to explore and deepen your relationship with God. We look forward to getting to know you and supporting you as you navigate your college journey. Please don't hesitate to reach out to us with any questions or if you need any support. We are excited to have you be a part of our fellowship and can't wait to see what God has in store for us. Welcome to the Arba Minch University Christian Fellowship! </p>
            <p className={`${formData.m5} `}>
            Mekilt tsegaye
            </p>
            <p className={`${formData.m6} `}>MAIN LEADER</p>
          </div>
          <img
            className={`${formData.m7} `}
            src='https://www.searchenginejournal.com/wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej-1280x720.png'
            alt=''
          />
        </section>
      </div>
    </div>
  );
}

export default Esist;
