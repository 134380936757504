import React, { useState } from 'react'
import { useEffect } from 'react';
import Buttons from '../Component/Button'
import Input from '../Component/Input'
import { useAuth } from '../Function/AuthProvider';
import {Button } from 'antd';
import Carroussel from '../DemoPage/Page/Carousel';
import ProgramCardDemo from '../DemoPage/Component/ProgramCardDemo';
function Program(props) {
  const auth=useAuth()
  const [someData, setSomeData] = useState([]);
  const [itemsPerPage,setItemPerPage]=useState(24)
  const [page, setPage] = useState(1);
  useEffect(() => {
      // Get the updated props data
      const myData = props.formData.ProgramData;
   
      // Perform the side-effect of setting the new state value
      setSomeData(myData);
     
  }, [props.formData.ProgramData,props.formData.serviceID]);
  
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentLeader= someData!=null&&someData.slice(startIndex, endIndex);

  const handleTimeBlur = (e) => {
    const [hour, minute] = e.target.value.split(":");
    const isPM = parseInt(hour, 10) >= 12;
    const hourIn12Format = ((parseInt(hour, 10) + 11) % 12 + 1).toString().padStart(2, "0");
    const timeIn12Format = `${hourIn12Format}:${minute} ${isPM ? "pm" : "am"}`;
    props.formData({...props.formData,time:`timeIn12Format`})
    
    props.handleChange({ target: { name: e.target.name, value: timeIn12Format } });

   
   
  };
  const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const [CARDS,SETCARDS]=useState(props.formData?.ProgramData?.map(({name,day,address,time,id})=>({name,day,address,time,id})));

function convertTo12HourFormat(time24) {
  // Split the time string into hours and minutes
  const [hours, minutes] = time24.split(':').map(Number);

  // Determine whether it's AM or PM
  const period = hours < 12 ? 'AM' : 'PM';

  // Convert hours to 12-hour format
  const hours12 = hours % 12 || 12; // Handle midnight (0:00) as 12:00 AM

  // Create the 12-hour time string
  const newMinutes=minutes<10?`0${minutes}`:minutes
  const time12 = `${hours12}:${newMinutes} `;

  return time12==='6:0'?`${time12}+0 ${period}`:`${time12} ${period}`;
} 

useEffect(()=>{
  SETCARDS(props.formData?.ProgramData?.map(({name,day,address,time,id})=>({name,day,address,time,id})))
},[props.formData.serviceID,props.formData.ProgramData]);
useEffect(() => {
  let cardss = CARDS?.map((item, index) => {
    return {
      key: index,
      content: <ProgramCardDemo onClick={() => props.updateItem('updateProgram', item.id, item)} className='flex flex-col justify-center rounded-t-[20px]' time={convertTo12HourFormat(item.time)} day={item.day} address={item.address} name={item.name} id={item.id} />
    };
  });
  setCards(cardss);
}, [props.formData.ProgramData,props.formData.serviceID,CARDS]);
const [cards,setCards]=useState([]);
  return (
    <div className='flex gap-8 w-full'>
      {auth.contextHolder}
    <div className='md:w-[30%] mt-[42px] lh-0  '>
    <Input onChange={props.handleChange} name='name' value={props.formData.name} placeholder="service title"  lable='Title'  style='text-[14px] lh-0 w-full h-[40px]   mt-[-7px] mb-[0px]' lableStyle='lh-0 font-bold w-full ' />
                {/* <Input onChange={props.handleChange} value={props.formData.day} type='text' name='day' placeholder="wed"  lable='Day'  style='text-[14px] lh-0 w-full h-[40px] mt-[-7px] mb-[0px]' lableStyle='lh-0 font-bold w-full ' /> */}
               
                <Input onChange={props.handleChange}   onBlur={handleTimeBlur} value={props.formData.time} type='time' name='time' placeholder=""  lable='Time'  style='text-[14px] lh-0 w-full h-[40px] mt-[-7px] mb-[0px]' lableStyle='lh-0 font-bold w-full ' />
                <Input onChange={props.handleChange} value={props.formData.address} name='address' placeholder=" church name"  lable='Church'  style='text-[14px] lh-0 w-full h-[40px] mt-[-7px] mb-[0px]' lableStyle='lh-0 font-bold w-full ' />
                <Input onChange={props.handleChange} value={props.formData.serviceID} name='serviceID' placeholder="id"  lable='ServiceID'  style=' hidden  text-[14px] lh-0 md:w-full h-[40px] mt-[-7px] mb-[0px]' lableStyle='hidden lh-0 font-bold w-full ' />
               <p className='lh-0 font-bold w-full ' >Day</p>
                <select className='w-[200px]  py-2 border-[2px] rounded-[4px] w-[300px] md:w-[320px] border-grey-300 mb-2'  name='day'  onChange={props.handleChange}>
              <option value={props.formData.day}>Select Day</option>
             {weekdays.map((item, index) => (
           
               <option value={item}>
          {item}
              </option>

            
))}
             </select>  
      <div className='flex gap-4 justify-start w-full'>
        <div>
        {props?.formData?.span!=false? 
        <Button style={{
          background:'#9009CF'
        }} className="bg-[#9009CF] md:w-[150px] md:h-[40px] font-body md:text-[18px]"
        type="primary" size="medium" loading>
      
     Saving
    </Button>:
      <Buttons name='program' handlclick={props.handlclick} style='bg-[#8E98A8] w-32 h-8'>{props.formData.serviceID!=null?'Update':'Add More'}</Buttons>
     }  
        <p className='text-[10px] lh-15 ml-[5px] mt-[10px] text-gray-900'>You can Add multiple
 of programs</p>

        </div>
        {props.formData?.serviceID&&
      <div>
<Buttons  name='removeProgram' handlclick={() => props.removeItem('removeProgram',props.formData?.serviceID)} style='  bg-red-500 w-32 h-8'>Delete </Buttons>
</div>
}
      </div>
     
        </div>
        <div className='hidden lg:block xl:w-[70%] lg:w-[80%] flex flex-col justify-right'>
        <div id="Services" className="pb-[100px]">
      <p className='mt-[50px] text-center lg:text-[38px] xl:text-[64px] font-[600] font-Poppins lg:leading-[48px] xl:leading-[62px] tracking-[-2px]'>Regular Programs</p>
      <p className=' lg:text-[14px] xl:text-[20px] text-center font-[400] font-Poppins text-[#6B1768] lg:mt-[12px] xl:mt-[20px]'>You are invited to be part of these programs</p>
   
    <Carroussel
      cards={cards}
      height="500px"
       width="30%"
      margin="0 auto"
      offset={3}
      showArrows={false}
    />
  </div>
        </div>
    </div>
  )
}

export default Program