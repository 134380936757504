
function MainRouter(props) {
 
    return (
        <> 
        <div className="flex">
        </div> 
        {props.children}
</>
  )
}

export default MainRouter