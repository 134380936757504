import React from 'react'

import Subtract from '../Assets/Subtract.png'
import fellow from "../css/Fellow.css";
import dikor from "../Assets/dikor.png";
function FellowshipData(props) {
   
  return (
    <div
    className="md:pb-2 md:h-[288px] clip"
    style={{
      background:`${props.data.bgColorFellow}`,
    }}
  >
    <div className=" flex justify-end">
    
      <div className=" overflow-hidden ">
          <div class="cards  md:w-[600px] md:h-[207px]">
      <div class="worshipImg  md:w-[438.24px] bottom-[40%] md:h-[350px]">
        <img class="img2" src={props.Fimage} alt="" />
      </div>
      <img class="img3" src={Subtract} alt=""/>
    </div> 
          </div>
      </div>
      <div className=" mt-[-140px]">
        <h1 className="  ml-[60px] mt-[30px] font-plus font-700 text-[30px]" >
          {props.name}
        </h1>
        <div className='flex items-center' style={{
background:`url(${dikor})`,
position:'relative',
backgroundSize:'100% 90%',
height:'150px'
}}>

        <div className="flex gap-10 ml-[60px] mt-[-20px]">
          <p>
            <p className=" text-[14px] text-black font-bold text-center  font-plus " >{props.membersNum}+</p>
            <p className="text-[14px] text-black font-plus" >{props.data.membersCaption}</p>
          </p>

          <p>
            <p className=" text-[14px] text-black font-bold text-center font-plus" >{props.teamNum}+</p>
            <p className="text-[14px] text-black  font-plus" >{props.data.teamsCaption}</p>
          </p>
          <p>
            <p className=" text-[14px] text-black font-plus font-bold text-center  font-plus"  >{props.service}+</p>
            <p className="text-[14px]  text-black font-plus" >{props.data.servicesCaption}</p>
          </p>
        </div>
        </div>
      </div>
  </div>
  )
}

export default FellowshipData