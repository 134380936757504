import React from 'react'

function Modal(props) {
  return (
    <div onClick={props.setShowModal}  className="modal fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm
      flex justify-center items-center
        ">
<div className="modal-content bg-white px-8 py-2  rounded-md ">
{props.children}

            </div>
            </div>
  )
}

export default Modal