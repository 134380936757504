import React, { useEffect, useState } from 'react'
import Table from './Table'
import { useAuth } from '../Function/AuthProvider';

function ALLcampus(props) {
    const auth=useAuth();
    const table=
{
    ID:'No',
    Name:'Name',
Created_by:'Created by',
Phone:'Phone',
Log:'Last update',
progress:'Progress',
Statues:'Status'
}

const [dashboard,setDashboard]=useState({
untitled:'',
publish:'',
unpublish:'',
progress:'',
intrash:'',
compintrash:'',
comp_unpublshed:'',
completedcampus:'',
})

useEffect(()=>{
  const untitleds=auth?.allCampus?.campuses.filter((item) =>item.campusDataEntryProgressPlain<3&&item.campusStatus!=='in Trash')
  const inTrash=auth?.allCampus?.campuses.filter((item) => item.campusStatus==='in Trash')
  const compinTrash=auth?.allCampus?.campuses.filter((item) => item.campusStatus==='in Trash'&&item.campusDataEntryProgressPlain===11)
  const Unpublish=auth?.allCampus?.campuses.filter((item) => item.campusStatus==='Unpublished')
  const Publish=auth?.allCampus?.campuses.filter((item) => item.campusStatus==='Published'&&item.campusStatus!=='in Trash')
  const Progress=auth?.allCampus?.campuses.filter((item) => item?.campusDataEntryProgressPlain>=3 &&item.campusDataEntryProgressPlain<11&&item.campusStatus!=='in Trash')
  const comp_Unpublshed=auth?.allCampus?.campuses.filter((item) => item?.campusDataEntryProgressPlain===11 &&item.campusStatus!=='in Trash'&&item.campusStatus==='Unpublished')
 const completedCampus=auth?.allCampus?.campuses.filter((item)=>item?.campusDataEntryProgressPlain===11&&item.campusStatus!=='in Trash')
  setDashboard({...dashboard,compintrash:compinTrash,completedcampus:completedCampus,comp_unpublshed:comp_Unpublshed,progress:Progress,unpublish:Unpublish,publish:Publish,intrash:inTrash,untitled:untitleds})
},[auth?.allCampus?.campuses])



  return (
    <div className={` bg-white h-screen overflow-y ${props.isDarkMode?'dark dark:bg-[#000] dark:text-white':'bg-[#F5F5F5]'}`}>

    <div className={`dark:bg-[#000] dark:text-white ${props.expand===false?'md:ml-[5.5%]  ': 'md:ml-[17.5%]  ' }`}>
     
             {auth.contextHolder}
            <div className=" bg-white -mt-[1px]  md:mt-[20px] mb-5 dark:bg-black dark:text-white">

<div className="  ">
{/* <img className='w-full h-[400px] mt-[0px]' src={horizon} alt="" /> */}
<div className=' flex flex-wrap  justify-center mt-[120px]'>
<div className='flex justify-center items-center  bg-purple-500 text-white'>
   <div className='flex flex-col justify-center items-center  bottom-1 p-5'>
    <p className='text-8xl font-Poppins font-bold'>{auth?.allCampus?.campuses?.length-dashboard?.intrash?.length}</p>
    <p>Fellowship Page</p>
    </div> 
  </div>
 
  {/*  */}
  <div className='flex justify-center items-center    bg-yellow-500 text-white'>
   <div className='flex flex-col justify-center items-center  bottom-1 p-5'>
    <p className='text-7xl font-Poppins font-bold text-white'>{dashboard?.progress?.length+dashboard?.untitled?.length}</p>
   <div className='flex gap-4 mt-4'>

    <div>

    <p>	on Progress <span className='text-yellow-600 bg-[#fff29ca8] rounded-full py-[1px] px-1'>3-10</span></p>
   <p className='text-6xl text-center font-Poppins font-bold text-white'>{dashboard?.progress?.length}</p>
    </div>
    <div>

    <p>	Initial <span className='text-yellow-600 bg-[#fff29ca8] rounded-full py-[1px] px-2'>1-2</span></p>
   <p className='text-6xl  text-center font-Poppins font-bold text-white'>{dashboard?.untitled?.length}</p>
    </div>
   </div>

    </div> 
  </div>
  {/*  */}
  <div className='flex justify-center items-center    bg-green-500 text-white'>
   <div className='flex flex-col justify-center items-center  bottom-1 p-5'>
    <p className='text-7xl font-Poppins font-bold text-white'>{dashboard?.completedcampus?.length}</p>
   <div className='flex gap-4 mt-4'>

    <div>

    <p>	completed <span className='text-green-600 bg-[#a3ff9ca8] rounded-full py-[1px] px-2'>1-11</span> </p>
   
    <p className='text-6xl font-Poppins text-center font-bold text-white'>{dashboard?.comp_unpublshed?.length}</p>
    </div>
    <div>

    <p>	Published</p>
    <p className='text-6xl font-Poppins text-center font-bold text-white'>{dashboard?.publish?.length}</p>
    </div>
   </div>

    </div> 
  </div>
 
  {/*  */}

{/*  */}
<div className='flex justify-center items-center    bg-red-500 text-white'>
   <div className='flex flex-col justify-center items-center  bottom-1 p-5'>
    <p className='text-7xl font-Poppins font-bold text-white'>{dashboard?.intrash?.length}</p>
   <div className='flex gap-4 mt-4'>

    <div>

    <p>	completed <span className='text-red-600 bg-[#ff9c9ca8] rounded-full py-[1px] px-2'>1-11</span> </p>
   
    <p className='text-6xl font-Poppins text-center font-bold text-white'>{dashboard?.compintrash?.length}</p>
    </div>
    <div>

    <p>	not completed</p>
    <p className='text-6xl font-Poppins text-center font-bold text-white'>{dashboard?.intrash?.length-dashboard?.compintrash?.length}</p>
    </div>
   </div>

    </div> 
  </div>
  {/*  */}
 
</div>
</div>
<div className=' ml-[3vw]'>

<Table  table={table} off='DA' invited={auth?.allCampus?.campuses}/>
     
</div>

            </div>
      </div>
      </div>
  )
}

export default ALLcampus