import React, { useRef } from 'react'
import { useAuth } from '../Function/AuthProvider'
import { Tour } from 'antd';

function Toor() {
   const auth=useAuth();
  return (
    <div>
              <Tour open={auth.open} onClose={() => auth.setOpen(false)} mask={false} type="primary" steps={auth.steps} />
    </div>
  )
}

export default Toor