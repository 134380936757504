import React from 'react'
import AuthPage from './AuthPage'
import AuthTitle from './AuthTitle'
import { Link, useNavigate } from "react-router-dom";
import resetnotif from '../Assets/resetnotif.png'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import '../css/baseStyle.css'
export default function (props) {
  return (
  //  <div className='mt-[60px]  font-plus h-screen'>
  //       <img className='h-[311px] ' src={props.image} alt="reset password" />
  //       <div className='mt-[32px]  downNotif '> <strong className=' text-center text-justify text-[#0AE720] plus-700 '>{props?.title}</strong>
  //          <p> 
  //            {props.info}
  //         </p>
  //     </div>
  //       <Link className='text-[#9009CF]  hover:text-[#2eb157] font-plus underline underline-offset-2' to="/"><BsFillArrowLeftCircleFill size={20}/></Link>
  //    </div>
  <div className='flex flex-col items-center overflow-hidden'>

  <div className='max-w-[1440px]  '>
<div className=' mb-52 md:mb-24 lg:mb-0 comingBG h-[400px] md:h-[800px] lg:h-[1024px] w-full lg:max-w-[1440px] lg:w-[100vw]'  >
<div className='flex flex-col items-center  '>
 <img className='lg:w-[427px]  lg:object-cover object-contain lg:h-[297px] mt-[25px] md:mt-[27px]  w-[250px] h-[150px]' src={props.image} alt="" />
 <h1 className='Poppins Popp-500 lg:text-[32px] text-[24px] mt-[10px] lg:mt-[25px]'>{props.title}</h1>
 <p className='Poppins Popp-400 text-[16px] text-center p-4 lg:mx-[335px]'>
 {props.info}
</p>
{props.children}
</div>
<div className='flex flex-col items-center'>
{/* <img className=' mt-[40px] w-[600px]' src={cuate} alt="" />
  */}
</div>
 </div>

   </div>
   </div>
  )
}
