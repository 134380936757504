import React from 'react'
import Button from './Button'
import { useAuth } from '../Function/AuthProvider'

function NoPage(props) {
  const auth=useAuth();
  return (
    <div className={`${auth.isDarkMode?'dark dark:bg-[#000] dark:text-white mb-5':'bg-[#fff] mb-5'}`}>

    <div className=' mb-4 flex flex-col justify-center items-center mt-[40px] dark:bg-[#000] dark:text-white dark:border-[1px]'>
    <svg width="153" height="120" viewBox="0 0 153 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="76.5" cy="52" r="52" fill="#EAECF0"/>
    <g filter="url(#filter0_dd_378_4231)">
    <path d="M78.1 16C67.3273 16 57.7978 21.3233 51.9987 29.4829C50.105 29.0363 48.1301 28.8 46.1 28.8C31.9615 28.8 20.5 40.2615 20.5 54.4C20.5 68.5385 31.9615 80 46.1 80L110.1 80C122.471 80 132.5 69.9712 132.5 57.6C132.5 45.2288 122.471 35.2 110.1 35.2C109.221 35.2 108.354 35.2506 107.502 35.349C102.598 23.9677 91.2797 16 78.1 16Z" fill="#F9FAFB"/>
    <ellipse cx="46.1" cy="54.4" rx="25.6" ry="25.6" fill="url(#paint0_linear_378_4231)"/>
    <circle cx="78.0996" cy="48" r="32" fill="url(#paint1_linear_378_4231)"/>
    <ellipse cx="110.1" cy="57.6" rx="22.4" ry="22.4" fill="url(#paint2_linear_378_4231)"/>
    </g>
    <circle cx="21.5" cy="19" r="5" fill="#F2F4F7"/>
    <circle cx="18.5" cy="109" r="7" fill="#F2F4F7"/>
    <circle cx="145.5" cy="35" r="7" fill="#F2F4F7"/>
    <circle cx="134.5" cy="8" r="4" fill="#F2F4F7"/>
    <g filter="url(#filter1_b_378_4231)">
    <rect x="52.5" y="62" width="48" height="48" rx="24" fill="#344054" fill-opacity="0.4"/>
    <g clipPath="url(#clip0_378_4231)">
    <path d="M80.5004 90L76.5004 86M76.5004 86L72.5004 90M76.5004 86V95M84.8904 92.39C85.8658 91.8583 86.6363 91.0169 87.0803 89.9986C87.5244 88.9804 87.6167 87.8432 87.3427 86.7667C87.0686 85.6902 86.4439 84.7355 85.5671 84.0535C84.6903 83.3714 83.6113 83.0007 82.5004 83H81.2404C80.9378 81.8293 80.3736 80.7423 79.5904 79.821C78.8072 78.8997 77.8253 78.1679 76.7185 77.6806C75.6118 77.1934 74.409 76.9634 73.2006 77.0079C71.9921 77.0524 70.8095 77.3703 69.7416 77.9377C68.6737 78.505 67.7484 79.3071 67.0351 80.2836C66.3218 81.2601 65.8391 82.3856 65.6234 83.5754C65.4077 84.7653 65.4646 85.9885 65.7897 87.1533C66.1148 88.318 66.6997 89.3939 67.5004 90.3" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </g>
    <defs>
    <filter id="filter0_dd_378_4231" x="0.5" y="16" width="152" height="104" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_dropShadow_378_4231"/>
    <feOffset dy="8"/>
    <feGaussianBlur stdDeviation="4"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_378_4231"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect2_dropShadow_378_4231"/>
    <feOffset dy="20"/>
    <feGaussianBlur stdDeviation="12"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"/>
    <feBlend mode="normal" in2="effect1_dropShadow_378_4231" result="effect2_dropShadow_378_4231"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_378_4231" result="shape"/>
    </filter>
    <filter id="filter1_b_378_4231" x="44.5" y="54" width="64" height="64" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feGaussianBlur in="BackgroundImageFix" stdDeviation="4"/>
    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_378_4231"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_378_4231" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_378_4231" x1="26.4429" y1="37.4857" x2="71.7" y2="80" gradientUnits="userSpaceOnUse">
    <stop stop-color="#D0D5DD"/>
    <stop offset="0.350715" stop-color="white" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint1_linear_378_4231" x1="53.5282" y1="26.8571" x2="110.1" y2="80" gradientUnits="userSpaceOnUse">
    <stop stop-color="#D0D5DD"/>
    <stop offset="0.350715" stop-color="white" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint2_linear_378_4231" x1="92.9002" y1="42.8" x2="132.5" y2="79.9999" gradientUnits="userSpaceOnUse">
    <stop stop-color="#D0D5DD"/>
    <stop offset="0.350715" stop-color="white" stop-opacity="0"/>
    </linearGradient>
    <clipPath id="clip0_378_4231">
    <rect width="24" height="24" fill="white" transform="translate(64.5 74)"/>
    </clipPath>
    </defs>
    </svg>
    <div className=' mb-8 text-center mt-[16px] dark:bg-[#000] dark:text-white dark:border-[1px]'>
        <h1 className='font-plus lh-24 text-[16px]  font-bold'>Start by creating a page</h1>
        <div className='text-[#667085] progress text-[14px] lh-20 plus-400 '>
    
        <p >Any page created will live have.</p>
        <p>Start creating by adding your campus information.</p>
        </div>
        <div className='mt-[24px] mb-[48px] w-full  dark:bg-[#000] dark:text-white border-[1px] '>
           
            {/* <Button handlclick={props.handlclick} style='p-3 w-full  px-8 flex gap-2 justify-center items-center' name='Create'>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_378_4254)">
    <path d="M13.3335 13.3333L10.0002 10M10.0002 10L6.66688 13.3333M10.0002 10V17.5M16.9919 15.325C17.8047 14.8819 18.4467 14.1807 18.8168 13.3322C19.1868 12.4836 19.2637 11.536 19.0354 10.6389C18.807 9.74179 18.2865 8.94626 17.5558 8.37787C16.8251 7.80948 15.9259 7.50061 15.0002 7.5H13.9502C13.698 6.52436 13.2278 5.61861 12.5752 4.85082C11.9225 4.08304 11.1042 3.47321 10.182 3.06717C9.25967 2.66113 8.25734 2.46946 7.25031 2.50657C6.24328 2.54367 5.25777 2.80858 4.36786 3.28138C3.47795 3.75419 2.7068 4.42258 2.1124 5.23631C1.51799 6.05005 1.11579 6.98794 0.936028 7.97949C0.756269 8.97104 0.803632 9.99044 1.07456 10.961C1.34548 11.9317 1.83291 12.8282 2.50021 13.5833" stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_378_4254">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    Create
            </Button> */}
        </div>
    </div>
    </div>
    </div>
  )
}

export default NoPage