
import React from 'react'
import image from '../Assets/unsplash.png'
export default function (props) {
  return (
    <div>
        <img className={props.className} style={{overflow:'hidden'}}  id={props.id}  src={props.image}/>
    </div>
  )
}
