import React from 'react'

function WeatherCard(props) {

  return (
    <div >
    <div className='  p-10 flex justify-center '
    style={{
      background: `rgba(255, 255, 255, 0.81)`,
boxShadow: `0px 0px 4px rgba(0, 0, 0, 0.25)`,
borderRadius: '20px',
    }}
    >
      <div>
     {/* <p className='text-[20px] text-center md:text-4xl font-thin'>{props.day}</p> 
     <p className='text-sm text-center md:text-sm font-light '>{props.city}</p> */}
     <div className='flex justify-center md:mt-[39px]'>
     <figure className='place-items-start'><img className='w-[156px] h-[134px] ' src={props.icon}/></figure>
     </div>
   <div className='text-center '>
   <p className=' text-[60px]   md:text-[100px] mb-[50px] mt-[10px]  md:mt-[97px] md:mb-[182px]'
    style={{
      fontFamily: 'poppins',
      fontStyle: 'normal',
      fontWeight: '400',
    
      lineHeight: '24px',
      /* identical to box height */
      textAlign:'center',
      color: '#000000',
  }}
   >{props.temp} °C</p> 
    <div className='flex justify-center text-justify items-center self-center mx-auto'>
    <div className='flex justify-center gap-4 mb-[12px]'
    style={{
      
fontFamily: 'Playfair Display',
fontStyle: 'normal',
fontWeight: '400',
fontSize: '16px',
lineHeight: '12px',
/* identical to box height, or 72% */

textAlign: 'center',

color: '#000000',

    }}
    >
    <p className='text-[12px] md:text-sm font-bold'>{props.condtion} <span className='font-light'>Humidity: {props.humidity}</span> </p>
      <p className='text-[18px] text-center md:text-sm  font-bold'>{props.day}</p> 
    </div>
    </div>
   </div>
  </div>

      </div>
  </div>
  )
}

export default WeatherCard