import carve from '../Assets/carve.png'
import resetpassword from '../Assets/resetpassword.png'
import seccsspage from '../Assets/seccsspage.png'
import resetnotif from '../Assets/resetnotif.png'
import confirmNotify from '../Assets/confirmNotify.png'
const auth={
    carve:carve,
    resetpassword:resetpassword,
    seccsspage:seccsspage,
resetNotify:resetnotif,
confirmNotify:confirmNotify,
resetPassword:" for submitted your information, we received a request to reset the password for your account.if you initiated this request, please follow the steps in the email to reset password."
,
confirmEmail:' for submitted your information, we will review it to ensure that it meets our standards.you will receive a confirmation email once your account has been approved.'
,
invitemailHeader:'Etcompus.org.',
resetHeader:' Etcompus.org.',
}

export default {auth};