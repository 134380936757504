import Cookies from 'js-cookie';
import React from 'react'
import Button from './Button'
import Input from './Input'
import axios from 'axios'
import { useState } from 'react';
import { toast } from 'react-toastify';
import { BASE_URL } from '../Function/Base';
import { useAuth } from '../Function/AuthProvider';
function AccountSetting(props) {
  const auth=useAuth()
  axios.defaults.headers.common['Authorization'] = `Bearer ${auth.token}`;
  const [formData,setFormData]=useState({
    firstName:props.profile?.firstName,
    lastName:props.profile?.lastName,
    email:props.profile?.email,
    phone:props.profile?.phone,
  });
function handleSubmit(e){
   e.preventDefault();
setFormData({...formData,[e.target.name]:e.target.value})
}

const url=`${BASE_URL}/api/change-profile`
async function update(){
 
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  try {
    if( formData.email!=null&&formData.email!=""&&formData.firstName!=null&&formData.firstName!=""&&formData.phone!=null&&formData.phone!=""&&formData.lastName!=null&&formData.lastName!=""){
    const response = await axios.post(url,
        {
          firstName:formData.firstName,
          lastName:formData.lastName,
          email:formData.email,
          phone:formData.phone
        });
                     
      if(response?.data.status===200){
        auth.openMessage(response.data.message,'success',1)
          //  navigate('/',{state:{header:props.data.invitemailHeader ,image:props.data.confirmNotify,message:props.data.confirmEmail}});
           auth.APIloading();
            }else{
              auth.openMessage(response?.data?.message
                ,'error',2)
            }
           } else if(formData.firstName===null||formData.firstName===""){
              auth.openMessage('First Name required', 'error', 3);
    
            }else if(formData.lastName===null||formData.lastName===""){
              auth.openMessage('Last Name required', 'error', 3);
            }else if(formData.phone===null||formData.phone===""){
              auth.openMessage('Phone number required', 'error', 3);
    
            
            }else if( !emailRegex.test(formData.email)){
              auth.openMessage('check email please!', 'error', 2);
              setFormData({...formData,email:null})}
    } catch (error) {
      auth.openMessage(error.message
        ,'error',2)
    }
  }

  return (
    <div className=' dark:bg-black'>
      {auth.contexHolder}
        <div className='grid grid-cols-1 md:w-full gap-[20px] dark:bg-black'>
          <div className='grid grid-col md:grid-cols-2 gap-4 w-full'>
       <Input onChange={handleSubmit} name='firstName' lableStyle='lh-0 font-bold md:w-full '  value={formData.firstName} placeholder={formData?.firstName}   lable='First Name'  style={`text-[14px] lh-0 mb-[-7px] md:w-full h-[40px] mt-[-7px] mb-[0px]' lableStyle='lh-0 mt-[-5px] md:w-full text-[18px] font-plus border dark:text-black ${auth.errorInput&&auth.errorInput==='First Name required'?'border-pink-500':''}`} />
       <Input onChange={handleSubmit} name='lastName' lableStyle='lh-0 font-bold md:w-full '  value={formData.lastName} placeholder={formData?.lastName}   lable='Last Name'  style={`text-[14px] lh-0 md:w-full h-[40px] mt-[-7px] mb-[0px]' lableStyle='lh-0 mt-[-5px] md:w-full text-[18px] font-plus border dark:text-black ${auth.errorInput&&auth.errorInput==='Last Name required'?'border-pink-500':''}`} />
          </div>
          <div className='grid grid-col md:grid-cols-2 gap-4 w-full'>

       <Input onChange={handleSubmit} name='phone' lableStyle='lh-0 font-bold w-full '  value={formData.phone} placeholder={formData?.phone}   lable='Phone Number'  style={`text-[14px] lh-0 md:w-full h-[40px] mt-[-7px] mb-[0px]' lableStyle='text-[18px] lh-0 font-plus md:w-full dark:text-black ${auth.errorInput&&auth.errorInput==='Incorrect email or password!'||auth.errorInput==='Phone number is already in use!'||auth.errorInput==='Phone number required'?'border-pink-500':''}`} />  
       <Input disable={true} onChange={handleSubmit} name='email' lableStyle='lh-0 font-bold w-full '  value={formData.email} placeholder={formData?.email}  lable='Email'  style={`text-[14px] lh-0 md:w-full h-[40px] mt-[-7px] mb-[0px] border lableStyle='text-[18px] lh-0 font-plus md:w-full dark:text-black ${auth.errorInput&&auth.errorInput==='Incorrect email or password!'||auth.errorInput==='Email is already in use!'||auth.errorInput==='All field required'||auth.errorInput==='check email please!'?'border-pink-500':''} `}  />
          </div>
       {/* <Input read='readOnly' onChange={props.handleSubmit} name='Campus' placeholder="Arbaminch main campus"  lable='Campus'  style='text-[14px] lh-0 w-full h-[40px] mt-[-7px] mb-[0px]' lableStyle='text-[18px] lh-0 font-plus w-full ' />
    */}
        </div>
        <Button name='update' handlclick={update} style='bg-[#8E98A8] w-32 h-[40px] mb-[32px] mt-[66px]'>Update</Button>
    </div>
  )
}

export default AccountSetting