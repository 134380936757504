import React from 'react'
import { useLocation } from 'react-router-dom';

function DashCard(props) {

  return (
    <div key={props.index} className='bg-white shadow-sm relative h-[143px] lg:h-[158px]  flex justify-end'>
        {props.data.img}
        <div className='absolute bottom-0 w-full'>
            <p className='text-[36px] Plus-700 font-bold mb-[20px] ml-[10px]' >{props.data.total}</p>
            <div className={`w-full h-[34px] flex items-center justify-center text-white text-center text-bold ${props.data.color}`}>
              <p className='text-[16px] Plus-700'>{props.data.title}</p>
            </div>
        </div>
    </div>
  )
}

export default DashCard