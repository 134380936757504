import React from "react";

function FellowGroups(props) {
   
  return (
    <div>
      <div className="md:m-20 m-2 text-white">
        <h1 className="text-3xl font-bold">Teams waiting You </h1>
        <p className="md:w-[40%] mb-5">
      a  groups of people who come together to support and encourage each other in their spiritual journey.
        </p>
        <div className="grid grid-cols-2 md:grid-cols-3 text-Cblack gap-2">
{props.team.map((data,index)=>(
    <div className=" md:w-[80%] h-full  bg-[#FFD02F] h-10 text-xs px-2 py-1  rounded-lg flex  justify-between" key={index}>
    <p>{data.name}</p>
    <p className="font-bold">{data.members}</p>
</div>
))}
        </div>
      </div>
    </div>
  );
}

export default FellowGroups;
