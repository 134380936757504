import { Progress } from 'antd'
import React from 'react'

function ProgressPage(props) {
  return (
   <>
    <div className="mt-[16px] ml-[21px] flex justify-between mr-[13px]">
        <div>
    <p className="progres plus-300 text-[10px] lh-15">{props.date}</p>
    <p className="mt-[6px] text-[#93A1FC] progres plus-700 text-[14px] lh-18 mb-[13px]">{props.states}</p>
        </div>
        <div className="w-[30px] h-[30px] rounded-full bg-white flex justify-center items-center">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.5 8C21.43 8 23 6.43 23 4.5C23 2.57 21.43 1 19.5 1C17.57 1 16 2.57 16 4.5C16 6.43 17.57 8 19.5 8ZM19.5 10C20 10 20.5 9.93 21 9.79V16C21 18.76 18.76 21 16 21H8C5.24 21 3 18.76 3 16V8C3 5.24 5.24 3 8 3H14.21C13.9775 3.81801 13.9379 4.67881 14.0944 5.51469C14.2509 6.35058 14.5993 7.13875 15.112 7.81722C15.6247 8.49568 16.2879 9.04593 17.0493 9.42469C17.8107 9.80345 18.6496 10.0004 19.5 10Z" fill="#FFC727"/>
    </svg>
    
        </div>
    </div>
    <div className="ml-[23px] mr-[13px]">
        <h1 className="progres plus-700 text-[14px] lh-18">{props.name}</h1>
    <p className="progres plus-300 text-[12px] lh-15 mt-[4px]">
   {props.message}
    </p>
    <div className="mt-[14px] mb-[13px]">
    <Progress strokeColor="#00A851" percent={95} status="active" />
    <p className="progres plus-300 text-[10px] lh-15 mt-[-10px] ">Progress</p>
    </div>
    
    </div>
 </>
  )
}

export default ProgressPage