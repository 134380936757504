import React from 'react'

function AuthTitle(props) {
    const style=`authheading ${props.style}`;
  return (
   <h1 className={style}>
{props.title}

   </h1>
  )
}

export default AuthTitle