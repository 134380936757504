import React, { useState } from 'react'
import { Link,  useNavigate } from 'react-router-dom'
import { BASE_URL, demo_URL } from '../Function/Base';
import axios from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useAuth } from '../Function/AuthProvider';
import { BsThreeDotsVertical } from "react-icons/bs";
import { Delete } from 'react-feather';
import Modal from './Modal';
import { Dropdown, Space } from 'antd';
function DashCampuslist(props) {
  const Navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const auth=useAuth();
 
 function update(){
 
  Navigate('/newPage',{state:{campusID:props.data.About_Section_Contents.campusID,page:props.data,ROUTE:'update'}});
 }

function  Publish(){

  if(Cookies.get('role').match('SuperAdmin')){
    axios.post(`${BASE_URL}/api/publish-campus?`,
    {
      campusID:props.data.About_Section_Contents.campusID
    })
  .then(res=>{
      if(res.data?.status===200){
        auth.openMessage(res.data?.message,'success',1);   
  auth.APIloading();
                } else{        
                  auth.openMessage(res.data?.message,'error',2);    
                } 
             auth.SetVisible(!auth.visibleCMenu)
  }).catch(e=>{
    auth.openMessage(e.response.data.message,'error',2); 
    auth.SetVisible(!auth.visibleCMenu)
  })

  }else{
    axios.post(`${BASE_URL}/api/publish-request`,
    {
      campusID:props.data.About_Section_Contents.campusID
    })
  .then(res=>{
      if(res.data?.status===200){
        auth.openMessage(res.data?.message,'success',1);   
  auth.APIloading();
                } else{        
                  auth.openMessage(res.data?.message,'error',2);    
                } 
             auth.SetVisible(!auth.visibleCMenu)
  }).catch(e=>{
    auth.openMessage(e.response.data.message,'error',2); 
    auth.SetVisible(!auth.visibleCMenu)
  }) 
  }

 
 }

function Delete(id){
  axios.post(`${BASE_URL}/api/block-campus?campusID=${id}`)
  .then(function (response) {
// handle success   

    if(response.data?.status===200){           
      auth.openMessage(response.data?.message,'success',1)
      auth.APIloadingSuper();
      auth.APIloading();
    }else{
      auth.openMessage(response.data?.message,'error',1)
    }
  })
  .catch(function (error) {
    // handle error
    
    auth.openMessage(error.response.data.message,'error',1)
  })
  .finally(function () {
    // always executed
  });
}

const items = [
  {
    label: (
      <a  onClick={update} target="_blank" rel="noopener noreferrer" >
      Update
      </a>
    ),
    key: '0',
  },
  {
    label: (
      <a target="_blank" rel="noopener noreferrer" onClick={Publish} >
       Publish
      </a>
    ),
    key: '1',
  },
  {
    type: 'divider',
  },
  {
    label: (<a  className='' target="_blank" href={`/Demo/${props.data.Welcome_Section_Contents.campusID}`}>View Demo</a>
    ),
    key: '3',
    disabled:props.data.isPublished!=1? false:true,
  },
  {
    label:(<a  className='  ' target="_blank" href={`https://myfellow.et/campus/${props.data.URL}`}>View site</a>
    ),
    key: '4',
    disabled: props.data.isPublished!=0? false:true,
  },
  {
    label:(<a  className='  ' onClick={()=>Delete(props?.data?.Welcome_Section_Contents?.campusID)} target="_blank" rel="noopener noreferrer">Delete</a>
    ),
    key: '5', 
  },
];

  return (
    <div  className='dark:bg-[#525151]  dark:text-white dark:border-[1px] dark:rounded-md mb-8 ' >
      {auth.contextHolder}
        <div  >
            <div className='relative' >
            <a target='_blank' href={`/Demo/${props.data.Welcome_Section_Contents.campusID}`}>
              <img className='w-[290px] h-[181px]' src={`${BASE_URL}/`+props.data.Welcome_Section_Contents.image} alt={props.data.Welcome_Section_Contents.title+'image'} border="0"/>
        <div className=' shadow-md mt-[8px] w-[290px]'>
            <div className='ml-[5px] mr-[5px]'>
               <div className='flex items-center gap-2 dark:text-white text-[#9009CF] progress plus-600 text-[14px] lh-20 '>
               <p className='text-[10px]'>By {props.data.Owner}</p>
              <div className='flex items-center gap-2'>
                <div className='w-2 h-2 dark:text-white bg-[#9009CF] rounded-full'></div>
                <p className='text-[10px]'>{props.data.Campus_Creation_Date}</p>
              </div>
               </div>
<p className='text-start mt-[6px] text-[19px] font-plus dark:text-white text-black plus-700 lh-32 font-bold'>{props.data.Welcome_Section_Contents.fellowshipName
}</p>
<div onClick={ ()=> localStorage.setItem("Keyid",props.data.Welcome_Section_Contents.campusID)} className='flex  items-center pageCard   mt-[19px] '>
 

   
    <div  className='flex items-center absolute md:absolute top-0 md:top-0  md:right-0 right-0'>
    <Dropdown
    menu={{
      items,
    }}
  >
    <div onClick={(e) => e.preventDefault()}>
      <Space>
    <div  className='flex justify-center relative cursor-pointer' >
      <div className=' bg-purple-500  rounded-b-full py-1   flex  ' > 
      <button name='team' ><BsThreeDotsVertical className='text-white ' size={20}/></button>
       </div>
      </div> 
        
      </Space>
    </div>
  </Dropdown>
      {/* {auth.visibleCMenu&&

    <div className='flex flex-col gap-1  bg-white px-1 py-2 rounded-md  absolute top-2 ml-8   '>
      <a   className='  w-[100px] border bottom-1 px-2 rounded-md text-[#667085] dark:text-white dark:last:text-[#fff] last:text-[#007F6D]  cursor-pointer hover:font-bold text-[10px]' onClick={update}>Update site </a>
    <a className=' border w-[100px]   rounded-md text-[#667085] dark:text-white dark:last:text-[#fff] last:text-[#063730]  cursor-pointer hover:font-bold text-[10px] ' onClick={Publish}>Publish</a>
    {props.data.isPublished!=1?
<a  className='border  text-center w-[100px]  rounded-md text-[10px] text-[#667085] dark:text-white dark:last:text-[#fff] last:text-[#007F6D] hover:font-bold' target="_blank" href={`/Demo/${props.data.Welcome_Section_Contents.campusID}`}>View Demo</a>

:
<a  className='border w-[100px]   rounded-md text-[10px] text-[#667085] dark:text-white dark:last:text-[#fff] last:text-[#007F6D]  hover:font-bold' target="_blank" href={`https://etcampus.dsethiopia.org/campus/${props.data.URL}`}>View site</a>

}
<div className='  cursor-pointer' onClick={() => setShowModal(true)}>
      <p className='border  text-[10px] w-[100px]  rounded-md text-[#667085] last:text-red-500  hover:font-bold'>Delete</p>
      </div>    
      </div> 
    } */}
    </div>

</div>

            </div>

        </div>
            </a>
            </div>
        </div>
   
      {showModal && (
       <Modal setShowModal={() => setShowModal(false)}>
            <p className='mt-8'> Are you shure you want to delete this ?</p>
            <div className='flex gap-5 mt-10 mb-1 items- justify-end'>
            <button className='bg-purple-500 h-6 text-white w-16 rounded-sm' onClick={() => setShowModal(false)}>No</button>
            <button className='bg-red-500 h-6 text-white w-16 rounded-sm' onClick={()=>props.Delete(props.data.About_Section_Contents.campusID)}>Yes</button>
            </div>
       </Modal>
           
        
      )}
    
    </div>
  )
}

export default DashCampuslist