import React,{ useEffect, useRef }  from "react";
import Button from "../Component/Button";
import { useState } from "react";
import Css from '../Component/Css'
import { BASE_URL } from "../../Function/Base";
export default function Welcome(props) {
  const videoRef = useRef(null);
  const [data,setData]=useState(null)
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.autoplay = true;
      videoRef.current.muted = true;
      videoRef.current.controls = false;
    }
    setData(props.props.welcom)
  }, [videoRef]);
const DATA={
  Nav:[
  {List:'Home',hrf:'#Home'},
  {List:'About',hrf:'#About'},
  {List:'Services',hrf:'#Services'},
  {List:'Gallery',hrf:'#Gallery'},
  {List:'Contact',hrf:'#Contact'},
  ]
}
const [scroll, setScroll] = useState(false);
const [open ,setOpen]=useState(false);
const [active,setActive]=useState(0);
useEffect(() => {
  const handleScroll = () => {
    setScroll(window.pageYOffset > 0);
  };
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

  return (
    <section id="Home">
  <div id="Home">
<div className={Css.CSSTailwind.Hero.contenor}>
  {/* hero image section */}
<div className={` relative ${Css.CSSTailwind.Hero.Hero_Image_BackGround_Contener}`}>
<img  src={`${BASE_URL}/${props.props.image}`} className={` object-cover ${Css.CSSTailwind.Hero.Hero_Image_BackGround} `}alt="hero image" />
 <div className="heroGraent absolute top-0 lg:h-[100vh] h-[848px]  w-full">

 </div>
  </div>
{/* Nav section */}
<div className=" top-0 right-0 left-0 z-10  ">
  <div className="absolute top-0 w-full ">
  <div className={` lg:flex lg:items-center lg:justify-center lg:w-full `} >

<div className={` top-0 lg:flex lg:justify-center lg:items-center w-full lg:max-w-[1300px]   z-50 transition-all duration-500 ${open==true?'h-auto duration-500 transition-all':'h-[55px] md:h-[70px] lg:h-[80px] overflow-hidden duration-500 transition-all'} ${scroll ? '  lg:top-[-10px]  md:top-0 md:px-5  p-2 lg:p-0 text-white' : 'text-white'} ${Css.CSSTailwind.Hero.Nav}`}>
<div className={`transition-all duration-500  ${Css.CSSTailwind.Hero.Nav_Right}`}>
<div data-aos="fade-up" className={Css.CSSTailwind.Hero.Logo_Div}>
  <img src={`${BASE_URL}/${props.props.fellowshipLogo}`} className={Css.CSSTailwind.Hero.Logo} alt="" />

</div>
<div  className={Css.CSSTailwind.Hero.Name_Div}>
  <p className={Css.CSSTailwind.Hero.Short_Name}>{props.props?.fellowshipAcronym}</p>
  <p className={Css.CSSTailwind.Hero.Long_Name}>{props.props?.fellowshipName}</p>
</div>
</div>
<div className="flex flex-col justify-end items-end gap-4">
<svg onClick={()=>setOpen(!open)} className="block md:hidden  mt-2" width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
{open==false?
<path d="M0 0.609741H24V2.67287H0V0.609741ZM0 19.1779H24V21.241H0V19.1779ZM0 6.79913H24V8.86225H0V6.79913ZM0 12.9885H24V15.0516H0V12.9885Z" fill="white"/>
:<>
<path d="M4.92856 6.34317L17.6565 19.0711L19.0707 17.6569L6.34277 4.92896L4.92856 6.34317Z" fill="white"/>
<path d="M6.34292 19.071L19.0708 6.34303L17.6566 4.92882L4.92871 17.6567L6.34292 19.071Z" fill="white"/>

</>

}


</svg>

 <div className={`  flex flex-col gap-4 items-end block md:hidden  ${open===true?'transtion-x-0 duration-1000':'translate-x-full'}`}>
  {DATA.Nav?.map((data,index)=>(
  
<div onClick={()=>setOpen(!open)} key={index} className=''>
    <a className="cursor-pointer " href={data.hrf}>
<p className=''>{data.List} </p>

    </a>
</div>

  ))}
</div>

</div>
<div className={Css.CSSTailwind.Hero.Nav_Left}>
  {DATA.Nav?.map((data,index)=>(
  
<div  onClick={()=>setActive(index)} key={index} className={Css.CSSTailwind.Hero.Nav_List_Small}>
    <a  className={`cursor-pointer Poppins  `} href={data.hrf}>
<p className={`${active===index?'Int-700 text-[#6B1768]':'Int-400'} ${Css.CSSTailwind.Hero.Nave_Item}`}>{data.List} </p>
    </a>
</div>
  ))}
</div>
</div>
  </div>
  </div>
</div>
{/* Bottom_section */}
<div className="absolute top-[241px] md:top-[-136px]    md:bottom-[136px]  md:left-[40px] lg:top-[-130px] lg:left-0 lg:bottom-[130px] lg:h-screen md:h-[848px] lg:w-auto">
<div className="lg:w-[98vw] lg:h-full md:h-full md:flex  md:justify-end md:items-end lg:flex lg:justify-center  lg:items-end  ">
<div data-aos="fade-up" className={Css.CSSTailwind.Hero.Bottom_Section}> 
<div className={Css.CSSTailwind.Hero.BTop_Section}>
<p className={Css.CSSTailwind.Hero.Campus_Title}>Welcome to {props?.props?.universityName}</p>
<p className={Css.CSSTailwind.Hero.Campus_Name}>| {props?.props?.campusName}</p>
</div>
<p className={Css.CSSTailwind.Hero.Moto}>{props?.props?.moto}</p>
<div data-aos="fade-up" className="flex justify-center items-center md:justify-start lg:justify-start " style={{

}}>
{props.registerButton!=true?'':
<Button bxshadow={'0px 4px 20px rgba(0, 0, 0, 0.25)'} hrf='register' button={props?.props?.registerButtonText} ClassName={Css.CSSTailwind.Hero.HeroButton}></Button>
}
</div>

</div>
</div>
</div>


  </div>
   </div>
  </section>
  );
}

 