import React from 'react'
import { message, Upload } from 'antd';
function Uploads(props) {
  return (
<div className="w-[50%]  ">
<label name={props.name} className=" ">
    <div className="pt-7">
   {props.children}
    </div>
    <input  multiple value={props.value} type='file' name={props.name} id='file_id'  className="opacity-0 w-1" accept="image/*" required 
           onChange={props.onChange} />

</label>
</div>
  

      
   
  

  )
}

export default Uploads