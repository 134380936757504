import React from 'react'
import { useAuth } from '../Function/AuthProvider'
function Contener(props) {
  const auth=useAuth();
  return (
    <div >
    <div className={`${auth.isDarkMode?'dark dark:bg-[#000] dark:text-white ':''}`}>
    <div className= {`${props.expand===false? 'md:ml-[5.5%]   bg-[#F5F5F5] min-h-screen dark:bg-[#000] dark:text-white': 'md:ml-[17.5%]  md:mt-[60px]  bg-[#F5F5F5] min-h-screen dark:bg-[#000] dark:text-white' } `}>
    <div className='dark:bg-[#000] dark:text-white ml-[50px]  md:mt-[60px]  flex flex-col justify-start  rounded-[17px] mr-[24px]' >
        {props.children}
        </div>
        </div>
    </div>
    </div>
  )
}
export default Contener