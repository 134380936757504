import Cookies from 'js-cookie';
import React from 'react'
import { useState } from 'react';
import axios from 'axios';

import ProfileModal from './ProfileModal';
import { AiOutlineCamera } from 'react-icons/ai';
function Perofile(props) {
 return (
    <div className={` rounded-full overflow-hidden profile border-solid border-[11px] border-white ${props.style} ${props.width} ${props.hieght}`}>
<img className={`${props.image===null?'':`rounded-full ${props.mt}`}`}  src={props.image===null?props.imageAvater:props.image} alt=""/> 
<ProfileModal width='1000' setOpen={props.setOpen} open={props.open} title='Update Profile' Button='Update' cancel={props.button} Action={props.update} >
<div className={`w-[170px] h-[190px] rounded-full overflow-hidden profile border-solid border-[11px] border-white  `}>
<label name='image' className=''>
<img  className=' w-full h-full rounded-full'  src={props.images===null?props.imageAvater:props.images}  alt=""/> 
<input className='hidden' name='image' type='file' onChange={props.handleChange} />
</label>
</div>
<label name='image' className=''> 
<div className='flex py-1 flex gap-2 items-center cursor-pointer bg-[#9009CF] text-white px-1 rounded-md '>
  <AiOutlineCamera size={20}/>
 Change Profile Photo
</div>
<input className='hidden' name='image' type='file' onChange={props.handleChange}/>
</label>
</ProfileModal>
    </div>
  )
}

export default Perofile