import React from 'react'
import '../css/baseStyle.css'
import authback from '../Assets/carveImage.png'
import auth from '../Assets/yegna.png'
import carve from '../Assets/carve.png'
function AuthPage(props) {
  return (
    <div>
    <div className={`relative authBack  hidden md:block ${props.hidden && 'md:hidden'} `}>
    
    
    <div className='absolute flex right-0 top-0 '>
    <img className='w-full h-screen '  src={auth} alt="" />
    </div>
   
    
    <div className='absolute top-72 '>
   <div className='flex flex-col items-center mt-10 md:w-full mr-4 md:authtext text-white text-right mt-77 md:mr-48 font-plus-jakarta-sans font-bold '>
<h1 className=' text-[40px]  leading-[40px] md:text-[64px] md:leading-[64px] text-white '>
    Welcome  Back.
</h1>
<p className='text-[12px]  leading-1 md:text-[14px] md:leading-[20px] Poppins Popp-400 mt-5 font-plus'>
Please enter your credentials to access <br/>
 your account and continue where you <br/>
left off."
</p>
   </div>
    </div>
    </div>
{/* mt-[-2px] it push up t get roginal delete it */}

    {/* add children here  */}
   
    {/* left side text */}
    <div className='relative flex justify-end '>
    <div className='inline-block self-start w-[100%]  '>
      <div className=' md:ml-[100px] '>
    {props.children}
      </div>  
    </div>
     
    </div>
    
  </div>
  )
}

export default AuthPage