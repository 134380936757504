import React from 'react'

function SvgDash() {
  return (
    <svg width="250" height="190" viewBox="0 0 250 190" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="248" height="188" rx="8" fill="#D9D9D9" fill-opacity="0.13" stroke="#0AE720" stroke-width="2" stroke-dasharray="2 2"/>
    </svg>
  )
}

export default SvgDash