import React,{useEffect} from 'react'
import { BsTelephoneFill,BsTelegram,BsFacebook } from "react-icons/bs";
import { BASE_URL } from '../Function/Base';
const OurLeaderslider=({data,sliderRef,updateItem})=>{
    const renderComponent = () => {
        // Render the component.
        return (
          <div className="flex items-center keen-slider mt-20" ref={sliderRef}>
            {data.length}
            {data.map((data, index) => (
              <div className="keen-slider__slide flex items-start">
                <div key={data.id} onClick={() => updateItem('leader', data.id, data)} className="w-[270px] flex flex-col items-center justify-center">
                  <img className="rounded-full md:w-[180px] md:h-[180px] lg:w-[180px] lg:h-[170px] xl:w-[264px] xl:h-[264px]" src={`${BASE_URL}/${data.photo}`} alt="" />
                  <div className="flex flex-col items-center justify-center md:mt-[10px] xl:mt-[26px]">
                    <p className="Plus md:text-[18px] xl:text-[21px] md:leading-[22px] xl:leading-[32px] font-bold">{data.name}</p>
                    <p className="Plus md:text-[14px] xl:text-[16px] leading-[26px] font-[400]">{data.role}</p>
                  </div>
                  <div className="flex gap-3 justify-center items-center md:mt-[6px] xl:mt-[15px]">
                    <BsTelephoneFill style={{ color: "#6B1768" }} size={20} />
                    <BsTelegram style={{ color: "#6B1768" }} size={20} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      };
      let prevData = null;

    useEffect(() => {
        // sliderRef?.current?.forceUpdate();
        // Check if the props.data prop has changed.
        if (data !== prevData) {
          // Re-render the component.
          renderComponent();
        }
        // Store the current value of the props.data prop.
        prevData = data;
      }, [data]);
    
      // Render the component.
      renderComponent();
    return(
        <div className='flex items-center keen-slider mt-20' ref={sliderRef}>
 
   
       
        {data?.map((data,index)=>(
                
            <div  className='keen-slider__slide flex  items-start'>
         <div key={data.id} onClick={()=>updateItem('leader',data.id,data)} className='w-[270px] flex  flex-col items-center justify-center ' >
              <img  className='rounded-full md:w-[180px] md:h-[180px] lg:w-[180px] lg:h-[170px] xl:w-[264px] xl:h-[264px]  ' src={`${BASE_URL}/${data?.photo}`} alt="" />
              <div className='flex flex-col items-center justify-center md:mt-[10px] xl:mt-[26px] '>
                <p className='Plus md:text-[18px] xl:text-[21px] md:leading-[22px] xl:leading-[32px] font-bold'>{data?.name}</p>
                <p className='Plus md:text-[14px] xl:text-[16px] leading-[26px] font-[400] '>{data?.role}</p>
              </div>
              <div className='flex gap-3 justify-center items-center md:mt-[6px] xl:mt-[15px]'>
              <BsTelephoneFill style={{ color: '#6B1768' }}  size={20} />
            <BsTelegram style={{ color: '#6B1768' }} size={20}/>
              </div>
            </div>     
            </div>
                    //  </SwiperSlide> 
            ))}
        </div>        
    )
}
export default OurLeaderslider;