import React from 'react'

function StudentType(props) {
  return (
    <div className={`${props.isDarkMode?'dark':''} dark:bg-[#000] dark:text-white dark:border dark:border-r-1 bg-white w-full  h-[50%] studentCard rounded-[17px]  hover:bg-gradient-to-r hover:from-indigo-500 hover:from-10% hover:via-sky-500 hover:via-30% hover:to-emerald-500 hover:to-90% bg-gradient-to-r hover:text-white`}>
    <p className="cardTitle progress mt-[22px]  ml-[30px]  hover:text-white">
    {props.title}
    </p>
    <div className="flex items-center ml-[30px] mr-[24px] justify-between mt-[20px] mb-[10px]">
      <p className="Nostudent progress">{props.amount}</p>
      <div className={`rounded-[10px]  h-[22px] w-[47px] flex items-center justify-center ${props.percentBG}`}>
        <p className={`oldStudent progress  ${props.percentColor}`}>{props.percent}</p>
      </div>
    </div>
  </div>
 
  )
}

export default StudentType