import React, { useEffect, useState } from "react";
import MainPage from "../Component/MainPage";
import { Empty, Progress, Space } from "antd";
import StudentWithSex from "../Component/StudentWithSex";
import StudentType from "../Component/StudentType";
import Device from "../Component/Device";
import ProgressPage from "../Component/ProgressPage";
import SvgDash from "../Component/SvgDash";
import Perofile from "../Component/Perofile";
import owener from '../Assets/owener.png'
import DashCampuslist from '../Component/DashCampuslist'
import callCenter from '../Assets/callCenter.png'
import Button from "../Component/Button";
import { IoEllipsisVerticalSharp} from "react-icons/io5";
import MainRouter from "./MainRouter";
import moment from 'moment';
import { BASE_URL } from "../Function/Base";
import { Link, useParams } from "react-router-dom";
import { useAuth } from "../Function/AuthProvider";
import Table from "../Component/Table";
import Modal from "../Component/Modal";
import axios from "axios";
function Dashboard(props) {
 const [time,setTime]=useState()
  const currentYear = new Date().getFullYear();
const imageURL=BASE_URL;

const auth=useAuth()
const {campusID} = useParams();
  //auth.setDemo(campusID)
  const [tableshow,setTableShow]=useState({
    fullName:true,
    sex:true,
    Phone:true,
    City:true,
    church:false,
language:false,
Host:false,
show:false,
arrivalDate:false,
  });
  // Filter the array to include only students who registered on this year
 const [Thead,setThead]=useState([
  {title:'Full Name',value:tableshow.fullName},
  {title:'Sex',value:tableshow.sex},
  {title:'Phone Number',value:tableshow.Phone},
  {title:'City',value:tableshow.City},
  {title:'Church',value:tableshow.church},
  {title:'Language',value:tableshow.language},
  {title:'Date'},
  {title:'Host',value:tableshow.Host}
 ])

  const studentsOnThisYear = props.student!=null&&
    props.student.filter((student) => {
      const year = new Date(student.created_at).getFullYear();
      return year === currentYear;
    });
  
  
  // Get the count of students who registered on this year and their percentage
  const countOnThisYear = studentsOnThisYear.length;
  const percentageOnThisYear =props.student!=null&& (countOnThisYear / props.student.length) * 100;
// Filter the array to include only male students
const maleStudents = props.student!=null && props.student.filter((student) => {
  return student.sex === "male";
});
// Filter the array to include only female students
const femaleStudents =props.student!=null&& props.student.filter((student) => {
  return student.sex === "memale";
});
// Get the count of male students and their percentage
const maleCount = maleStudents.length;
const malePercentage =props.student!=null&& (maleCount / props.student.length) * 100;

// Get the count of female students and their percentage
const femaleCount = femaleStudents.length;
const femalePercentage =props.student!=null&& (femaleCount / props.student.length) * 100;
  const newStudent=countOnThisYear;
  const oldStudent=props.student!=null&&props.student.length-countOnThisYear;
  
const startDate = moment().startOf('week');
const endDate = moment().endOf('week');

 const filteredData = props.student!=null&&props.student.filter(item => {
  const itemDate = moment(item.arrivalDate);
  return itemDate.isSameOrAfter(startDate) && itemDate.isSameOrBefore(endDate);
});
useEffect(()=>{
  setTime(moment().format('MMMM Do YYYY, h:mm:ss a'))
},[])
useEffect(()=>{
  auth.setDemo(campusID)
},[campusID]);

const newuser=percentageOnThisYear>100?'-'+percentageOnThisYear:percentageOnThisYear;
const olduser=percentageOnThisYear>100?'-'+ 100-percentageOnThisYear:100- percentageOnThisYear
  return (
    <div className="h-screen overflow-y">

    {props.admin===null?
     <div className={`${props.isDarkMode?'dark dark:bg-[#000] dark:text-white  h-screen':'bg-[#F5F5F5] h-screen '}`}>

     <div className={`dark:bg-[#000] dark:text-white ${props.expand===false?'md:ml-[5.5%]  ': 'md:ml-[17.5%]  ' }`}>
  
       <div className=" dark:bg-[rgb(0,0,0)] dark:text-white h-screen overflow-hidden md:ml-[50px] flex flex-col items-center md:flex-col md:flex-wrap md:gap-[20px]  ">  
      
<div className="flex flex-col items-center md:flex-row lg:flex-col overflow-y  h-screen   md:gap-[20px] justify-center md:ml-[50px]">
<span className="dark:bg-[#000]  dark:text-white  text-black  text-center mt-[300px]  ">
    Select your campus  or create your one   
   </span>
   
   <div className='grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 justify-center gap-5  mr-4 '>

    {
      props.compusData?.Active_Campuses?.map((data,index)=>(
        <DashCampuslist data={data}/>
      ))
    }
    </div>
</div>
    
    </div>
    </div>
    </div>
     :props.admin.length!=0?
  <div className={`${props.isDarkMode?'dark dark:bg-[#000] dark:text-white':'bg-[#F5F5F5]'}`}>

    <div className={`dark:bg-[#000] dark:text-white ${props.expand===false?'md:ml-[5.5%]  ': 'md:ml-[17.5%]  ' }`}>
     
      <div className=" dark:bg-[#000] dark:text-white lg:ml-[50px] md:ml-[55px] flex flex-col items-center lg:flex-row md:flex-wrap md:gap-[20px] ">
      
        <div className="mt-[100px] w-full lg:w-[48%] md:w-[80%] flex flex-col items-center md:flex-row gap-8">
         <StudentWithSex  isDarkMode={props.isDarkMode} total={props?.dash?.allTimeStudentData?.totalStudents} male={props?.dash?.allTimeStudentData?.maleStudentsPercentage} m={props?.dash?.allTimeStudentData?.maleStudents} f={props?.dash?.allTimeStudentData?.femaleStudents} female={props?.dash?.allTimeStudentData?.femaleStudentsPercentage} />
          <div className="dark:bg-[#000] dark:text-white w-[60%] flex flex-col h-full space-y-[29px]">
          <StudentType isDarkMode={props.isDarkMode} title='New Students' amount={props?.dash?.newStudents} percent={props?.dash?.newStudentsPercentage} percentBG='bg-[#B0E4C9]' percentColor='text-[#006531]'/>
          <StudentType isDarkMode={props.isDarkMode} title='Old Students' amount={props.dash?.oldStudents} percent={props?.dash?.oldStudentsPercentage} percentBG='bg-[#FDD9D9]' percentColor='text-[#BF0141]'/>
           
            {/* end of card */}
          </div>
        </div>
        {props.dash!=null&&props.dash!=''?
        
        <div className="dark:bg-[#000] dark:text-white lg:w-[48%] md:w-[80%]  mt-[100px] flex  flex-col md:flex-col md:flex-row ">
        <div className='flex  justify-between mx-5 lg:mx-0 gap-4 lg:gap-0 '>
        <div className=" mt-[10px] mb-4">
              <h1 className="plus-400 lh-18 progress text-[14px] dark:text-white">Analytics</h1>
              <p className="progress plus-700 lh-14 text-[8px] text-[#a008ec]">{time}</p>         
            </div>
            {/* <div className=" mt-[10px] mb-4">
              <h1 className="plus-400 lh-18 progress text-[14px] dark:text-white">Contact Us?</h1>
              <p className="progress plus-700 lh-14 text-[8px]text-[#a008ec]">+251986339852/+251925679877</p>         
            </div> */}
          </div>
          <div className="dark:bg-[#000] dark:text-white relative w-full flex  gap-4 justify-between  rounded-[17px]">
         
          <StudentType isDarkMode={props.isDarkMode} title='Hosted Students' amount={props.dash.studentData.hostedStudents} percent={props.dash.studentData.hostedStudentsPercentage!='No data available'?props.dash.studentData.hostedStudentsPercentage:''} percentBG='bg-[#B0E4C9] ' percentColor='text-[#006531]'/>
          <StudentType isDarkMode={props.isDarkMode} title='not Hosted Students' amount={props.dash.studentData.notHostedStudents} percent={props.dash.studentData.notHostedStudentsPercentage!='No data available'?props.dash.studentData.notHostedStudentsPercentage:''} percentBG='bg-[#FDD9D9]' percentColor='text-[#BF0141]'/>
       
          </div>
          <div className=" w-full text-white hover:bg-gradient-to-r hover:from-indigo-500 hover:from-10% hover:via-sky-500 hover:via-30% hover:to-emerald-500 hover:to-90% bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 mt-5 rounded-[20px] h-auto flex gap-5 justify-between p-8">
<div>
  <p className="text-md ">Total Students</p>
  <p className="text-xl font-bold font-plus mt-2">{props.dash.studentData.totalStudents}</p>
</div>
<div className="">
  <p>Total Services</p>
  <p className="text-xl font-bold font-plus mt-2" >{props.dash.servicesData.totalServices}</p>
</div>
</div>
        </div>
        :''}
      </div>
      <div className="dark:border dark:border-r-1 dark:hover:text-white dark:bg-[#000] dark:text-white hover:text-white hover:bg-gradient-to-r hover:from-indigo-500 hover:from-10% hover:via-sky-500 hover:via-30% hover:to-emerald-500 hover:to-90%  mt-[20px] ml-[50px] flex flex-wrap justify-center bg-white rounded-[8px] w-[93%] ">
      <div className=" hidden md:block mb-[30.98px]">
      <p className="text-center text-xl font-bold mt-[35px]">Admin list</p>
      <div className="   flex justify-center items-center " >
{props.admin.map((data,index)=>(
  <div key={index}>
    {index===0?
      <Perofile mt='object-fit h-[118.99px] rounded-lg' style='dark:bg-[#000] dark:text-white bg-[#E2E8F0] translate-x-20 leaderGroup first:mt-10 last:mt-10 w-[118.99px] h-[118.99px] first:w-[139.73px] last:w-[139.73px] first:h-[139.73px] last:h-[139.73px]' image={data.profilePicture!=null&&data.profilePicture!=""? `${BASE_URL}/`+data.profilePicture:owener}  posetion='' />      
   :index===1?
      <Perofile mt=' object-fit h-[118.99px] rounded-lg' style='dark:bg-[#000] dark:text-white bg-[#E2E8F0] translate-x-14 leaderGroup first:mt-10 last:mt-10 w-[118.99px] h-[118.99px] first:w-[139.73px] last:w-[139.73px] first:h-[139.73px] last:h-[139.73px]' image={data.profilePicture!=null&&data.profilePicture!=""? `${BASE_URL}/`+data.profilePicture:owener}  posetion='' />
   :index===2?
      <Perofile mt='object-fit h-[118.99px] rounded-lg' style='dark:bg-[#000] dark:text-white bg-[#E2E8F0] translate-x-9 leaderGroup first:mt-10 last:mt-10 w-[118.99px] h-[118.99px] first:w-[139.73px] last:w-[139.73px] first:h-[139.73px] last:h-[139.73px]' image={data.profilePicture!=null&&data.profilePicture!=""? `${BASE_URL}/`+data.profilePicture:owener}  posetion='' />
   :index===3?
      <Perofile mt='object-fit h-[118.99px] rounded-lg' style='dark:bg-[#000] dark:text-white bg-[#FDBA74]  z-50 leaderGroup first:mt-10 last:mt-10' image={data.profilePicture!=null&&data.profilePicture!=""? `${BASE_URL}/`+data.profilePicture:owener} width='w-[145.5px]' height='h-[145.5px]' posetion='' />  
   :index===4?
    <Perofile mt='object-fit h-[118.99px] rounded-lg' style='dark:bg-[#000] dark:text-white bg-[#E2E8F0] -translate-x-6 leaderGroup first:mt-10 last:mt-10 w-[118.99px] h-[118.99px] first:w-[139.73px] last:w-[139.73px] first:h-[139.73px] last:h-[139.73px]' image={data.profilePicture!=null&&data.profilePicture!=""? `${BASE_URL}/`+data.profilePicture:owener}  posetion='' />
   :index===5?
   <Perofile mt='object-fit h-[118.99px] rounded-lg' style='dark:bg-[#000] dark:text-white bg-[#E2E8F0] -translate-x-16 leaderGroup first:mt-10 last:mt-10 w-[118.99px] h-[118.99px] first:w-[139.73px] last:w-[139.73px] first:h-[139.73px] last:h-[139.73px]' image={data.profilePicture!=null&&data.profilePicture!=""? `${BASE_URL}/`+data.profilePicture:owener}  posetion='' />  
   :index===6?
      <Perofile mt='object-fit h-[118.99px] rounded-lg' style='dark:bg-[#000] dark:text-white bg-[#E2E8F0] -translate-x-24 leaderGroup first:mt-10 w-[118.99px] h-[118.99px] first:w-[139.73px] last:w-[139.73px] first:h-[139.73px] last:h-[139.73px] last:mt-10' image={data.profilePicture!=null&&data.profilePicture!=""? `${BASE_URL}/`+data.profilePicture:owener}  posetion='' />   
   :""}
 
  </div>
 ))} 
      </div>
      </div>
      </div>
      {tableshow.show && (
<Modal setShowModal={
  () => setTableShow({...tableshow,show:!tableshow.show})
  }>
  <div className='flex flex-col space-y-4 p-5'>
    <p>Select You want to see</p>
<div className="flex items-center">
  <input id="checkbox" type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" onChange={(e)=>setTableShow({...tableshow,City:!tableshow.City})} checked={tableshow.City}/>
  <label for="checkbox" className="ml-2 block text-gray-700">
    City
  </label>
</div>
{/*  */}
<div className="flex items-center">
  <input id="checkbox" type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" onChange={(e)=>setTableShow({...tableshow,fullName:!tableshow.fullName})} checked={tableshow.fullName}/>
  <label for="checkbox" className="ml-2 block text-gray-700">
    Name
  </label>
</div>
{/*  */}
<div className="flex items-center">
  <input id="checkbox" type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" onChange={(e)=>setTableShow({...tableshow,language:!tableshow.language})} checked={tableshow.language}/>
  <label for="checkbox" className="ml-2 block text-gray-700">
    Language
  </label>
</div>
{/*  */}
<div className="flex items-center">
  <input id="checkbox" type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" onChange={(e)=>setTableShow({...tableshow,church:!tableshow.church})} checked={tableshow.church}/>
  <label for="checkbox" className="ml-2 block text-gray-700">
   Church
  </label>
</div>
{/*  */}
<div className="flex items-center">
  <input id="checkbox" type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" onChange={(e)=>setTableShow({...tableshow,Host:!tableshow.Host})} checked={tableshow.Host}/>
  <label for="checkbox" className="ml-2 block text-gray-700">
   Host
  </label>
</div>
{/*  */}
<div className="flex items-center">
  <input id="checkbox" type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" onChange={(e)=>setTableShow({...tableshow,sex:!tableshow.sex})} checked={tableshow.sex}/>
  <label for="checkbox" className="ml-2 block text-gray-700">
   Sex
  </label>
</div>
{/*  */}
<div className="flex items-center">
  <input id="checkbox" type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" onChange={(e)=>setTableShow({...tableshow,Phone:!tableshow.Phone })} checked={tableshow.Phone}/>
  <label for="checkbox" className="ml-2 block text-gray-700">
   Phone
  </label>
</div>
{/*  */}

<div className="flex items-center">
  <input id="checkbox" type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" onChange={(e)=>setTableShow({...tableshow,arrivalDate:!tableshow.arrivalDate })} checked={tableshow.arrivalDate}/>
  <label for="checkbox" className="ml-2 block text-gray-700">
  arrivalDate
  </label>
</div>
{/*  */}
  </div>
</Modal>
     )}
      <div className=" dark:bg-[#000] dark:text-white mt-[20px]  ml-[20px] md:ml-[50px]    flex flex-wrap    md:w-[93%] ">
        <div className="dark:bg-[#000] dark:text-white flex gap-[2%] w-full justify-end mb-[16px] ">
        <div className="dark:bg-[#000] dark:text-white dark:border dark:border-r-1 dark:hover:text-white hover:text-white hover:bg-gradient-to-r hover:from-indigo-500 hover:from-10% hover:via-sky-500 hover:via-30% hover:to-emerald-500 hover:to-90% w-[100%]  bg-white rounded-[8px]">
            <div className=" ml-[20px] md:ml-[37px] mt-[17px]">
            <p className="progres plus-400 lh-18 text-[14px]">student arriving this week</p>
            <div className="mt-[15px] mr-[59px] lh-0">
              <Table Thead={Thead} currentItems={filteredData} setTableShow={setTableShow} tableshow={tableshow} />
                {/* <table className="w-full border-collapse arival">
                    <thead className="heading">
                        <th></th>
                        <td className="title text-[16px] md:text-md">Full Name</td>
                        <td  className="title text-[16px] md:text-md">Phone number</td>
                        <td  className="title text-[16px] md:text-md">Date</td>
                        <td  className="title text-[16px] md:text-md">Host</td>
                    </thead >
                    <tbody >
                      {filteredData.length>0&&filteredData.map((data,index)=>(

<tr key={index} >
                        <td >
                            <div className="m-1 w-[30px] h-[30px]  bg-[#FFA8A7] rounded-full flex justify-center  items-center">
<p className="progres plus-700 lh-15 text-[12px] text-white">{data.firstName.charAt(0)+ data.lastName.charAt(0)}</p>
                            </div>

                        </td>
                        <td>
                      
                          <p className="text-[8px] md:text-lg">{data.firstName+''+data.lastName}</p>
                         

                        </td>
                        <td>
                       
                            <p className="text-[8px] md:text-lg">{data.phone}</p>
                            
                        </td>
                        <td>
                           
                            <p className="text-[8px] md:text-lg">{data.arrivalDate}</p>
                          
                          
                        </td>
                    <td>
                    <div className="flex  justify-between  items-center">
                       <div className="flex gap-2 ">
                       <div className={`w-[10px] h-[10px] ${data.isHostAvailable!=1? 'bg-[#FFA8A7]':'bg-[#4CDE27]'}  rounded-full m-2`}></div>
                        <p className="text-[8px] md:text-lg" >{data.isHostAvailable!=1?'No Host':'Has Host'}</p>
                       </div>
                     <div className="flex justify-between items-center ">
                   
                        <IoEllipsisVerticalSharp color="#D9D9D9" size={30}/>
                     </div>
                       </div>
                    </td>
                        </tr>

                      ))}
                        
                      
                       
                    </tbody>
                </table> */}
            </div>
            </div>

<div>

</div>
        </div>
        {/* <div className="dark:border dark:border-r-1 dark:hover:text-white dark:bg-[#000] dark:text-white bg-[#D7E4FF]  hidden md:block w-[30%]  text-center rounded-[8px]">
            <div className="dark:bg-[#000] dark:text-white flex justify-center  ">
            <img className="mt-0" src={callCenter} alt="" />
            </div>
         
<p className="mt-[21.21px] mb-[16px]">Help Center</p>

<div className="ml-[35px] mr-[35px] ">
<Button  style='h-[51.14px] w-full    mb-[12px] hover:text-white hover:bg-gradient-to-r hover:from-indigo-500 hover:from-10% hover:via-sky-500 hover:via-30% hover:to-emerald-500 hover:to-90%' name='Contact Now!'>
  <p>Contact us! <br/>+251-90-977-9292</p>
</Button>
</div>
        </div> */}

        </div>
      </div>
    </div>
  </div>
  :
  
<div role="status" className="dark:bg-[#000] dark:text-white animate-pulse md:mt-[100px]">
<div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[640px] mb-2.5 mx-auto"></div>
<div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[640px] mb-2.5 mx-auto"></div>

<div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[640px] mb-2.5 mx-auto"></div>
<div className="h-2.5 mx-auto bg-gray-300 rounded-full dark:bg-gray-700 max-w-[540px]"></div>
<div className="flex items-center justify-center mt-4">
    <svg className="w-10 h-10 mr-2 text-gray-200 dark:text-gray-700" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd"></path></svg>
    <svg className="w-10 h-10 mr-2 text-gray-200 dark:text-gray-700" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd"></path></svg>
    <svg className="w-10 h-10 mr-2 text-gray-200 dark:text-gray-700" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd"></path></svg>
    <svg className="w-10 h-10 mr-2 text-gray-200 dark:text-gray-700" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd"></path></svg>   
</div>
<div className="flex items-center justify-around  mx-auto">
        <div>
           <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
    </div>
<span className="sr-only">Loading...</span>
</div>

                      }
                      </div>
  );
}

export default Dashboard;
