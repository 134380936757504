import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import Button from '../Component/Button'
import Contener from '../Component/Contener'
import CreatePage from '../Component/CreatePage'
import MainRouter from './MainRouter'
import { BASE_URL } from '../Function/Base'
import { useAuth } from '../Function/AuthProvider'
import { useParams } from 'react-router-dom'

function Content(props) {
  const [hasPage,setHasPage]=useState(false)
  const auth=useAuth();
  const [deletestatus,SetdeletStates]=useState(false)
 
  const [data,SetData]=useState()
  const {campusID} = useParams();
  axios.defaults.headers.common['Authorization'] = `Bearer ${auth.token}`;
  
 useEffect(()=>{
  auth.setDemo(campusID)
 },[campusID])
  useEffect(() => {
   
    const filteredContents =props.compusData!=null&& props.compusData?.Active_Campuses?.filter(content => content.Welcome_Section_Contents.campusID ==auth.selectCAMPUS);
      const titles =props.compusData!=null &&  filteredContents?.map(content => content.Welcome_Section_Contents.campusName        );
     
      SetData(filteredContents);
  
  }, [deletestatus,auth.apiload,Cookies.get('campus'),props.compusData]);
 function Delete(ID){
    axios.delete(`${BASE_URL}/api/delete-campus?campusID=${ID}`)
  .then(res=>{
      if(res?.data.status===200){
        auth.openMessage(res.data?.message,'success',1); 
        SetdeletStates(!deletestatus)   
        auth.APIloading();        
                } else if(res?.data.status===429)  {        
                 // auth.openMessage(res.data?.message,'error',2);   
                 auth.SetVisible(!auth.visibleCMenu)
                } else{
                  auth.openMessage(res.data?.message,'error',2);   
                  auth.SetVisible(!auth.visibleCMenu)
                } 
               
  }).catch(e=>{
    auth.openMessage(e.response.data.message,'error',2);  
  
  })
   }
  return (
    <div className=' h-screen overflow-y'>
  <Contener expand={props.expand}>
    {auth.contextHolder}
    <CreatePage Delete={Delete} contextHolder={props.contextHolder} ALLNotification={props.ALLNotification} datas={data} hasPage={hasPage}/>
</Contener>
   </div>
  )
}

export default Content