import React from 'react'
import SideNave from './SideNave'
import TopNav from './TopNav'

export default function MainPage(props) {
  return (
    <div>
        <div className='flex bg-[#F5F5F5] '>
            <div className='w-[20%]'>
            <SideNave/>
            </div>
         <div className='w-[100%]'>
         <TopNav/>
          <div>
            {props.children}
          </div>
         </div>
         
        </div>
    </div>
  )
}
