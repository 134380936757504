import React, { useState } from 'react'
import ReactPaginate from 'react-paginate';
function PaginatedItems(props) {
   // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  // Invoke when user click to request another page.\
 
  const handlePageClick = (event) => {
    const newOffset = (event.selected * props?.itemsPerPage) % props?.items?.length;
    props?.setItemOffset(newOffset);
  };
  return (
    <div className=' md:w-[85%]'>
        <ReactPaginate
        className='flex gap-8 justify-center items-center'
            breakLabel="..."
            nextLabel="Next "
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={props.pageCount}
            marginPagesDisplayed={1}
            previousLabel={`Previous`}
            renderOnZeroPageCount={null}
            containerClassName='w-full'
            activeClassName='bg-[#F9F5FF] rounded-[8px] w-[40px] h-[40px] flex justify-center items-center text-[#7F56D9]'
            previousClassName='text-[14px] font-medium font-plus plus-500 lh-20 border border-grey-300  rounded-[8px] w-[86px] h-[36px]  items-center flex justify-center'
            nextClassName='text-[14px]  font-plus plus-500 lh-20 border border-grey-300 rounded-[8px] w-[86px] h-[36px] items-center flex justify-center'
          />

    </div>
  )
}

export default PaginatedItems;