import axios from 'axios';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import Button from './Button'
import Input from './Input'
import Cookies from 'js-cookie'
import { BASE_URL } from '../Function/Base';
import { useAuth } from '../Function/AuthProvider';
function Password(props) {
  const auth=useAuth()
  const [formData,setFormData]=useState({
    oldPassword:null,
    newPassword:null,
    confirmPassword:false,
  });
  const [visible,setVisible]=useState(false)
function handleSubmit(e){
   e.preventDefault();
setFormData({...formData,[e.target.name]:e.target.value})
}

axios.defaults.headers.common['Authorization'] = `Bearer ${auth.token}`;
const url=`${BASE_URL}/api/changepassword?`
  const update=async()=>{
  if(formData.oldPassword!=null&&formData.oldPassword!=''&&formData.newPassword!=null&&formData.newPassword!=''&&formData.confirmPassword!=null&&formData.confirmPassword!=''){

  
    try {
      const response = await axios.post(url,
        {
          oldPassword:formData.oldPassword,
          newPassword:formData.newPassword,
          confirmPassword:formData.confirmPassword,
          token:auth.token,
        });
                       
      if(response?.data.status===200){
        auth.openMessage(response.data.message,'success',1)
       
            }else{
              auth.openMessage(response.data.message,'error',1)
       
            }
    } catch (error) {
      auth.openMessage(error,'error',1)
    }
  }else{
    auth.openMessage('All fields required!','error',1)
         
  }
  }

 
  
  function showPassword() {
    setVisible(!visible)
    const passwordInput = document.querySelector('#password');
  const passwordconfirm=document.querySelector('#Confrimpassword');
  const passwordold=document.querySelector('#oldpassword');
    const type = passwordInput.getAttribute('type') === 'password' ? 'text' : 'password';
    passwordInput.setAttribute('type', type);
   
    const secondType=passwordconfirm.getAttribute('type') === 'password' ? 'text' : 'password';
    passwordconfirm.setAttribute('type', secondType);
   
    const therdType=passwordold.getAttribute('type') === 'password' ? 'text' : 'password';
    passwordold.setAttribute('type', therdType);
  }
  
 
  return (
    <div className='dark:bg-black'>
        <div>
         <Input onChange={handleSubmit} id='oldpassword' type='password' name='oldPassword' placeholder="old password"  lable='Old password'  style={`text-[14px] lh-0 w-full h-[40px] mt-[-7px] mb-[0px] border dark:text-black ${auth.errorInput&&auth.errorInput==='Old Password incorrect!'||auth.errorInput==='All fields required!'?'border-pink-500':''}`} lableStyle='lh-0 font-bold w-full ' /> 
     <div className='relative'>
      <Input onChange={handleSubmit} id='password' name='newPassword' type='password' placeholder="********"  lable='New Password'  style={`text-[14px] lh-0 w-full h-[40px] mt-[-7px] mb-[0px] border dark:text-black ${auth.errorInput&&auth.errorInput==='New Password and Confirm Password do not match!'||auth.errorInput==='All fields required!'?'border-pink-500':''}`} lableStyle='lh-0 font-bold w-full ' />
      
     
     </div>
      <Input  onChange={handleSubmit} id='Confrimpassword' name='confirmPassword' type='password' placeholder="*********"  lable='Confirm Password'  style={`text-[14px] lh-0 w-full h-[40px] mt-[-7px] mb-[0px] border dark:text-black ${auth.errorInput&&auth.errorInput==='New Password and Confirm Password do not match!'||auth.errorInput==='All fields required!'?'border-pink-500':''}`} lableStyle='lh-0 font-bold w-full ' />
       </div>
       <div className=' flex gap-4 items-center mb-4'>
     
     <input
         type="checkbox"
         checked={visible}
         onChange={showPassword}
       />
      <label htmlFor="password" className=' text-[12px]'>Show Password</label>
   
     </div>
       <Button name='update' handlclick={update} style='bg-[#8E98A8] w-32 h-[40px] mb-[32px] mt-[66px]'>Update</Button>
        </div>
  )
}

export default Password