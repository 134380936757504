import React from 'react'
import rank from '../Assets/SuperAdmin/rank.png'
function RankCard(props) {
  return (
    <div className='flex flex-col justify-center items-start '>
     
<div className='flex gap-4'> 
{props.children}
</div>
    </div>
  )
}

export default RankCard