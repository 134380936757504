import React, { useEffect, useState } from 'react'
import Contener from '../Component/Contener'
import horizon from '../Assets/horizon.png'
import Perofile from '../Component/Perofile'
import owener from '../Assets/owener.png'
import Password from '../Component/Password'
import AccountSetting from '../Component/AccountSetting'
import MainRouter from './MainRouter'
import axios from 'axios'
import Cookies from 'js-cookie'
import { toast } from "react-toastify";
import { BASE_URL } from '../Function/Base'
import { useAuth } from '../Function/AuthProvider'
import { useParams } from 'react-router-dom'

function Profile(props) {
  
const auth=useAuth()
    const [open,setOpen]=useState(false)
    const [slide,setSlide]=useState(true)
  
const {campusID} = useParams();

useEffect(()=>{
  auth.setDemo(campusID)
},[campusID])

    function sliding(){
        setSlide(!slide);
    }
    function modal(){
      
        setOpen(true)
    }

const Delete=()=>{
    setOpen(false)
    //toast("'user deleted'");
  }
//console.log(props.PROFILE)
  const [formData,setFormData]=useState({
    image:null,
    imageFile:null,
    PROFILE:props.PROFILE!=null&&props.PROFILE!='undefined'?`${BASE_URL}/${props.PROFILE.profilePicture}`:null
  });
  const handleChange = (e) => {
    setFormData({ ...formData,PROFILE:URL.createObjectURL(e.target.files[0]), [e.target.name+'File']:e.target.files[0] });
  
};
  const url=`${BASE_URL}/api/change-profile-picture`
 
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  const update=async()=>{

    axios.defaults.headers.common['Authorization'] = `Bearer ${auth.token}`;
   const formDatas =new FormData();
   formDatas.append('image', formData.imageFile);
   formDatas.append('token', auth.token);
   auth.openMessage('please wait just a minute!','info',1)
      const response = await axios.post(url,formDatas,config);
                     
      if(response&&response.data&&response?.data.status===200){
        auth.openMessage(response?.data.message,'success',1)
        setOpen(false)
       props.ProfileUPDATE(response.data.profilePicture)
    
       auth.APIloading();
            }else{
              auth.openMessage(response?.message,'error',1)
            }
   
  }


  return (
    <Contener  expand={props.expand}>
      {auth.contextHolder}
       <div className=' mt-[20px]  dark:bg-black'> 
<img className='w-full' src={horizon} alt="" />
<div className='mb-[10%] flex flex-col md:flex-row md:justify-center gap-8  mt-[-101px] dark:bg-black ' >
    <div className=' bg-white md:w-[30%] dark:bg-black dark:border-[1px]'>
<div className='flex justify-center'>
    <div className='relative'>
<Perofile  open={open} handleChange={handleChange} button={Delete} update={update} setOpen={setOpen} 
 style='border-[#F6E6F6]   mt-10 ' mt=' object-fit h-[118.99px] rounded-lg' imageAvater={owener}  image={props.PROFILE!=null&&props.PROFILE!='undefined'&&props.PROFILE!=''?`${BASE_URL}/${props.PROFILE.profilePicture}`:null} images={formData.PROFILE!=null&&formData.PROFILE!='undefined'&&formData.PROFILE!=''?formData.PROFILE:null} width='w-[145.5px]' height='h-[145.5px]' posetion='' />
    <div onClick={modal} className='absolute bottom-0 mb-[13px] mr-[8px]  right-0 flex justify-center items-end  '>
    <svg  width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="15" cy="15" r="15" fill="white"/>
<path d="M8.33366 22.5C7.87533 22.5 7.4831 22.337 7.15699 22.0109C6.83033 21.6842 6.66699 21.2917 6.66699 20.8334V10.8334C6.66699 10.375 6.83033 9.98282 7.15699 9.65671C7.4831 9.33004 7.87533 9.16671 8.33366 9.16671H10.9587L12.5003 7.50004H17.5003V10C17.9587 10 18.3512 10.1634 18.6778 10.49C19.0039 10.8162 19.167 11.2084 19.167 11.6667H23.3337V20.8334C23.3337 21.2917 23.1706 21.6842 22.8445 22.0109C22.5178 22.337 22.1253 22.5 21.667 22.5H8.33366ZM15.0003 19.5834C16.042 19.5834 16.9275 19.2189 17.657 18.49C18.3859 17.7606 18.7503 16.875 18.7503 15.8334C18.7503 14.7917 18.3859 13.9062 17.657 13.1767C16.9275 12.4478 16.042 12.0834 15.0003 12.0834C13.9587 12.0834 13.0731 12.4478 12.3437 13.1767C11.6148 13.9062 11.2503 14.7917 11.2503 15.8334C11.2503 16.875 11.6148 17.7606 12.3437 18.49C13.0731 19.2189 13.9587 19.5834 15.0003 19.5834ZM15.0003 17.9167C14.417 17.9167 13.9239 17.7153 13.5212 17.3125C13.1184 16.9098 12.917 16.4167 12.917 15.8334C12.917 15.25 13.1184 14.757 13.5212 14.3542C13.9239 13.9514 14.417 13.75 15.0003 13.75C15.5837 13.75 16.0767 13.9514 16.4795 14.3542C16.8823 14.757 17.0837 15.25 17.0837 15.8334C17.0837 16.4167 16.8823 16.9098 16.4795 17.3125C16.0767 17.7153 15.5837 17.9167 15.0003 17.9167ZM22.2087 10.8334C22.2087 9.75004 21.8337 8.83337 21.0837 8.08337C20.3337 7.33337 19.417 6.95837 18.3337 6.95837V5.83337C19.7225 5.83337 20.9031 6.31949 21.8753 7.29171C22.8475 8.26393 23.3337 9.44449 23.3337 10.8334H22.2087ZM20.0003 10.8334C20.0003 10.375 19.8373 9.98282 19.5112 9.65671C19.1845 9.33004 18.792 9.16671 18.3337 9.16671V8.04171C19.0975 8.04171 19.7467 8.31254 20.2812 8.85421C20.8162 9.39587 21.0975 10.0556 21.1253 10.8334H20.0003Z" fill="#9009CF"/>
</svg>
    </div>
    </div>
    
</div>
<div className='flex flex-col justify-center items-center '>
   <p className='font-plus text-[14px] font-bold'>{props.PROFILE&&props.PROFILE.firstName!=null?props.PROFILE.firstName+' '+props.PROFILE.lastName:''}</p>
    <p className='font-plus text-[14px]'>{props.PROFILE&&props.PROFILE.role!=null?props.PROFILE.role:''}</p>
</div>
<div className='flex flex-col  justify-center mt-[48px]'>
<hr />
    <div className=''>
   
    <p className='ml-[45px] p-3 font-plus  text-[14px] h-[45px]'>Email: <span className='text-[14px font-plus font-w-[400]'>{props.PROFILE&&props.PROFILE.email!=null?props.PROFILE.email:''}</span></p>
    <hr />
    <p className='ml-[45px] p-3 font-plus  text-[14px] font-plus  text-[14px] h-[45px]'>Phone: <span className='text-[14px font-plus font-w-[400]'>{props.PROFILE&&props.PROFILE.phone!=null?props.PROFILE.phone:''}</span></p>
    <hr />
    {/* <p className='ml-[45px] p-3  font-plus  text-[14px] h-[45px]'>Campus: <span className='text-[14px font-plus font-w-[400]'>Arbaminch main campus</span></p>
    <hr className='mb-[153px]' /> */}
    </div>
</div>
    </div>
    <div className='bg-white dark:bg-black dark:border-[1px]'>
        <div className='flex gap-[35px] h-[49px] ml-[45px] items-end mb-[11px]'>
 <div onClick={sliding}>
 <p className='font-plus text-[14px] cursor-pointer'>Account Setting</p>
 {slide&&
<svg className='mb-[-13px] mt-2 ' width="110" height="3" viewBox="0 0 110 3" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="110" height="3" fill="#AF20F2" fillOpacity="0.7"/>
</svg>}
 </div>
 <div onClick={sliding}>

<p className='pointer text-[14px] font-plus cursor-pointer'>Password</p>
{!slide&&
<svg className='mb-[-13px] mt-2 ' width="70" height="3" viewBox="0 0 70 3" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="70" height="3" fill="#AF20F2" fillOpacity="0.7"/>
</svg>

}

 </div>
        </div>
    <svg className='w-10 md:w-[739px]'  height="1" viewBox="0 0 739 1" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect  height="1" fill="#EBEBEB"/>
</svg>

<div className='ml-[45px] mt-[65px] mr-[65px] '>
{slide&& 
<AccountSetting profile={props.PROFILE}/>
}
{!slide&&
<Password/>
}

</div>
    </div>
</div>
       </div>
    </Contener>
 
  )
}

export default Profile