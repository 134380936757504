import React from 'react'
import { Upload } from 'antd'
function AddImage(props) {
  return (
    <Upload
    name="avatar"
    listType="picture-card"
    className="avatar-uploader mb-[-50px] "
    // showUploadList={false}
    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
    // beforeUpload={beforeUpload}
    onChange={props.onChange}
  >
    

    <div>
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <p className="">+</p>
      <div
        style={{
          marginTop: 8,
        }}
      >
        <span className="text-[#C7C7C7]"> Upload</span>
      </div>
    </div>
  

      
   
  
  </Upload>
  )
}

export default AddImage