import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import { Link,  useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Button from './Button'
import NoPage from './NoPage'
import PageCard from './PageCard'
import { BASE_URL } from '../Function/Base'
import Function from '../Function/Function'
import ContentLoader from 'react-content-loader'

import { Skeleton } from 'antd';
import { useAuth } from '../Function/AuthProvider'
function CreatePage(props) {
 // const Navigate = useNavigate();
  const auth =useAuth();
  //axios.defaults.headers.common['Authorization'] = `Bearer ${auth.token}`;
useEffect(()=>{
  auth.HaveCampus(props.datas!=null?props.datas: null);
})
 
  
// const post=async()=>{
//   const url=`${BASE_URL}/api/create-new-campus`
//   try {
//     const response = await axios.post(url,
//       );
      
    
//     if(response?.data.status===200){
  
//      props.ALLNotification(response.data?.message,'top')
//      Cookies.set('campus',response?.data.campusCreated.About_Section_Contents.campusID)
//      //  console.log(response.data?.campusCreated)
//      // Navigate('/newPage',{state:{campusID:response?.data.campusID,page:response?.data.campusCreated}});
//       Navigate('/newPage',{state:{campusID:response?.data.campusCreated.About_Section_Contents.campusID,page:response.data?.campusCreated,ROUTE:'create'}});  
//   auth.APIloading()
//     }
//   } catch (error) {
//     props.ALLNotification(error,'top')
  
//   }
//}

  return (
  <div  className={`${auth.isDarkMode?'dark dark:bg-[#000] dark:text-white':'bg-[#F5F5F5]'}`}>
    <div className=' mt-[62px] bg-white rounded-[17px] dark:bg-[#000] dark:text-white  dark:rounded-[17px]' >
  {props.contextHolder}
    <div className='h-[67px] flex items-center shadow-sm justify-between dark:bg-[#000] dark:text-white dark:border-[1px] dark:rounded-md'>
    <p className='ml-[24px] text-[18px] font-plus font-bold '> My Page</p>
    <div ref={!auth.have?auth.ref3:null} className='flex items-center gap-2 mr-[24px] dark:bg-[#000] dark:text-white '> 
    </div>
    </div>
   {props.datas?.length<1?
   
     <NoPage ALLNotification={props.ALLNotification} contextHolder={props.contextHolder} />:
<div className='flex flex-wrap  justify-center md:justify-start md:ml-[70px] mb-[57px] mt-[50px] gap-8 dark:bg-[#000] dark:text-white'> 
{props.datas?.map((data,index)=>(
 <div key={index}>
  <PageCard Delete={props.Delete} data={data} ActiveL={props.datas}/>
 </div>
))
}
  </div>
}
   
    </div>
  </div>
  )
}

export default CreatePage