import React from 'react'

export default function Card(props) {
  return (
    <div style={{ 
      boxShadow:props.style,
    }} key={props.keys} className={props.className}>
{props.children}
    </div>
  )
}
