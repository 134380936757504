import React, { useState } from 'react'
import Fellowship from './Fellowship';
import Nav from '../Component/Nav';
import Ourcity from './Ourcity';
import Weather from './Weather';
import Hero from './Hero';
import P from '../Component/P';
import Programs from './Programs';
import Registerui from './Registerui';
import Contactui from './Contactui';
import Clip from '../Component/Clip';
import Gallery from './Gallery';
import DataPackage from '../Component/DataPackage';
import FellowGroups from './FellowGroups';
import Welcom from './Welcom';
import AboutCampus from './AboutCampus';
import FellowTeams from './FellowTeams';
import Jesoun from '../Component/Jesoun'
import { useEffect } from 'react';
import { BrowserRouter,HashRouter, Routes, Route, useParams } from "react-router-dom";
import axios from 'axios';
import Footer from './Footer';

import { useAuth } from '../../Function/AuthProvider';
import { motion, useScroll } from "framer-motion"
import { BASE_URL } from '../../Function/Base';


export default  function   CampusDemo () {
  const [welcome,setWelcom]=useState(null)
  const [Notification,setNotify]=useState(null)
const auth=useAuth();
  const {campusID} = useParams();
  useEffect(()=>{
    auth.setDemo(campusID)
  },[campusID])
useEffect(()=>{
 
  if(campusID!=null&&campusID!='undefined'){ 
   
    axios.post(`${BASE_URL}/api/view-campus-demo?campusID=${campusID}`)
    .then(res=>{
     const data = res.data;
     
     if(res.data&&res.data?.status==='200'){
       setWelcom(data)
      
     }else {
        setNotify(res.data.message)
     }
   
    }).catch(error=>{
    
     setNotify(error)
    })
   }
    

 
},[welcome])
const { scrollYProgress } = useScroll();
  
  return (
    <motion.div  
    >
      {welcome!=null&&welcome!='undefined'?
      <>     
          <Hero props={welcome?.Welcome_Section_Contents}/>
          <Fellowship props={welcome?.Fellowship_Section_Contents}/>
       <Welcom  props={welcome?.Intro_Section_Contents}/>
        <AboutCampus props={welcome?.About_Section_Contents}/> 
        <Ourcity Fewclouds={DataPackage.city.Fewclouds} Clearsky={DataPackage.city.Clearsky} props={welcome.City_Section_Contents}/>
<Weather caption={welcome?.Campus_Contents}  props={welcome?.City_Section_Contents} />
<Contactui content={welcome.Campus_Contents} props={welcome.Leader_Section_Contents}/>
<div className='flex lg:flex-col flex-col-reverse '>
<FellowTeams caption={welcome?.Campus_Contents} content={welcome?.Campus_Contents} props={welcome.Team_Section_Contents}/>

<Programs   caption={welcome?.Campus_Contents} props={welcome?.Service_Section_Contents}/>
</div>

{welcome?.Registration_Form_Visibility!=true?'':
   <Registerui caption={welcome} props={DataPackage.registerui} campusID={welcome?.Welcome_Section_Contents.campusID}  campus={welcome.Welcome_Section_Contents.title} />
}
<Gallery caption={welcome?.Campus_Contents}  props={welcome}/>
  <Footer mapSrc={welcome?.mapSrc} lat={parseFloat(welcome?.Footer_Section_Contents?.mapLatitude)} long={parseFloat(welcome?.Footer_Section_Contents?.mapLongitude)} props={welcome?.Footer_Section_Contents} social={welcome?.Social_Medias}/>
  
  </>:
  <div>
    <p>{Notification}</p>
    </div>
}
    </motion.div>
  )
}