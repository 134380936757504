import React from 'react'
import '../css/baseStyle.css'
function Input(props) {
   const readonlys=props.readonly
    return (
        <div>
            <label className={`text-[14px] font-plus ${props.lableStyle} `} htmlFor={props.name}> {props.lable} <br/></label>
<input pattern={props.pattern} disabled={props.disable}  onKeyDown={props.onkeydown} min={props.min} maxLength={props.max} value={props.value}   id={props.id}  onChange={props.onChange} className={` text-[14px] font-plus pl-3 h-[35px] md:h-[50px]   mt-[7px] mb-[15px]  rounded-[4px]  hover:border-blue-500  border-solid border-2 border-black-600 text-[18px] ${props.style}`} placeholder={props.placeholder} required type={props.type} name={props.name}/>
        </div>
     
   
    )
}

export default Input