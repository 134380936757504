import React, { useEffect, useState } from "react";

import axios from "axios";

import WeatherCard from "../Component/WeatherCard";

const API_KEY = "6a5a6d15a1534c77ae6a1ad66500a0ac";
export default function Weather(props) {
  const [weather, setWeather] = useState(null);
  const [dayName, setDayName] = useState(null);
  
  useEffect(() => {
    // const fetchWeather = async () => {
    //   try {
    //     const { data } = await axios.get(
    //       `https://api.weatherbit.io/v2.0/current?lat=6.03333&lon=37.55&key=${API_KEY}&include=minutely`
    //     );
    //     var moment = require("moment"); // require

    //     const date = moment(data.data[0].ob_time, "YYYY-MM-DD HH:mm").format(
    //       "dddd"
    //     );  
    //     setDayName(date);
    //     setWeather(data);
        
    //   } catch (error) {
       
    //   }
    // };

    // fetchWeather();
  }, []);

  return (
    <div style={{
      
    }}>
      <div className="container inline-block mt-5 mb-5">
        {weather != null ? (
          <div className="flex  justify-center">
            <div className="space-y-8 flex p-1 items-center">
              <WeatherCard
                day={dayName}
                temp={weather.data[0].temp}
                icon={weather.data[0].weather.description==='Clear sky'?props.props.Clearsky:weather.data[0].weather.description==='Few clouds'?props.props.Fewclouds:''}
                windSpeed={weather.data[0].wind_spd}
                humidity={weather.data[0].rh}
                condtion={weather.data[0].weather.description}
                city={weather.data[0].city_name}
                
              />         
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      
    </div>
  );
}
