import { Progress } from "antd";
import React from "react";
import { Pie, measureTextWidth } from '@ant-design/plots';

 function StudentWithSex(props){
  
  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
    const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
  }

  const data = [
    {
      type: 'Male',
      value: props?.m,
      persenteage:props?.male
    },
    {
      type: 'Female',
      value:props?.f,
      persenteage:props?.female
    },
  ];
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (v) => `% ${v} `,
      },
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
      },
      autoRotate: false,
      content: '{value}',
    },
    statistic: {
      title: {
        offsetY: -2,
        customHtml: (container, view, datum) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum.type : 'Student';
          return renderStatistic(d, text, {
            fontSize: 10,
          });
        },
      },
      content: {
        offsetY: 2,
        style: {
          fontSize: '12px',
        },
        customHtml: (container, view, datum, data) => {
          const { width } = container.getBoundingClientRect();
          const text = datum ? ` ${datum.value} %` : `${data.reduce((r, d) => r + d.persenteage,0)}%`;
          return renderStatistic(width, text, {
            fontSize: 10,
          });
        },
      },
    },
   
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
      {
        type: 'pie-statistic-active',
      },
    ],
  };
    return(
        <div className={`${props.isDarkMode?'dark':''} dark:bg-[#000] dark:text-white dark:border dark:border-r-1 w-[70%] bg-white rounded-[17px] text-[#737373] hover:bg-gradient-to-r hover:from-indigo-500 hover:from-10% hover:via-sky-500 hover:via-30% hover:to-emerald-500 hover:to-90% bg-gradient-to-r hover:text-white`}>
        <div className=" ">
          <div className="relative flex w-full justify-center mt-[22px]">
            <div className="relative    w-[250px] h-[250px]">
            <Pie {...config} />
            </div>
         
          </div>
         
        </div>
      </div>
    )
}
export default StudentWithSex;