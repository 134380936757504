import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth} from "./AuthProvider";
const RequireAuth = ({ children, loginPath }) => {
  const { pathname } = useLocation();
//const auth=useAuth()
//   useEffect(() => {
//     if (!auth.token && pathname !== loginPath) {
//       auth.logout();
//     } 
//   }, [pathname, loginPath]);

const auth=useAuth()

  if (!auth.token) {
    auth.logout();
    return <Navigate to={loginPath} />;
  } else {
    return children;
  }
};

export default RequireAuth;
