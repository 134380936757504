import axios from 'axios';
import Cookies from 'js-cookie';
import React from 'react'

const token=Cookies.get('token');

const url='http://161.35.56.176:8000/api/'
const studentData=[]
function getStudent(){
}
export default {getStudent}