import React, { useEffect, useState } from 'react'
import AuthPage from '../Component/AuthPage'
import { Link,useNavigate,useParams } from "react-router-dom";
import Input from '../Component/Input';
import AuthTitle from '../Component/AuthTitle';
import Button from '../Component/Button';
import { BsCheck2 } from 'react-icons/bs';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../Function/Base';
import {  message, Steps, Switch, theme,notification, Spin } from "antd";
import { useAuth } from '../Function/AuthProvider';
function InviteSignUp(props) {
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    const { invKey } = useParams();
    const [visible, setVisible] = useState(false);
const auth=useAuth();
  
    const [formData,setFormData]=useState({
        span:false,
        Firstname:null,
        Lastname:null,
        Phonenumber:null,
        Organization:null,
        Email:null,
        Password:null,
        confirmPassword:null,
        resetPassword:true,
      });
      const [isValid, setIsValid] = useState(true);
      const [confirmPassword,setConfirmPassword]=useState(true);
      const [resetP,setReset]=useState(false);
      const [empty ,setEmpty]=useState(true);
      const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;
    function handleSubmit(e){
       e.preventDefault();
    setFormData({...formData,[e.target.name]:e.target.value})
      // Regular expression to check for at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and a minimum length of 8 characters
   
      setIsValid(formData.Password);
     
    }
    function openNotification(placement,message){
        api.info({
          message: `Notification`,
          description:
            message,
          placement,
        });
      }
    
    const post=async()=>{
    
     const url =`${BASE_URL}/api/inv-signup?`;
     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


 if(isValid!=false&&formData.Phonenumber!=null&&formData.Phonenumber!=""&&formData.confirmPassword!=null&&formData.confirmPassword!=""&&formData.Firstname!=null&&formData.Firstname!=""&&formData.Password!=null&&formData.Password!=""&&formData.Lastname!=null&&formData.Lastname!=""){
   axios.post(url,{
    firstName: formData.Firstname,
    lastName: formData.Lastname,
    phone:formData.Phonenumber,
    password:formData.Password,
    confirmPassword:formData.confirmPassword,
    invitationKey:invKey
        })
        .then(function (response) {
        
          if(response.data.status===200){
            //auth.openMessage(response.data.message, 'success', 1);
            navigate('/');
          }else if(response&&response?.status===500){
            
            auth.openMessage(response.message, 'error', 2);
          }else if(response&&response?.status===403){
            
            auth.openMessage(response.message, 'error', 2);
          }else{
            auth.openMessage(response.data.message, 'error', 3);
          }
        })
        .catch(function (error) {
         
          toast(error);
        });
      }else if(formData.Firstname===null||formData.Firstname===""){
        auth.openMessage('First Name required', 'error', 3);

      }else if(formData.Lastname===null||formData.Lastname===""){
        auth.openMessage('Last Name required', 'error', 3);
      }else if(formData.Phonenumber===null||formData.Phonenumber===""){
        auth.openMessage('Phone number required', 'error', 3);

      }else if(formData.Password===null||formData.Password===""){
        auth.openMessage('Password required', 'error', 3);
      }else if(isValid===false){
        auth.openMessage(' Check Password format!', 'error', 3);
      }else if(formData.confirmPassword===null||formData.confirmPassword===""){
        auth.openMessage('Confirm Password required', 'error', 3);
      }
        
    }
    function  reset(){
      setReset(true)
    }
    function resetBack(){
      setReset(false)
    }
    useEffect(()=>{
    
      
    },[])
    const togglePassword = document.querySelector('.toggle-password');
    const passwordInput = document.querySelector('#password');
    const passwordconfirm=document.querySelector('#Confrimpassword');
    function showPassword() {
      setVisible(!visible);
      const type = passwordInput.getAttribute('type') === 'password' ? 'text' : 'password';
      passwordInput.setAttribute('type', type);
      const secondType=passwordconfirm.getAttribute('type') === 'password' ? 'text' : 'password';
      passwordconfirm.setAttribute('type', secondType);
    }
  return (
    <div className='md:grid md:grid-cols-2 md:h-screen md:overflow-hidden'>
       {auth.contextHolder}
   <div>
    <AuthPage/>
   </div>
   <div className='flex flex-col justify-center items-center h-screen overflow-y'>
         <div className=' mt-[165px] md:mt-[230px] flex flex-col items-start '>
         <AuthTitle title='SignUp' style='    authheading'  />
         </div>
      <div className=' flex flex-col items-center md:items-start  font-plus mb-[50px] mt-[50px]'>
      <div className=''>
      <Input placeholder='  First Name'  lableStyle='authlable'  lable='First Name' name='Firstname' type='text' style={`border ${auth.errorInput&&auth.errorInput==='First Name required'?'border-pink-500':''} authinput w-[300px]  md:w-[400px] md:h-[40px] h-[40px] font-plus  invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500  `}  onChange={(e)=>handleSubmit(e)}/>
      <Input placeholder='  Last Name' lableStyle='authlable'  lable='Last Name' name='Lastname' type='text' style={`border ${auth.errorInput&&auth.errorInput==='Last Name required'?'border-pink-500':''} authinput w-[300px]  md:w-[400px] md:h-[40px] h-[40px]  `}  onChange={(e)=>handleSubmit(e)}/>
        </div>
       <div className=''>
      {/* <Input placeholder='  @gmail.com' lableStyle='authlable'  lable='Email' name='Email' type='email' style={`authinput w-[300px]  md:w-[500px] md:h-[50px] font-plus invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 border ${auth.errorInput&&auth.errorInput==='Incorrect email or password!'||auth.errorInput==='Email is already in use!'||auth.errorInput==='All field required'||auth.errorInput==='check email please!'?'border-pink-500':''}  `}  onChange={(e)=>handleSubmit(e)}/> */}
      <Input placeholder='251' min='12' pattern="[0-9]{10}" max='13' lableStyle='authlable'  lable='Phone Number' name='Phonenumber' type='number' style={` phone-number-input authinput w-[300px]  md:w-[400px] md:h-[40px] h-[40px] font-plus invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 border ${auth.errorInput&&auth.errorInput==='Incorrect email or password!'||auth.errorInput==='Phone number is already in use!'||auth.errorInput==='Phone number is already in use! Please enter another phone number'?'border-pink-500':''}   `}  onChange={(e)=>handleSubmit(e)}/>    
     
       </div>
      <div className=''>
        <div className='relative'>
      <Input placeholder='  ******' id='password' lableStyle='authlable' lable='Password' name='Password' type='password' style={` border authinput w-[300px]  md:w-[400px] md:h-[40px] h-[40px] invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 ${auth.errorInput&& auth.errorInput==='Passwords do not match!'||auth.errorInput==='Password required'?'border-pink-500':''}  `}  onChange={(e)=>handleSubmit(e)}/>
      
        </div>
      <Input placeholder='  ******' id='Confrimpassword'  lableStyle='authlable' lable='Confirm Password' name='confirmPassword' type='password' style={`authinput w-[300px]  md:w-[400px] md:h-[40px] h-[40px] ${auth.errorInput&& auth.errorInput==='Passwords do not match!'||auth.errorInput==='Confirm Password required'?'border-pink-500':''}  `}  onChange={(e)=>handleSubmit(e)}/>
      </div>
      <div className=' flex gap-4 items-center mb-4'>
     
     <input
         type="checkbox"
         checked={visible}
         onChange={showPassword}
       />
      <label htmlFor="password" className=' text-[12px]'>Show Password</label>
   
     </div>
    <div className='flex gap-2 mb-1 items-center'>  {!empty && <p className='text-red-500 opacity-70 text-[10px] mt-[-10px] flex items-center gap-2'><BsCheck2 size={10}/>  All fildes required </p>}
    {!confirmPassword && <p className='text-red-500 opacity-70 text-[10px] mt-[-10px] mb-2 flex items-center gap-2'> <BsCheck2 size={10}/> password is not the same </p>}
      </div>
      {!isValid && <p className='text-[8px] md:text-[8px] opacity-70 mt-[-10px]  '> <p className="  flex items-center text-[8px] md:text-[10px] gap-1 md:gap-2"><BsCheck2 size={10} className='text-red-500' />  Password must be at least <span className="text-red-500">8 characters</span>   <span className="text-red-500">uppercase letters,</span><span className="text-red-500">lowercase letters</span>,and  <span className="text-red-500">numbers</span> </p> <span className="text-red-500 text-[8px]  md:text-[15px]"> </span></p> }    
       {formData.span!=false?
        <Spin  style={{
      
        width: "150px",
       marginTop:'4px'
      }} type="primary" size="small" loading>
            
            </Spin>:   
    <Button name='Signup'  handlclick={post} style=' md:w-[400px] md:h-[40px] mt-[2px] h-[40px] w-[300px] '>Signup</Button>
    }
      <p className='md:text-[18px] mt-[50px] md:DoAccount '>Do you have an account? <Link className='text-[#9009CF] hover:text-[#2eb157] font-plus ' to="/">Login now</Link></p>   
      </div>
      </div>
    </div>
  )
}

export default InviteSignUp