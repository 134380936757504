import React, { useEffect,useState } from 'react'
import AuthNotify from '../Component/AuthNotify'
import AuthPage from '../Component/AuthPage'
import AuthTitle from '../Component/AuthTitle'
import { Link, useNavigate,useLocation } from "react-router-dom";
import '../css/baseStyle.css'
import Fackdata from '../Database/Fackdata';
import confirmNotify from '../Assets/confirmNotify.png';
import {BASE_URL } from '../Function/Base';
import axios from 'axios';
import { useAuth } from '../Function/AuthProvider';
import Cookies from 'js-cookie';
import {  Spin } from 'antd';
function SeccssPage(props) {
    const location = useLocation();
    const auth= useAuth();
    const navigate = useNavigate();
    const [Notification,setNotification]=useState(true);
    const config = {
   
      headers: {
        'Content-Type': 'multipart/form-data',
'Authorization':`Bearer ${Cookies.get('token')}`
      },
    
  };
  
     function sendEmail(){

       axios.post(`${BASE_URL}/api/verify-email-request`,config).then(function (response){
      
        setTimeLeft(300)
        setTimerRunning(true);
      }

      )
      .catch((error) => {
       
        
     });
    }
    const [timeLeft, setTimeLeft] = useState(300); // 300 seconds = 5 minutes
  const [timerRunning, setTimerRunning] = useState(false);

  useEffect(() => {
    let intervalId;

    if (timerRunning) {
      intervalId = setInterval(() => {
        setTimeLeft(prevTime => {
          if (prevTime === 0) {
            clearInterval(intervalId);
            setTimerRunning(false);
            
            // You can perform additional actions when the timer reaches 0 here
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000); // Update every second
    }

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [timerRunning]);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimerRunning(false);
    }
  }, [timeLeft]);
  useEffect(() => {
    setTimerRunning(true);
    sendEmail();
  }, []);

  const handleStartTimer = () => {
    setTimerRunning(true);
  };

  const handleStopTimer = () => {
    setTimerRunning(false);
  };
  useEffect(()=>{
    let  interval;
    if(timerRunning){

       interval=setInterval(()=>{
        const response =  axios.get(`${BASE_URL}/api/email-status`,config).then(function (response){

          if(response.data.isEmailVerified==='No'){
  
          }else{
            setNotification(false)
            Cookies.set('isEmailVerified',true)
            auth.setisEmailVerified(true)
            navigate('/');
          }
        }
  
        )
        .catch((error) => {
        
          
       });
      },[6000])
  }
   
    return ()=>clearInterval(interval);
  },[timerRunning])
  return (
    <div className='flex justify-center items-center' >
    
<Link className='  hover:text-[#2eb157] mt-[-7px] ml-[5px]    p' to=''></Link>

<AuthNotify image={confirmNotify}  title='Email Confirmation' info={`We have sent a verification link via your email. Please check your email!`} tag='myfellow.et'>
<p>Time left: <span className={`${timeLeft===0?'text-red-500':'text-green-500'} font-bold `}>{timeLeft}</span> seconds</p>
<svg className=' bg-slate-500 p-[1px] m-2' width="55%" height="1">
  <line x1="0" y1="0" x2="100%" y2="0" style={{stroke:'#000', strokeWidth:2}} />
</svg>

<p className='text-md Poppins Popp-500 text-center flex flex-col lg:flex-row'>If you have not received any mail, </p>
<p onClick={()=>sendEmail()} className='  cursor-pointer text-green-500 font-bold text-md cursor-pointer'>please resend the confirmation email. </p>
{!Notification&&<Spin  size='large' />}
  </AuthNotify>

</div>
  )
}

export default SeccssPage