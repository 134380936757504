import React, { useState } from 'react'
import { message, Upload } from 'antd';


function SteperRout(props) {
   
  return (
    <div>
    <h1>{props.title}</h1>
    <div className='flex gap-8 justify-start '>
       
{props.children}
 
        </div>
    </div>
  )
}

export default SteperRout