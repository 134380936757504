import React from 'react'
import AuthNotify from '../Component/AuthNotify'
import AuthPage from '../Component/AuthPage'
import AuthTitle from '../Component/AuthTitle'
import { Link, useNavigate,useLocation } from "react-router-dom";
import '../css/baseStyle.css'
import Fackdata from '../Database/Fackdata';
import confirmNotify from '../Assets/pana.png';
function Declen(props) {
    const location = useLocation();
  return (
    <AuthPage>
    <AuthTitle title='Success' style='mt-[184px] authheading text-[#0AE720]'/> <br/>
<Link className='  hover:text-[#2eb157] mt-[-7px] ml-[5px]   w-[330px] p' to=''><p className='text-[#9009CF]'>{Fackdata.invitemailHeader}</p></Link>
<AuthNotify image={confirmNotify} info='Invitation Declined Successfully!' tag='Etcompus.org.'/>
</AuthPage>
  )
}

export default Declen