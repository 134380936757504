import React, { useRef, useState } from 'react'
import formLock from '../Assets/formLock.jpg'
import '../css/switch.css'
import { BASE_URL } from '../Function/Base';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useAuth } from '../Function/AuthProvider';
function FreshStudentForm(props) {
 const [check,setCheck]=useState(props.formData.viewForm)
const token=Cookies.get('token')

const auth=useAuth()
async function handleUncheck(){
 
  await   axios.post(`${BASE_URL}/api/change-form-visibility?token=${auth.token}&campusID=${props?.formData?.campusID}&isVisible=${!check}`)
  .then(function (response) {
    setCheck(!check)
 props.ALLNotification(response.data?.message,'top')
  }).catch(function (error) {

  });

}

  return (
  <div className=''>
    <img className='' src={formLock} alt="" />
  <div className='flex gap-4 relative items-center justify-center mt-5'>
    <p className='text-black text-[16px] font-bold'> Display registration form.</p>
    <div className='relative'>
 <div onClick={()=>handleUncheck} className=' left-0 right-0 flex items-center justify-end '>
 <label className='switch'>
 <input type="checkbox"  onChange={handleUncheck} checked={check}  />
 <span class="slider round"></span>
</label>


 </div>
    </div>
  </div>
  </div>
  )
}

export default FreshStudentForm