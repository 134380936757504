import React from 'react'
import { BASE_URL } from '../Function/Base'

function TeamCard(props) {
  return (
    <div className='h-full  relative  overflow-hidden rounded-[8px]' style={{
        // backgroundImage:`url(${data.image})`,     
    }}>
        <img className='absolute rounded-[8px] w-[100%] h-[100%]' src={`${BASE_URL}/`+props.image} alt="" />
    <div className={`relative h-full text-white p-2 flex items-end rounded-[8px]  ${props.serviceID===props.id? 'border-x-pink-500 border-2':''}`} style={{
        background:`linear-gradient(359deg, #05419B 26.05%, rgba(255, 255, 255, 0) 91.79%)`
    }}>
    
    <div className='mb-[18px]  '>
    <p  className='text-lg text-center mb-[20px]'>{props.name}</p>
    <p className='text-[11px] text-justify'>{props.description}</p>
    </div>
    </div>
    </div>
  )
}

export default TeamCard