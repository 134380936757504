import React, { useState } from 'react'
import AuthPage from '../Component/AuthPage'
import AuthTitle from '../Component/AuthTitle'
import Button from '../Component/Button';
import Input from '../Component/Input'
import { Link, useNavigate } from "react-router-dom";
import '../css/baseStyle.css'
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../Function/Base';
import { useAuth } from '../Function/AuthProvider';
function ForgetPassword(props) {
    const navigate = useNavigate();
    const auth=useAuth();
    const [formData,setFormData]=useState({
        Email:null,
      });
      const [isValid, setIsValid] = useState(true);
      const [resetP,setReset]=useState(false);
    function handleSubmit(e){
       e.preventDefault();
    setFormData({...formData,[e.target.name]:e.target.value})
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    function post(){
      if(formData.Email!=null){
        axios.post(`${BASE_URL}/api/reset-password-request?email=${formData.Email}`)
        .then(function (response) {
      // handle success   
          
          if(response.data?.status===200){
            auth.openMessage(response.data.message, 'success', 1);  
            setTimeout(() => {
              // Your function code here
              navigate('/',{state:{header:props.data.invitemailHeader ,image:props.data.resetNotify,message:props.data.resetPassword}});
             
            }, 30000);
          }else {
            auth.openMessage(response.data.message, 'error', 1);
          }
        })
        .catch(function (error) {
          // handle error
         toast(error)
        })
        .finally(function () {
          // always executed
        });
   
      }else if( !emailRegex.test(formData.Email)){
        auth.openMessage('check email please!', 'error', 2);
        setFormData({...formData,Email:null})
      }
    }
  return (
    <div className='md:grid md:grid-cols-2 md:h-screen md:overflow-hidden'>
        {auth.contextHolder}
      <div>
      <AuthPage/>
      </div>
      <div className='flex flex-col justify-center items-center h-screen overflow-y '>
         <AuthTitle title='Forgot  Password?' style=' text-center md:text-start mt-[280px] md:mt-[116px] md:authheading'/>
      <div className='mt-[20px] md:mt-[50px] font-plus flex flex-col items-center md:items-start justify-center'>
        <p className='md:forgetP mb-[-25px] ml-[-50px] md:ml-[0px] text-center md:text-black text-[10px] md:text-start'> Enter Your Email and we will send password reset link</p>
      <Input placeholder='@gmail.com' lableStyle='authlable' name='Email' lable=' ' type='email' style={` authinput w-[300px] h-[40px]  md:w-[400px] md:h-[40px] font-plus  invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 ${auth.errorInput&&auth.errorInput==='Email does not exist!'||auth.errorInput==='check email please!'?'border-pink-500':''}`}  onChange={(e)=>handleSubmit(e)}/>    
      <Button name='Send Email' lableStyle='authlable'  handlclick={post} style=' authinput w-[300px] md:w-[400px] md:h-[40px] h-[40px] mt-[24px] '>Send Email</Button>
      <p className='p-5 md:p-0 text-[10px] md:text-[14px] mb-[29px] text-center md:text-start mt-[20px] md:mt-[17px]  md:forgetPassP text-[#737373]'>If you don't remember the email address associated with your account or <br/>
if you need additional help, please <strong>contact gcmedevelopers@gmail.com</strong> <br/> our support team for assistance.
  </p>   
  <Link className='text-[#9009CF]  hover:text-[#2eb157] font-plus underline underline-offset-2' to="/"><BsFillArrowLeftCircleFill size={20}/></Link>
        </div>
        </div>
    </div>
  )
}

export default ForgetPassword;