import React, { useEffect } from "react";
import Contener from "../Component/Contener";
import { Button, message, Steps, Switch, theme,notification, Spin } from "antd";
import { useState } from "react";
import Dashboard from "./Dashboard";
import HeroPage from "./HeroPage";
import WelcomPage from "./WelcomPage";
import SteperRout from "../Component/SteperRout";
import oneteam from '../Assets/oneteam.png';
import twoteam from '../Assets/twoteam.png';
import threeteam from '../Assets/threeteam.png';
import ds from '../Assets/ds.jpeg'
import groupMessage from '../Assets/12.png';
import OurCity from "./OurCity";
import Clearsky from "../Assets/Weather/11.png";
import Fewclouds from "../Assets/Weather/1.png";
import AboutCampus from "./AboutCampus";
import Fellowship from "./Fellowship";
import fellows from '../Assets/fellow.png'
import Program from "./Program";
import OurTeam from "./OurTeam";
import FreshStudentForm from "./FreshStudentForm";
import Footer from "./Footer";
import OurLeader from "./OurLeader";
import Gallery from "./Gallery";
import MainRouter from "./MainRouter";
import { useLocation, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../Function/Base";
import { Link,  useNavigate } from 'react-router-dom'
import { useAuth } from "../Function/AuthProvider";
function NewPage(props) {
  const [api, contextHolder] = notification.useNotification();
  const auth=useAuth();
  axios.defaults.headers.common['Authorization'] = `Bearer ${auth.token}`;

const {campusID} = useParams();
  auth.setDemo(campusID)
  const location = useLocation();
  const [wrongAnswers, setWrongAnswers] = useState({});
  const result = [];
  const token= Cookies.get('token')
  const url=`${BASE_URL}/api/`
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const Navigate = useNavigate();
  
  const reader = new FileReader();
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  function openNotification(placement){
    api.success({
      message: `Notification`,
      description:
        'Image upload successfully!',
      placement,
    });
  }
  function ALLNotification(placement,location,type){
    api.success({
      message: `Notification`,
      description:
        `${placement}`,
        location,
    });
  }
  const config = {
   
      headers: {
        'Content-Type': 'multipart/form-data',

      },
    
  };
  const beforeUploadGalary = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
     // auth.openMessage("You can only upload JPG/PNG file!",'error',1);
    }
   
    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB
    const isLt2M= file.size < MAX_FILE_SIZE;
    if (!isLt2M) {
      auth.openMessage("Image must smaller than 2MB!",'error',2);
    }
    return isJpgOrPng && isLt2M;
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      auth.openMessage("You can only upload JPG/PNG file!",'error',1);
    }
   
    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB
    const isLt2M= file.size < MAX_FILE_SIZE;
    if (!isLt2M) {
      auth.openMessage("Image must smaller than 2MB!",'error',2);
    }
    return isJpgOrPng && isLt2M;
  };
useEffect(()=>{
  
},[location.state.page])

  const Urlimage=`${BASE_URL}/`
  const newData = ['u'];
 
  const [formData, setFormData] = useState({
    span:false,
    ROUTE:location.state.ROUTE,
    url:location.state.page.Campus_Contents.url,
    urlError:null,
    campusID:location.state.campusID,
    viewForm:location.state.page.Registration_Form_Visibility,
    universityName: location.state.page.Welcome_Section_Contents.universityName,
    fellowshipName: location.state.page.Welcome_Section_Contents.fellowshipName,
    fellowshipAcronym:location.state.page.Welcome_Section_Contents.fellowshipAcronym,
    campusName:location.state.page.Welcome_Section_Contents.campusName,
    Flogo:Urlimage+location.state.page.Welcome_Section_Contents.fellowshipLogo,
    FlogoFile:'',
    CityImage:Urlimage+location.state.page.City_Section_Contents.bg_Image,
    CityImageFile:'',
    FellowshipMoto:location.state.page.Welcome_Section_Contents.moto,
    image:Urlimage+location.state.page.Welcome_Section_Contents.image,
    imageFile:'',
    title:location.state.page.Intro_Section_Contents.title,
message:location.state.page.Intro_Section_Contents.message,
Leaderimage:Urlimage+location.state.page.Intro_Section_Contents.image,
LeaderimageFile:'',
leaderName:location.state.page.Intro_Section_Contents.author,
posetion:location.state.page.Intro_Section_Contents.authorPosition,
  history:location.state.page.City_Section_Contents.description,
  cityTitle:location.state.page.City_Section_Contents.title,
     Clearsky: Clearsky,
  Fewclouds: Fewclouds,
  longtude:'27.565656',
  latitude:'30.4545',
  mapSrc:location.state.page.mapSrc,
  campusDescription:location.state.page.About_Section_Contents.description,
  Logo:Urlimage+location.state.page.About_Section_Contents.logo,
  LogoFile:'',
  campusImage:Urlimage+location.state.page.About_Section_Contents.bgImage,
  campusImageFile:'',
  membersNum:location.state.page.Fellowship_Section_Contents.members,
  teamNum:location.state.page.Fellowship_Section_Contents.teams,
  service:location.state.page.Fellowship_Section_Contents.services,
  Fdescription:location.state.page.Fellowship_Section_Contents.description,
  fdescription:'Fellowship History',
  bgColorFellow:location.state.page.Fellowship_Section_Contents.bgColor,
  servicesCaption:location.state.page.Fellowship_Section_Contents.servicesCaption,
  teamsCaption:location.state.page.Fellowship_Section_Contents.teamsCaption,
  membersCaption:location.state.page.Fellowship_Section_Contents.membersCaption,
  Fimage:Urlimage+location.state.page.Fellowship_Section_Contents.image,
  FimageFile:'',
  name: "",
  serviceID:null,
  teamID:null,
  leaderID:null,
  gallerieID:null,
  day: "",
  time: "",
  address: "",
  Teamname: "",
  Teamimage: '' ,
  TeamimageFile:'',
  TeamDisc: "",
   
  Teams:newData,
  data: [],
  contactPhone:location.state.page.Footer_Section_Contents.phone,
  email:location.state.page.Footer_Section_Contents.email,
  telegram:location.state.page.Social_Medias.telegramLink,
  facebook:location.state.page.Social_Medias.facebookLink  ,
  youtube:location.state.page.Social_Medias.youtubeLink,
  instagram:location.state.page.Social_Medias.instagramLink,
  tiktokLink:location.state.page.Social_Medias.tiktokLink,
  CITYNAME:location.state.page.City_Section_Contents.name,
  map:location.state.page.Footer_Section_Contents.mapLink,
  mapLatitude: parseFloat(location?.state?.page?.Footer_Section_Contents?.mapLatitude),
  mapLongitude: parseFloat(location?.state?.page?.Footer_Section_Contents?.mapLongitude),
   LeaderName: "",
  LeaderNumber:'+251' ,
  LeaderPosetion: "", 
  LeaderTelegram:'',
  LeaderFacebook:'',
  LeaderPhone:'',
  LeaderImage:'',
  
  LeaderImageFile:'',
  GalleryImage:"",
  GalleryImageFile:'',
  LeaderData:location.state.page.Leader_Section_Contents  ,
  GalleryData:location.state.page.Gallery_Section_Contents  ,
  TeamData:location.state.page.Team_Section_Contents,
  ProgramData:location.state.page.Service_Section_Contents
  });

  const [images,setImages]=useState([]);

  const handleImageChange = (event) => {
   
      const newImages = [...images];
      for (const file of event.target.files) {
        newImages.push(file);
      }
  
      setImages(newImages);
  
    // if(beforeUploadGalary(event.target.files[0])){
     // const selectedImages = Array.from(event.target.files);
      //openNotification('top')
     // setImages([...images, ...selectedImages]);
      auth.openMessage("Image upload successfully!",'success');
    // }

};
  const handleChange = (e) => {

   const file = e.target.files[0];
  
  reader.onloadend = () => {
      const content = reader.result;
      const mimeType = content.split(",")[0].split(":")[1].split(";")[0];
     
        if (beforeUpload(e.target.files[0])) {
          setFormData({ ...formData,[e.target.name]:URL.createObjectURL(e.target.files[0]), [e.target.name+'File']:e.target.files[0] });
          openNotification('top')
        } else {
          auth.openMessage("Please select a valid image file (JPEG or PNG)",'error');
        }
    
    };
    reader.readAsDataURL(file);
    
  };
  const uploadButton = (
    <div>
      {/* {loading ? <LoadingOutlined /> : <PlusOutlined />} */}
      <p className="">+</p>
      <div
        style={{
          marginTop: 8,
        }}
      >
        <span className="text-[#C7C7C7]">Upload</span>
      </div>
    </div>
  );
  function handleSubmit(e) {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // Regular expression to check for at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character, and a minimum length of 8 characters
  }
  const handleImageUpload = () => {

const formD = new FormData();
formD.append('campusID', location.state.campusID);

if (images && images.length > 0) {
  for (let i = 0; i < images.length; i++) {
    formD.append('images[]', images[i]);
  }
}

    if(images.length>0){
      setFormData({...formData,span:true})
    }else{
auth.openMessage('Add image first','error',1)
    }
  };

 async function  add(e){
  if(e.target.name==='leader'){
    if(formData.LeaderName!=''&&formData.LeaderPosetion!=''&&formData.LeaderImageFile!=''&&formData.LeaderPhone!=''){
   
    if(formData.leaderID!=null){
      const formDatas = new FormData();
      setFormData({...formData,span:true})
    //const formDatas = new FormData();
    const bodyData = {
      name:formData.LeaderName,
      role:formData.LeaderPosetion,
      photo:formData.LeaderImageFile,
      phone:formData.LeaderPhone,
      telegramLink:formData.LeaderTelegram,
      facebookLink:formData.LeaderFacebook,
      campusID:location.state.campusID,
      token:token
    };

    for (const key in bodyData) {
      formDatas.append(key, bodyData[key]);
    }
     
  await  axios.post(`${url}update-leader`,formDatas,config)
  .then(function (response){
    if(response.data?.status===200){
      auth.openMessage(response.data?.message,'success');
      setFormData((prevFormData) => ({ ...formData,span:false, LeaderData:response.data.leaders,LeaderName:'',LeaderPosetion:'',LeaderImageFile:'',LeaderPhone:'',LeaderTelegram:'',LeaderFacebook:'',leaderID:null}));
      auth.APIloading();
    }else{
      auth.openMessage(response.data?.message,'error',1);
      setFormData({...formData,span:false});
    }
  })
  .catch(function (error) {
    auth.openMessage(error.response.data.message,'error');
    setFormData({...formData,span:false})
  });
}else{
  
  const formDatas = new FormData();
    formDatas.append('name', formData.LeaderName);
    formDatas.append('role', formData.LeaderPosetion);
    formDatas.append('photo',formData.LeaderImageFile);
    formDatas.append('phone', formData.LeaderPhone);
    formDatas.append('telegramLink', formData.LeaderTelegram);
    formDatas.append('facebookLink', formData.LeaderFacebook);
    formDatas.append('campusID', location.state.campusID);
    formDatas.append('token', token);
    setFormData({...formData,span:true})
    
    axios.post(`${url}create-leader`, {
      name:formData.LeaderName,
      role:formData.LeaderPosetion,
      photo:formData.LeaderImageFile,
      phone:formData.LeaderPhone,
      telegramLink:formData.LeaderTelegram,
      facebookLink:formData.LeaderFacebook,
      campusID:location.state.campusID,
      token:token
    },config)
  .then(function (response) {
    if(response.data?.status===200){
      auth.openMessage(response.data?.message,'success');
    setFormData((prevFormData) => ({ ...formData,span:false, LeaderData:response.data.leaders,LeaderName:'',LeaderPosetion:'',LeaderImageFile:'',LeaderPhone:'',LeaderTelegram:'',LeaderFacebook:'',leaderID:null})); 
    auth.APIloading();
  }else{
      auth.openMessage(response.data?.message,'error',1);
      setFormData({...formData,span:false})
    }
  })
  .catch(function (error) {
   
    auth.openMessage(error.response.data.message,'error');
    setFormData({...formData,span:false})
  });
} 
}else{
  auth.openMessage('All field required','error',1)
  setFormData({...formData,span:false})
}

  
  }else if(e.target.name==='gallery'){
    if(images.length >0){
      const formDatas = new FormData();
      formDatas.append('campusID', formData?.campusID);
      for (const image of images) {
        formDatas.append('images[]', image);
      }
      setFormData({...formData,span:true})
      
  axios.post(`${url}upload-gallery-images`, formDatas,config)
  .then(function (response) {
  auth.openMessage(response.data.message,'success');
  setFormData((prevFormData) => ({ ...formData,span:false, GalleryData:response.data.galleries,GalleryImageFile:'',gallerieID:null}));
  setImages([])
  auth.APIloading();
  })
  .catch(function (error) { 
     auth.openMessage(error.message,'error');
    setFormData({...formData,span:false})
    setImages([])
  });
 
    }else{
auth.openMessage('Add image first','error',1);
setFormData({...formData,span:false})
    }
   
  }else if(e.target.name==='Teamimage'){
  if(formData.Teamname!=''&&formData.TeamDisc!=''&& formData.TeamimageFile!=''){

    if(formData.teamID!=null){
      setFormData({...formData,span:true})
      const formDatas = new FormData();
      formDatas.append('teamID', formData.teamID);
      formDatas.append('name', formData.Teamname);
      formDatas.append('description', formData.TeamDisc);
      formDatas.append('image', formData.TeamimageFile);
      formDatas.append('campusID', location.state.campusID);
      formDatas.append('token', token);
axios.post(url+'update-team', formDatas,config)
  .then(function (response) {
 if(response.data?.status===200){
  auth.openMessage(response.data?.message,'success');
  auth.APIloading();
  setFormData((prevFormData) => ({ ...formData,span:false,TeamData:response.data.teams,Teamname:'',TeamDisc:'',TeamimageFile:'',teamID:null}));
}else if(response.data?.status===400){
  auth.openMessage(response.data?.message,'error',1);
  setFormData({...formData,span:false})
}
else{
  auth.openMessage(response.data?.message,'error',1);
  setFormData({...formData,span:false})
}
  })
  .catch(function (error) {
    auth.openMessage(error.response.data.message,'error');
    setFormData({...formData,span:false})
  });
    }else{
      setFormData({...formData,span:true})
    
      const formDatas = new FormData();
            formDatas.append('name', formData.Teamname);
            formDatas.append('description', formData.TeamDisc);
            formDatas.append('image',formData.TeamimageFile);
            formDatas.append('campusID', location.state.campusID);
            formDatas.append('token', token);

      axios.post(url+'create-team',formDatas,config)
  .then(function (response) {
    
 if(response.data?.status===200){
  auth.openMessage(response.data?.message,'success');
  auth.APIloading();

  setFormData((prevFormData) => ({ ...formData,span:false,TeamData:response.data.teams,Teamname:'',TeamDisc:'',TeamimageFile:'',teamID:null}));
}else if(response.data?.status===400){
  auth.openMessage(response.data?.message,'error',1);
  setFormData({...formData,span:false})
}
else{
  auth.openMessage(response.data?.message,'error',1);
  setFormData({...formData,span:false})
}
  })
  .catch(function (error) {
    auth.openMessage(error.response.data.message,'error');
    setFormData({...formData,span:false})
  });
    }
  }else{
    auth.openMessage('All field required','error',1);
    setFormData({...formData,span:false})
  }
  }else if(e.target.name==='program'){
    if(formData.name!=''&&formData.day!=''&& formData.time!=''&&formData.address!=''){

  if(formData.serviceID!=null){
    setFormData({...formData,span:true})
  await  axios.post(url+'update-service?', { 
      serviceID:formData.serviceID,  
      name: formData.name,
      day:formData.day,
      time:formData.time, 
      address:formData.address,
campusID:location.state.campusID,
token:token,
})
.then(function (response) {
  auth.openMessage(response.data?.message,'success');
  auth.APIloading();
setFormData((prevFormData) => ({ ...formData,span:false,ProgramData:response.data.services,name:'',day:'',time:'',address:'',serviceID:null}));
})
.catch(function (error) {
  auth.openMessage(error.response.data.message,'error');
  setFormData({...formData,span:false})
});
  }else{
    
    setFormData({...formData,span:true})
    axios.post(url+'create-service?', {  
      name: formData.name,
      day:formData.day,
      time:formData.time, 
      address:formData.address,
campusID:location.state.campusID,
token:token,
})
.then(function (response) {
  setFormData((prevFormData) => ({ ...formData,span:false,ProgramData:response.data.services,name:'',day:'',time:'',address:'',serviceID:null}));
  auth.APIloading();
  auth.openMessage(response.data?.message,'success');
})
.catch(function (error) {
  setFormData({...formData,span:false})
  auth.openMessage(error.response.data.message,'error');
});
  }
}else{
    auth.openMessage('All field required','error',1);
    setFormData({...formData,span:false})
  }
 

  }
  
  } 
  function removeItem(e,itemToRemove) {
    if(e==='removeProgram'){
      const program = formData.ProgramData.map(item => {
        return  Object.values(item);
      });
    
      axios.delete(`${BASE_URL}/api/delete-service?serviceID=${itemToRemove}&campusID=${location.state.campusID}&token=${token}`)
      .then(function (response) {
   if(response.data.status===200){

   
     auth.openMessage(response.data?.message,'success');
     setFormData((prevFormData) => ({ ...formData,span:false,ProgramData:response.data.services,name:'',day:'',time:'',address:'',serviceID:null}));
     auth.APIloading();
    }else{
      auth.openMessage(response.data?.message,'error');
      setFormData((prevFormData) => ({ ...formData,span:false,ProgramData:response?.data?.services,name:'',day:'',time:'',address:'',serviceID:null}));
    
   }
      })
      .catch(function (error) {
        auth.openMessage(error,'error');
        setFormData({...formData,span:false})
      });
    
     }else if(e==='team'){
   
      axios.delete(`${BASE_URL}/api/delete-team?teamID=${itemToRemove}&campusID=${location.state.campusID}&token=${token}`)
      .then(function (response) {
        if(response.data.status===200){
          auth.openMessage(response.data?.message,'success');
         setFormData((prevFormData) => ({ ...formData,span:false,TeamData:response.data.teams,Teamname:'',TeamDisc:'',teamID:null}));
         auth.APIloading();
        }else{
        auth.openMessage(response.data?.message,'error');
        setFormData((prevFormData) => ({ ...formData,span:false,TeamData:response.data.teams,Teamname:'',TeamDisc:'',teamID:null}));
       }
       
      })
      .catch(function (error) {
        setFormData({...formData,span:false})
      });
      // const newList =formData.TeamData.splice(itemToRemove,1);
      // setFormData({ ...formData, newList });
     }else if(e==='removeleader'){
         
      axios.delete(`${BASE_URL}/api/delete-leader?campusID=${location.state.campusID}&leaderID=${itemToRemove}`)
      .then(function (response) {
       if(response.data.status===200){
         auth.openMessage(response.data?.message,'success');
        setFormData((prevFormData) => ({ ...formData,span:false,LeaderData:response.data.leaders,LeaderName:'',LeaderPosetion:'',LeaderFacebook:'',LeaderPhone:'',LeaderTelegram:'',leaderID:null}));
        auth.APIloading();
      }else{
       auth.openMessage(response.data?.message,'error');
       setFormData((prevFormData) => ({ ...formData,span:false,LeaderData:response?.data?.leaders,LeaderName:'',LeaderPosetion:'',LeaderFacebook:'',LeaderPhone:'',LeaderTelegram:'',leaderID:null}));
      }
   
      })
      .catch(function (error) {
        setFormData({...formData,span:false})
      });
      // const newList =formData.LeaderData.splice(itemToRemove,1);
      // setFormData({ ...formData, newList });
     }else if(e==='removeGallery'){
      axios.delete(`${BASE_URL}/api/delete-gallery-image?token=${token}&campusID=${formData?.campusID}&imageURL=${itemToRemove}`)
      .then(function (response) {
    
        auth.openMessage(response.data?.message,'success');
        setFormData((prevFormData) => ({ ...formData, GalleryData:response.data.galleries,GalleryImageFile:'',gallerieID:null}));
        auth.APIloading();
      })
      .catch(function (error) {
        setFormData({...formData,span:false})
      });
      // const newList =formData.GalleryData.splice(itemToRemove,1);
      // setFormData({ ...formData, newList });
     }else{
   
     }
  };
  function updateItem(e,itemToRemove,data) {
    
    if(e==='updateProgram'){
      setFormData({ ...formData,name:data.name,day:data.day,time:data.time,address:data.address,serviceID:data.id});

     }else if(e==='team'){
      setFormData({ ...formData,Teamname:data.name,TeamDisc:data.description,TeamimageFile:data.image,teamID:data.id});
      // const newList =formData.TeamData.splice(itemToRemove,1);
      // setFormData({ ...formData, newList });
     }else if(e==='leader'){
      setFormData({ ...formData,LeaderName:data.name,LeaderPhone:data.phone,LeaderPosetion:data.role,LeaderImageFile:data.photo,LeaderFacebook:data.facebookLink,LeaderTelegram:data.telegramLink,leaderID:data.id});
      // const newList =formData.LeaderData.splice(itemToRemove,1);
      // setFormData({ ...formData, newList });
     }else if(e==='Gallery'){
     
      const newList =formData.GalleryData.splice(itemToRemove,1);
      setFormData({ ...formData, newList });
     }else{
    
     }
  };
  const [current, setCurrent] = useState(0);
  const [currentExit,setCurrentExit]=useState('exit');

 
  const next = (action) => {
switch (current) {
  case 0:
   setFormData({...formData,span:true})
    axios.post(url+'update-welcome', {
      image: formData.imageFile,
      universityName: formData.universityName,
      fellowshipName: formData.fellowshipName,
      moto:formData.FellowshipMoto,
      campusName:formData.campusName,
      fellowshipAcronym:formData.fellowshipAcronym,
      fellowshipLogo:formData.FlogoFile,
      url:formData.url,
      campusID:location.state.campusID,
    },config)
    .then(function (response) {
      setFormData({...formData,span:false})
      
      if(response.data?.status===200){
        setFormData({...formData,urlError:null,span:false})
        
        ALLNotification(response.data?.message,'top');
        if(currentExit!==action){
          setCurrent(current + 1);
        
        }else{
       
          Navigate('/content');
        }
       
      }else{
        if(response.data?.message==='This URL is held by another campus!'){
          auth.openMessage(response.data?.message,'error',1);
          setFormData({...formData,urlError:response.data?.message,span:false})
        }else{
        auth.openMessage(response.data?.message,'error',1);
        setFormData({...formData,span:false})
        } 
      } })
    .catch(function (error) {
      auth.openMessage(error.response.data.message,'error',1);
      setFormData({...formData,span:false})
    });
  
    break;
    case 1:
      setFormData({...formData,span:true})
      axios.post(url+'update-intro', {
        message: formData.message,
        author: formData.leaderName,
        authorPosition: formData.posetion,
        image:formData.LeaderimageFile,
       
      campusID:location.state.campusID,
      token:token,
    },config)
    .then(function (response) {
      setFormData({...formData,span:false})
if(response.data?.status===200){
  auth.APIloading();
ALLNotification(response.data?.message,'top');

if(currentExit!==action){
  setCurrent(current + 1);
 
}else{

  Navigate('/content');
}
    }else if(response.data?.status===400){
      auth.openMessage(response.data?.message,'error',1);
      setFormData({...formData,span:false})
    }
    else{
      auth.openMessage(response.data?.message,'error',1);
      setFormData({...formData,span:false})
    }
    })
    .catch(function (error) {
      auth.openMessage(error.response.data.message,'error',1);
   
   
      setFormData({...formData,span:false})
    });
   
    break
    case 2:
      setFormData({...formData,span:true})
    axios.post(url+'update-city', {
      description:formData.history,
       name:formData.CITYNAME,
      latitude:formData.latitude,
      longitude:formData.longtude,
      bg_Image:formData.CityImageFile,
      campusID:location.state.campusID,   
    },config)
    .then(function (response) {
      setFormData({...formData,span:false})
      // toast(response.data?.message,{
      //   position:'bottom-right'
      // });
      if(response.data?.status===200){
        ALLNotification(response.data?.message,'top');
        auth.APIloading();
        if(currentExit!==action){
          setCurrent(current + 1);
       
        }else{
         
          Navigate('/content');
        }
      }else if(response.data?.status===400){
        auth.openMessage(response.data?.message,'error',1);
        setFormData({...formData,span:false})
      }
      else{
        auth.openMessage(response.data?.message,'error',1);
        setFormData({...formData,span:false})
      }
    })
    .catch(function (error) {
      ALLNotification(error.response.data.message,'top');
      setFormData({...formData,span:false})
    });
   
    break;
  case 3:
    setFormData({...formData,span:true})
    axios.post(url+'update-about', {
      description:formData.campusDescription,
      logo:formData.LogoFile,
      bgImage:formData.campusImageFile,
      campusID:location.state.campusID,
      token:token,
    },config)
    .then(function (response) {
      setFormData({...formData,span:false})
if(response.data?.status===200){
ALLNotification(response.data?.message,'top');
auth.APIloading();
if(currentExit!==action){
  setCurrent(current + 1);

}else{

  Navigate('/content');
}
    }else if(response.data?.status===400){
      auth.openMessage(response.data?.message,'error',1);
      setFormData({...formData,span:false})
    }
    else{
      auth.openMessage(response.data?.message,'error',1);
      setFormData({...formData,span:false})
    }
    })
    .catch(function (error) {
      ALLNotification(error.response.data.message,'top');
      setFormData({...formData,span:false})
    });
  
    break;
   
    case 4:
      setFormData({...formData,span:true})
      axios.post(url+'update-fellowship', {
        image:formData.FimageFile,
        members:formData.membersNum,
        teams:formData.teamNum,
        services:formData.service,
        campusID:location.state.campusID,
        description:formData.Fdescription, 
      },config)
      .then(function (response) {
        setFormData({...formData,span:false});
  if(response.data?.status===200){
  ALLNotification(response.data?.message,'top');
  auth.APIloading();
  if(currentExit!==action){
    setCurrent(current + 1);
  
  }else{
  
    Navigate('/content');
  }
      }else if(response.data?.status===400){
        auth.openMessage(response.data?.message,'error',1);
        setFormData({...formData,span:false})
      }
      else{
        auth.openMessage(response.data?.message,'error',1);
        setFormData({...formData,span:false})
      }
      })
      .catch(function (error) {
        ALLNotification(error.response.data.message,'top');
        setFormData({...formData,span:false})
      });

      break;
      case 5:
        
        if(currentExit!==action){
      
          setCurrent(current + 1);
          
        }else{
        
          Navigate('/content');
        }
        break;
        case 6:
          if(currentExit!==action){
            setCurrent(current + 1);
          
          }else{
          
            Navigate('/content');
          }
          break;
          case 7:
            if(currentExit!==action){
              setCurrent(current + 1);
            
            }else{
            
              Navigate('/content');
            }
          break;
          case 8:
          
          if(currentExit!==action){
            setCurrent(current + 1);
          
          }else{
          
            Navigate('/content');
          }
            break;
            case 9:
              if(currentExit!==action){
                setCurrent(current + 1);
              
              }else{
              
                Navigate('/content');
              }
  break;
  case 10:
     Done();
   

            break;
            default:
              return '';
            }
   
  };
  const Done=()=>{
    const coordinates = formData.map.match(/@([0-9]+\.[0-9]+),([0-9]+\.[0-9]+)/);
   
    setFormData({...formData,span:true})

                    axios.post(url+'update-footer?', {
            email:formData.email,
            phone:formData.contactPhone,
            mapLink:formData.map,
            mapLatitude:coordinates&&coordinates[1],
            mapLongitude:coordinates&&coordinates[2],
            facebookLink:formData.facebook,
            telegramLink:formData.telegram,
            instagramLink:formData.instagram,
            youtubeLink:formData.youtube,
            tiktokLink:formData.tiktokLink,
            campusID:location.state.campusID,
            token:token
          })
          .then(function (response) {
            
            setFormData({...formData,span:false})
   if(response.data?.status===200){
    
    setFormData({...formData,span:false})
    auth.openMessage(response.data?.message,'success');
    auth.APIloading();
    Navigate('/contenet',{state:{campusID:formData.campusID,page:location.state.page}});
  }else if(response.data?.status===400){
    auth.openMessage(response.data?.message,'error',1);
    setFormData({...formData,span:false})
  }
  else{
    auth.openMessage(response.data?.message,'error',1);
    setFormData({...formData,span:false})
  }
  })
          .catch(function (error) {
            setFormData({...formData,span:false})
            auth.openMessage(error.response.data.message,'error',1);
           
          });
       
  }
  const prev = () => {
    setCurrent(current - 1);
  };

 
  const steps = [
    {
      title: "",
      content: (
        <SteperRout  title='Hero Page'>
          <HeroPage
         
            handleChange={handleSubmit}
            formData={formData}
            onChange={handleChange}
            uploadButton={uploadButton}
            name='image'
          />
        </SteperRout>
      ),
    },
    {
      title: "",
      content:  <SteperRout   title='Welcom Message'>
        <WelcomPage  
        name='Leaderimage'
      posetion={formData.posetion}
      leaderName={formData.leaderName}
      image={formData.Leaderimage}
      title={formData.title}
      message={formData.message}
        handleChange={handleSubmit}
            formData={formData}
            onChange={handleChange}
            uploadButton={uploadButton} />
      </SteperRout>,
    },
    {
      title: "",
      content: <SteperRout   title='Our City Page'>
      <OurCity  
   
      title={formData.cityTitle}
    history={formData.history}
    image={formData.Leaderimage}
  
      handleChange={handleSubmit}
          formData={formData}
          setFormDatas={setFormData}
          onChange={handleChange}
          uploadButton={uploadButton} />
           
    </SteperRout>,
    },
    {
      title: "",
      content: <SteperRout   title={location.state.page.About_Section_Contents.title}>
      <AboutCampus 
   
      title='About Campus'
    history={formData.history}
    image={formData.Leaderimage}
  Logo={formData.Logo}
  campusImage={formData.campusImage}
      handleChange={handleSubmit}
          formData={formData}
          onChange={handleChange}
          uploadButton={uploadButton} />
    </SteperRout>,
    },
    {
      title: "",
      content: <SteperRout   title={location.state.page.Fellowship_Section_Contents.title}>
      <Fellowship 
   
      title={location.state.page.Fellowship_Section_Contents.title}
    history={formData.history}
    image={formData.Leaderimage}
  campusImage={formData.campusImage}
      handleChange={handleSubmit}
          formData={formData}
          onChange={handleChange}
          uploadButton={uploadButton} />
    </SteperRout>,
    },
    {
      title: "",
      content: <SteperRout   title='Regular Programs Page'>
      <Program 
   
     
    handleChange={handleSubmit}
          formData={formData}
         
          handlclick={add}
          removeItem={removeItem}
          updateItem={updateItem}
          uploadButton={uploadButton} />
    </SteperRout>,
    },
    {
      title: "",
      content: <SteperRout   title='Our Team'>
      <OurTeam 
   name='Teamimage'
      title='Our Team'
    handleChange={handleSubmit}
          formData={formData}
          onChange={handleChange}
          uploadButton={uploadButton} 
          handlclick={add}
          updateItem={updateItem}
          removeItem={removeItem}
          />
    </SteperRout>,
    },
    {
      title: "",
      content: <SteperRout   title='Our Leader'>
      <OurLeader 
 
      title='Our Team'
    handleChange={handleSubmit}
          formData={formData}
          onChange={handleChange}
          uploadButton={uploadButton} 
          handlclick={add}
          updateItem={updateItem}
          removeItem={removeItem}
          />
    </SteperRout>,
    },
    {
      title: "",
      content: <SteperRout   title=''>
      <FreshStudentForm 
  campusID={location.state.campusID}
    handleChange={handleSubmit}
    ALLNotification={ALLNotification}
          formData={formData}
          onChange={handleChange}
          uploadButton={uploadButton} 
          handlclick={add}
          />
    </SteperRout>,
    },
    {
      title: "",
      content:  <SteperRout   title='Gallery'>
      <Gallery 
   
      title='Gallery'
    handleChange={handleSubmit}
          formData={formData}
          onChange={handleImageChange}
          uploadButton={uploadButton} 
          handlclick={add}
          removeItem={removeItem}
          images={images}
          />
    </SteperRout>,
    },
    {
      title: "",
      content: <SteperRout   title='Footer'>
      <Footer
  
    handleChange={handleSubmit}
          formData={formData}
          onChange={handleChange}
          uploadButton={uploadButton} 
          handlclick={add}
         
          />
    </SteperRout>,
    },
  ];
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    lineHeight: "60px",

    color: token.colorTextTertiary,
    //   backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    //   border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };
  const onChange = (value) => {
    setCurrent(value);
  };

  function exit(){
  
  setCurrent(current);
 
next("exit");

  //Navigate('/content');  
  // auth.navi('Dashboard');
      // auth.APIloading();

    
  

  }
  return (
   
   
    <Contener  expand={props.expand}>
       {contextHolder}
       {auth.contextHolder}
      <div className="mt-[22px] ml-[-30px] bg-white">
       
        <div  className="mt-[26px] md:ml-[34px] mr-[10px] md:mr-[40px] ">
          <div className="flex  md:flex-col" >
          <Steps
          className="flex md:items-center md:justify-center items-center justify-start w-[50px] md:w-full"          
            current={current}
            items={items}
            percent={current.percent}
            onChange={onChange}
            />

          <div  style={contentStyle}>{steps[current].content}</div>
          </div>
          <div className="flex  "
            style={{
              marginTop: 71,
              marginBottom: 70,
           
            }}
          >
            {current > 0 && (
              <Button className="md:w-[150px] md:h-[40px] ml-[8px] mr-[8px] md:text-[18px]"
             
                onClick={() => prev()}
              >
                Previous
              </Button>
            )}
            {current < steps.length - 1 && (
              <> 
               {formData.span!=false? <Button className="bg-[#9009CF] md:w-[150px] md:h-[40px] font-body md:text-[18px]"
                type="primary" size="medium" loading>
              Saving
            </Button>: 
            <div  className="flex gap-4">
               <Button
              className="bg-[#9009CF] md:w-[150px] md:h-[40px] font-body md:text-[18px]"
                type="primary"
                onClick={() => next("next")}
                >
                  Next
                
                
               
              </Button>
              <Button
                className="bg-[#9009CF] md:w-[150px] md:h-[40px] font-body md:text-[18px]"
                type="primary"
                onClick={() => exit()}
                >
                 Save & Exit
              </Button>
            </div>
              } 
             </>
            )}

            {current === steps.length - 1 && (
               <> 
               {formData.span!=false?<Button  style={{
        background: "#9009CF",
        width: "150px",
        height: "40px",
        fontWeight: "700",
        fontSize: "18px",
      }} type="primary" size="small" loading>
              Saving
            </Button>: 
              <Button
                style={{
                  backgroundColor: "#9009CF",
                  width: "150px",
                  height: "40px",
                  margin: "0 8px",
                  fontWeight: "700",
                  fontSize: "18px",
                }}
                type="primary"
                onClick={() => Done() }
              >
                Done
              </Button>
               }
              </>
            )}
          </div>
        </div>
      </div>
    </Contener>
 
  );
}
export default NewPage;
