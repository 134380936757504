// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Card_card__xLavs {\n    display: flex;\n    flex-direction: column;\n \n    justify-content: center;\n    background-color: bisque;\n    width: 16rem;\n    height: -moz-fit-content;\n    height: fit-content;\n    padding: 0 2rem 2rem 2rem;\n    border-radius: 10px;\n  }\n  \n  .Card_card__xLavs img {\n    margin-top: -20%;\n    width: 100%;\n    border-radius: 20px;\n  }\n  \n  .Card_card__xLavs h2 {\n    margin: 0;\n    margin-top: 1rem;\n  }\n  \n  .Card_card__xLavs p {\n    margin: 0;\n    margin-top: 0.5rem;\n    margin-bottom: 1.5rem;\n  }\n  \n  .Card_card__xLavs .Card_btnn__Ii\\+i4 {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  ", "",{"version":3,"sources":["webpack://./src/DemoPage/css/Card.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;;IAEtB,uBAAuB;IACvB,wBAAwB;IACxB,YAAY;IACZ,wBAAmB;IAAnB,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,SAAS;IACT,gBAAgB;EAClB;;EAEA;IACE,SAAS;IACT,kBAAkB;IAClB,qBAAqB;EACvB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB","sourcesContent":[".card {\n    display: flex;\n    flex-direction: column;\n \n    justify-content: center;\n    background-color: bisque;\n    width: 16rem;\n    height: fit-content;\n    padding: 0 2rem 2rem 2rem;\n    border-radius: 10px;\n  }\n  \n  .card img {\n    margin-top: -20%;\n    width: 100%;\n    border-radius: 20px;\n  }\n  \n  .card h2 {\n    margin: 0;\n    margin-top: 1rem;\n  }\n  \n  .card p {\n    margin: 0;\n    margin-top: 0.5rem;\n    margin-bottom: 1.5rem;\n  }\n  \n  .card .btnn {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "Card_card__xLavs",
	"btnn": "Card_btnn__Ii+i4"
};
export default ___CSS_LOADER_EXPORT___;
