import React from 'react'

function Button(props) {
  
    return (
       <button  className={`bg-[#9009CF] font-plus  rounded-[4px] text-white  text-center text-[18px] font-bold font-larg ${props.style}`} name={props.name} onClick={props.handlclick}>{props.children} </button>
     
   
    )
}

export default Button